import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDebounce } from "react-use";

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    flexDirection: "row",
    width: "100%",
  },
  formcontrollabel: {
    flexDirection: "column-reverse",
  },
}));
export default function LinearScaleResponseWithValue(props) {
  const { viewMode, question, questionId, formRes, updateApi } = props;
  const classes = useStyles();

  const lowerbound = question.lowerbound;
  const upperbound = question.upperbound;
  const oldValue = formRes[0]?.value || lowerbound;

  const [value, setValue] = useState(oldValue);

  let scale = [];
  const func = (lower, upper) => {
    for (let i = lower; i <= upper; i++) {
      scale.push(i);
    }
    return scale;
  };
  scale = func(lowerbound, upperbound);

  useDebounce(
    () => {
      if (questionId) {
        let formResId = formRes[0]?._id;
        const obj = {
          formResId,
          updateObj: {
            value: value,
          },
        };
        if (!viewMode) updateApi(obj);
      }
    },
    100,
    [value]
  );

  return (
    <RadioGroup
      onChange={(event) => {
        if (!viewMode) {
          const v = event.target.value;
          setValue(v);
        }
      }}
      value={Number(value)}
      classes={{ root: classes.radioGroup }}
    >
      {scale.map((val, i) => {
        return (
          <FormControlLabel
            name={val}
            value={val}
            key={i}
            control={<Radio color="primary" />}
            label={val}
            classes={{ root: classes.formcontrollabel }}
            disabled={viewMode}
          />
        );
      })}
    </RadioGroup>
  );
}
