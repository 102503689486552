import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { green, red } from "@material-ui/core/colors";
import FilterSelectComponent from "./FilterSelectComponent";
import { TextField } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import FilterCondition from "./FilterCondition";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    // flexDirection: "column",
    border: "1px solid grey",
    borderRadius: "5px",
    width: "100%",
    // marginTop: "1rem",
    padding: "10px",
    position: "relative",
  },
  btnGroup: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
  },
}));

export default function FilterGroup({
  items,
  isEmpty,
  addCondition,
  deleteCondition,
  updateCondition,
  deleteGroup,
  condition,
  index,
}) {
  const classes = useStyles();
  const history = useHistory();

  const { root, btnGroup, btn } = classes;

  // useEffect(() => {
  //   if (!isEmpty) {
  //     if (condition?.length > 0) {
  //       setConjunction(condition[0]);
  //       setHeaderValue(condition[1]);
  //       setConditionValue(condition[2]);
  //       setText(condition[3]);
  //     }
  //   }
  // }, [condition]);

  return (
    <div className={root}>
      <div className={btnGroup}>
        <IconButton
          size="small"
          color="primary"
          onClick={() => addCondition(index)}
          className={btn}
        >
          <AddIcon />
        </IconButton>
        <IconButton
          size="small"
          color="primary"
          onClick={() => deleteGroup(index)}
          className={btn}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      {condition?.length > 0 ? (
        <div>
          {condition.map((subCondition, subIndex) => {
            return (
              <FilterCondition
                items={items}
                key={subIndex}
                isEmpty={isEmpty}
                deleteCondition={deleteCondition}
                updateCondition={updateCondition}
                condition={subCondition}
                index={index}
                subIndex={subIndex}
                required={subIndex == 1 ? true : false}
              />
            );
          })}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>No conditions added</span>
        </div>
      )}
      {/* <Button
        size="small"
        variant="outlined"
        color="primary"
        onClick={() => addCondition(index)}
        startIcon=<AddIcon />
        style={{ position: "relative", right: "0" }}
      >
        Condition
      </Button> */}
    </div>
  );
}

// {!firstCondition ? (
//         <>
//           <FilterSelectComponent
//             items={conjunctionHeader}
//             currentVal={conjunction}
//             condition={condition}
//             updateCondition={updateCondition}
//             index={index}
//             title={"Conjunc"}
//             pos={0}
//           />
//           &nbsp;
//         </>
//       ) : null}
