import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Avatar,
  Typography,
} from "@mui/material";
import { AvatarGroup } from "@material-ui/lab";
import SingleUserWithCaptionTableCell from "../styled/generic/SingleUserWithCaptionTableCell";
import HorizBox from "../styled/generic/HorizBox";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import {
  CallOutlined,
  KeyboardArrowDown,
  MoreVertOutlined,
  RadioButtonChecked,
  SmsOutlined,
} from "@mui/icons-material";
import dayjs from "dayjs";
import PopupMenuList from "../styled/generic/PopupMenuList";
import Api from "../../helpers/Api";
import { useDispatch } from "react-redux";
import { DeleteOutline } from "@material-ui/icons";
import ConfirmationDialog from "../global/ConfirmationDialog";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";

const CRMCard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const issue = props?.issue;
  const statusOptions = props?.statusOptions;
  const onIssueUpdate = props?.onIssueUpdate;
  const onIssueDelete = props?.onIssueDelete;
  const hideStatus = props?.hideStatus || false;

  const updateIssue = async (statusId) => {
    try {
      const res = await Api.post("issue/updateFromKanban", {
        issueId: issue?._id,
        status: statusId,
      });
      if (res?.success) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Status updated successfully",
          },
        });
        onIssueUpdate({
          ...issue,
          status: statusOptions.find((status) => status._id === statusId),
        });
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: res?.message || "Something went wrong",
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong",
        },
      });
    }
  };

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const deleteIssue = async () => {
    try {
      const res = await Api.post("issue/delete", {
        issueId: issue?._id,
      });
      if (res) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Issue deleted successfully",
          },
        });
        onIssueDelete(issue?._id);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: res?.message || "Something went wrong",
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong",
        },
      });
    }
  };

  let priority_color = "grey";
  let priority_text = "low";
  switch (issue?.priority) {
    case 1:
      priority_color = "grey";
      priority_text = "Very Low";
      break;
    case 2:
      priority_color = "green";
      priority_text = "Low";
      break;
    case 3:
      priority_color = "yellow";
      priority_text = "Medium";
      break;
    case 4:
      priority_color = "orange";
      priority_text = "High";
      break;
    case 5:
      priority_color = "red";
      priority_text = "Urgent";
      break;
  }

  return (
    <Grid item xs={12} md={6} lg={3}>
      <Box
        sx={{
          p: 2,
          boxShadow: "2px 4px 6px rgba(0,0,0,0.10)",
          borderRadius: "8px",
          overflow: "hidden",
          "&:hover": {
            boxShadow:
              "0px 2px 8px 0px rgba(52, 101, 255, 0.12), 0px -2px 8px 0px rgba(52, 101, 255, 0.12)",
          },
          cursor: "pointer",
        }}
      >
        <BoxSpaceBetween
          onClick={() => {
            var path = "/issue/edit/" + issue?._id;
            history.push(path);
          }}
          sx={{
            cursor: "pointer",
            alignItems: "start",
          }}
        >
          <Box display="flex" flexDirection="column">
            <span style={{ fontWeight: "bold", fontSize: 20 }}>
              {issue?.template?.isCRM
                ? issue?.finrel?.profile?.parent?.displayName
                : issue?.title || "Untitled"}
            </span>
            <span style={{ fontWeight: "normal" }}>
              {moment(issue?.createdAt).format("DD MMM YYYY")}
            </span>
          </Box>

          <HorizBox spacing={1}>
            {/* <RadioButtonChecked color="primary" /> */}
            <Box
              sx={{
                display: "flex",
                padding: "4px 12px",
                alignItems: "center",
                borderRadius: "20px",
                backgroundColor: issue?.status?.color || "#E48900",
                color: "white",
              }}
            >
              {issue?.status?.text}
            </Box>

            {!hideStatus && (
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <PopupMenuList
                  appearContent={
                    <IconButton size="small">
                      <MoreVertOutlined />
                    </IconButton>
                  }
                  actions={statusOptions.map((status) => {
                    return {
                      title: status?.text,
                      action: () => {
                        updateIssue(status?._id);
                      },
                    };
                  })}
                  // secondaryActions={[
                  //   {
                  //     title: "Delete",
                  //     action: () => {
                  //       setShowConfirmationDialog(true);
                  //     },
                  //   },
                  // ]}
                />
              </Box>
            )}
          </HorizBox>
        </BoxSpaceBetween>

        {!issue?.template?.isCRM && (
          <Box>
            <BoxSpaceBetween
              sx={{
                mt: 1,
                // height of 2 lines, if overflow, show ... at end
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                whiteSpace: "normal",
                height: "40px",
              }}
            >
              {String(issue?.description).trim().length > 0 ? (
                <Box>{ReactHtmlParser(issue?.description)}</Box>
              ) : (
                <Box
                  sx={{
                    fontStyle: "italic",
                    color: "grey",
                  }}
                >
                  no description
                </Box>
              )}
            </BoxSpaceBetween>
          </Box>
        )}

        {issue?.template?.isCRM && (
          <Box>
            <BoxSpaceBetween spacing={3} sx={{ mt: 2 }}>
              <Box sx={{ fontSize: 16 }}>Listings:</Box>
              <Box sx={{ fontSize: 16, color: "#2D76E0" }}>
                {issue?.finrel?.listing?.title || "Untitled"}
              </Box>
            </BoxSpaceBetween>
            <BoxSpaceBetween spacing={3} sx={{ mt: 1 }}>
              <Box sx={{ fontSize: 16 }}>Requirements:</Box>
              <Box sx={{ fontSize: 16 }}>
                {issue?.finrel?.requirement?.title || "Untitled"}
              </Box>
            </BoxSpaceBetween>
          </Box>
        )}

        <BoxSpaceBetween sx={{ mt: issue?.template?.isCRM ? 0 : 1 }}>
          <Box sx={{ fontSize: 16 }}>Priority:</Box>
          <Box sx={{ fontSize: 16 }}>{priority_text}</Box>
        </BoxSpaceBetween>
        <BoxSpaceBetween spacing={3} sx={{ mt: 1 }}>
          <Box sx={{ fontSize: 16 }}>Assigned:</Box>
          <Box sx={{ fontSize: 16 }}>
            {issue?.assigned.length > 0 ? (
              <AvatarGroup max={4}>
                {issue?.assigned &&
                  issue?.assigned.map((userData, i) => (
                    <Avatar
                      key={i}
                      alt={userData?.parent?.displayName}
                      src={
                        userData?.parent?.displayPicture?.thumbUrl ||
                        userData?.parent?.displayPicture?.url
                      }
                      style={{ width: "30px", height: "30px" }}
                    />
                  ))}
              </AvatarGroup>
            ) : (
              <Box sx={{ fontSize: 16 }}>0 Users</Box>
            )}
          </Box>
        </BoxSpaceBetween>

        <BoxSpaceBetween spacing={3} sx={{ mt: 1 }}>
          <Box sx={{ fontSize: 15 }}>Project:</Box>
          <Box sx={{ fontSize: 15 }}>
            {issue?.project?.displayName || "N/A"}
          </Box>
        </BoxSpaceBetween>
        <BoxSpaceBetween spacing={3} sx={{ mt: 1 }}>
          <Box sx={{ fontSize: 15 }}>Organization:</Box>
          <Box sx={{ fontSize: 15 }}>
            {issue?.organization?.displayName || "N/A"}
          </Box>
        </BoxSpaceBetween>

        <BoxSpaceBetween spacing={3} sx={{ mt: 1 }}>
          <Box sx={{ fontSize: 15 }}>Author:</Box>
          <Box sx={{ fontSize: 15 }}>
            <SingleUserWithCaptionTableCell
              label={issue?.user?.displayName || "N/A"}
              url={issue?.user?.displayPicture?.thumbUrl || ""}
              labelStyleProps={{ fontSize: 16 }}
              avatarTextGap={1}
              avatarSx={{ width: 24, height: 24 }}
            />
          </Box>
        </BoxSpaceBetween>

        <Box
          onClick={(e) => {
            e.stopPropagation();
          }}
        ></Box>

        <ConfirmationDialog
          title="Delete Ticket"
          message="Are you sure you want to delete this ticket? This action cannot be undone."
          successButtonText="Yes"
          cancelButtonText="Cancel"
          successListener={() => {
            deleteIssue();
            setShowConfirmationDialog(false);
          }}
          cancelListener={() => {
            setShowConfirmationDialog(false);
          }}
          open={showConfirmationDialog}
          setOpen={setShowConfirmationDialog}
        />
      </Box>
    </Grid>
  );
};

export default CRMCard;
