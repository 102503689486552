import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { FormControl, MenuItem, Select, TextField } from "@material-ui/core";
import { useDebounce } from "react-use";
import Choose from "../select/choose";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function FormTableCellDialog({
  open,
  onClose,
  qType,
  tableResponsesId,
  response,
  updateResponse,
  openChoose,
  setOpenChoose,
}) {
  const [value, setValue] = useState("");

  useDebounce(
    () => {
      let obj = {};
      if (
        qType == "Project" ||
        qType == "Organization" ||
        qType == "User" ||
        qType == "Issue" ||
        qType == "Template"
      ) {
        obj = {
          tableResponsesId,
          responseId: response?._id,
          updateObj: {
            parent: value,
            parentModelName: qType,
          },
        };
      } else {
        let field = "text";
        if (qType == "Date") {
          field = "date";
        } else if (qType == "Time") {
          field = "time";
        } else if (qType == "Number") {
          field = "numeric";
        }
        obj = {
          tableResponsesId,
          responseId: response?._id,
          updateObj: {
            [field]: value,
          },
        };
      }
      if (obj) updateResponse(obj);
    },
    100,
    [value]
  );

  useEffect(() => {

    let field = "text";
    if (qType == "Date") {
      field = "date";
    } else if (qType == "Time") {
      field = "time";
    } else if (qType == "Number") {
      field = "numeric";
    } else if (
      qType == "User" ||
      qType == "Organization" ||
      qType == "Project"
    ) {
      field = "parent?.displayName";
    } else if (qType == "Issue" || qType == "Template") {
      field = "parent?.title";
    }
    let defaultVal = response?.[field];
    setValue(defaultVal);
  }, [response]);

  const getQuestion = (qType) => {
    switch (qType) {
      case "Short Answer":
        return (
          <TextField
            variant="standard"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            label="Your Answer"
            style={{ width: "90%" }}
          />
        );
      case "Long Answer":
        return (
          <TextField
            variant="standard"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            label="Your Answer"
            style={{ width: "90%" }}
          />
        );

      case "Date":
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              margin="normal"
              id="date-picker-dialog"
              label="Date picker"
              format="MM/dd/yyyy"
              value={value}
              onChange={(date) => setValue(date)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        );
      case "Time":
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Select time"
              value={value}
              onChange={(date) => setValue(date)}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </MuiPickersUtilsProvider>
        );
      case "Number":
        return (
          <TextField
            variant="standard"
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            type="number"
            label="Your Answer"
            style={{ width: "90%" }}
          />
        );

      case "Dropdown":
        return (
          <FormControl fullWidth variant="outlined">
            <Select
              value={
                response?.question?.options?.find((item) => item._id === value)
                  ?.optionText
              }
              onChange={(e) => setValue(e.target.value)}
              inputProps={{ "aria-label": "Without label" }}
            >
              {response?.question?.options?.map((item, index) => (
                <MenuItem key={index} value={item._id}>{item?.optionText || ""}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
    }
  };

  return (
    <div>
      {qType == "Organization" ||
      qType == "Project" ||
      qType == "User" ||
      qType == "Issue" ||
      qType == "Template" ? (
        <Choose
          open={openChoose}
          setOpen={setOpenChoose}
          parentModelName={qType}
          multiple={false}
          disableModelChange={true}
          placeHolder={`Choose ${qType}`}
          onSelected={(arr) => {
            setValue(arr[0]?._id);
          }}
        />
      ) : (
        <Dialog
          onClose={onClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle id="customized-dialog-title" onClose={onClose}>
            Enter Cell Data
          </DialogTitle>
          <DialogContent dividers>{getQuestion(qType)}</DialogContent>
          <DialogActions>
            <Button autoFocus onClick={onClose} color="primary">
              Save changes
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
