import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import NormalDialog from "../styled/CommonComponents/NormalDialog";
import { getPublicTemplatesApiCall } from "./api.call";
import CircularProgress from '@material-ui/core/CircularProgress';
import emptyIcon from "../../Assets/emptyData.svg"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "450px",
    display: "flex",
    flexDirection: "column",
  },
  top_panel: {
    margin: "1rem 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      color: "#193B56",
      fontSize: "16px",
      fontWeight: "510",
    }
  },
  bottom_panel: {
    "& p": {
      color: "#193B56",
      fontSize: "16px",
      fontWeight: "510",
    },
  },
  btnCont: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiButton-label": {
      textTransform: "capitalize"
    }
  },
  loadingCont: {
    width: "100%",
    height: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      height: "150px",
      width: "auto"
    },
    "& p": {
      color: "gray",
      fontSize: "14px",
      fontWeight: "400",
      marginTop: "15px"
    }
  }
}));

export default function CloneTemplateDialog({ cloneTemplates, open, setOpen, setOpenChoose }) {
  const classes = useStyles();
  const [publicTemplates, setPublicTemplates] = useState([]);
  const [publicTemplatesLoading, setPublicTemplatesLoading] = useState(false);
  const [checked, setChecked] = useState([]);

  //To get public templates
  const getPublicTemplates = async () => {
    setPublicTemplatesLoading(true)
    await getPublicTemplatesApiCall({})
      .then((data) => {
        setPublicTemplates(data);
        setPublicTemplatesLoading(false)
      })
      .catch((err) => {
        console.log(err);
      })
  };

  //useEffect to get public templates
  useEffect(() => {
    getPublicTemplates();
  }, []);

  //handle click on check boxs
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <NormalDialog
      openDialog={open}
      handleCloseShare={() => { setOpen(false) }}
      pageTitle={"Import Template(s)"}
      content={<>
        <div className={classes.container}>
          <div className={classes.top_panel}>
            <p>Import from your own</p>
            <Button
              variant="contained"
              size="small"
              className={classes.button}
              startIcon={<TouchAppIcon />}
              onClick={() => {
                setOpen(false);
                setOpenChoose(true);
              }}
              style={{ textTransform: "none" }}
            >
              Choose
            </Button>
          </div>
          <div className={classes.bottom_panel}>
            <p>Import global Templates</p>
            {publicTemplatesLoading || publicTemplates.length === 0 ? (
              <div className={classes.loadingCont} >
                {publicTemplatesLoading ? (
                  <CircularProgress />
                ) : (<>
                  <img src={emptyIcon} />
                  <p>No Public Template is available</p>
                </>)}
              </div>
            ) : (
              <List>
                {publicTemplates.map((template) => (
                  <ListItem
                    key={template?._id}
                    role={undefined}
                    dense
                    button
                    onClick={handleToggle(template?._id)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        color="primary"
                        checked={checked.indexOf(template?._id) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": `checkbox-list-label-${template?._id}` }}
                      />
                    </ListItemIcon>
                    <ListItemText id={`checkbox-list-label-${template?._id}`} primary={template?.title} />
                  </ListItem>
                ))}
              </List>
            )}
          </div>
        </div>

        <div className={classes.btnCont} >
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              cloneTemplates(checked);
              setOpen(false);
            }}
          >
            Clone Templates
          </Button>
        </div>
      </>}
    />
  );
}