import { useState, useEffect } from "react";
import Api from "../../../helpers/Api";
import arrayToReducer from "../../../helpers/arrayToReducer";

function useContactData(profileId) {
	const [contactIds, setContactIds] = useState([]);

	const [contactDictionary, setContactDictionary] = useState({});
	const [totalContacts, setTotalContacts] = useState(0);

	const [loading, setLoading] = useState(false);

	const getData = async () => {
		setLoading(true);

		const res = await Api.post("wallet/relation/get/allByProfile", {
			profile: profileId,
		});

		const contacts = res.data;
        const length = contacts.length;

		setLoading(false);

		if (contacts) {
	

			const { newDict, idArr } = arrayToReducer(contacts);

			setContactIds(idArr);
			setContactDictionary(newDict);
			setTotalContacts(length);
		}
	};

	useEffect(() => {
		if (profileId) {
			getData();
		}
	}, [profileId]);

	return {
		contactIds,
		setContactIds,
		contactDictionary,
		setContactDictionary,
		totalContacts,
		loading,
	};
}

export default useContactData;
