import { TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDebounce } from "react-use";

export default function ShortAnswerResponse(props) {
  const { viewMode, questionId, formRes, updateApi } = props;
  const [value, setValue] = useState(formRes[0]?.text || "");

  useDebounce(
    () => {
      if (questionId) {
        let formResId = formRes[0]?._id;
        const obj = {
          formResId,
          updateObj: {
            text: value,
          },
        };
        if (!viewMode) updateApi(obj);
      }
    },
    100,
    [value]
  );

  return (
    <div style={{ width: "100%" }}>
      <TextField
        variant="standard"
        disabled={viewMode ? true : false}
        value={value}
        onChange={(e) => {
          if (!viewMode) setValue(e.target.value);
        }}
        label="Your Answer"
        style={{ width: "60%" }}
      />
    </div>
  );
}
