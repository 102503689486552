import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import ResourceShareDrawer from '../ResourceAccessControl/Resource.Share.Drawer';


const useStyles = makeStyles((theme) => ({
  linkTextCont: {
    fontSize: "15px",
    fontWeight: "500",
    color: theme.palette.primary.main,
    cursor: "pointer"
  }
}))

function ShareIconBtn({
  curEntityId, entity, sharedProfilesRole, setSharedProfilesRole,
  searchParameter, callbackAfterShare, resourceName, resourceId
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false)

  return (<>
    <div
      className={classes.linkTextCont}
      onClick={() => {
        setOpen(true)
      }}
    >
      Manage Resource Access
    </div>

    <ResourceShareDrawer
      openResourceShare={open}
      setOpenResourceShare={setOpen}
      curEntityId={curEntityId}
      entity={entity}
      resourceName={resourceName}
      resourceId={resourceId}
      callbackAfterShare={callbackAfterShare}
      searchParameter={searchParameter}
      sharedProfilesRole={sharedProfilesRole}
      setSharedProfilesRole={setSharedProfilesRole}
    />
  </>);
}

export default ShareIconBtn