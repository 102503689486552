import { TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDebounce } from "react-use";
import Linkify from "react-linkify";

export default function LinkResponse(props) {
  const { viewMode, questionId, formRes, updateApi } = props;
  const [value, setValue] = useState(formRes[0]?.link || "");

  useDebounce(
    () => {
      if (questionId) {
        let formResId = formRes[0]?._id;
        const obj = {
          formResId,
          updateObj: {
            link: value,
          },
        };
        if (!viewMode) updateApi(obj);
      }
    },
    100,
    [value]
  );

  return (
    <div style={{ width: "100%" }}>
      {viewMode ? (
        <Linkify>{value}</Linkify>
      ) : (
        <TextField
          variant="standard"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          label="Paste link here"
          style={{ width: "60%" }}
        />
      )}
    </div>
  );
}
