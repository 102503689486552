import React, { useEffect, useState } from "react";
import StandardContainer from "../styled/generic/StandardContainer";
import HorizBox from "../styled/generic/HorizBox";
import Button from "../styled/generic/Button";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import {
  Add,
  AlarmOnOutlined,
  KeyboardArrowDownOutlined,
  MessageOutlined,
  NoteOutlined,
  PhoneOutlined,
} from "@material-ui/icons";
import FormBox from "../styled/generic/FormBox";
import TextField from "../styled/generic/TextField";
import SpaceBetween from "../styled/generic/SpaceBetween";
import DuoButtonGroup from "../styled/generic/DuoButtonGroup";
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
import { useDispatch, useSelector } from "react-redux";
import DrawerContainer from "../styled/generic/DrawerContainer";
import SearchEntityDialog from "../styled/CommonComponents/SearchEntityDrawer";
import Select from "../styled/generic/Select";
import Api from "../../helpers/Api";
import ImgTextChip from "../styled/generic/ImgTextChip";
import dayjs from "dayjs";
import FormHeaderWithActions from "../styled/generic/FormHeaderWithActions";
import { Delete, EditOutlined, FilePresentOutlined } from "@mui/icons-material";
import DatePicker from "../styled/generic/DatePicker";
import TimePicker from "../styled/generic/TimePicker";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAllFiles } from "../styled/CommonComponents/api.call";
import TaskPicker from "../styled/generic/TaskPicker";
import { add } from "lodash";
import TableContainer from "../styled/generic/TableContainer";
import PopupMenuList from "../styled/generic/PopupMenuList";
import MoreButton from "../styled/generic/MoreButton";
import FilesViewer from "../styled/generic/FilesViewer";
import useShareAndAssign from "../ResourceAccessControl/shareAndAssign";
import config from "../../config/index";
import { setAuthCookieData } from "../../helpers/cookie.helper";
import CreatePalDrawer from "../AddMember/CreatePalDrawer";
import SingleUserWithCaptionTableCell from "../styled/generic/SingleUserWithCaptionTableCell";
import OrgPicker from "../styled/generic/OrgPicker";

const ProfileIssueCRM = ({ issue, setIssue }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { fileDictionary, createdFileIds } = useSelector((state) => state.file);
  const [actionTab, setActionTab] = useState("Notes");
  const [showLeadsDrawer, setShowLeadsDrawer] = useState(false);
  const [activitySearchStr, setActivitySearchStr] = useState("");
  const [note, setNote] = useState("");
  const [activities, setActivities] = useState([]);
  const [lead, setLead] = useState(false);
  const [showPreviewDrawer, setShowPreviewDrawer] = useState(false);
  const [itemToPreview, setItemToPreview] = useState(null);
  const [showRequirementDrawer, setShowRequirementDrawer] = useState(false);
  const [actionTitle, setActionTitle] = useState("");
  const [actionDescription, setActionDescription] = useState("");
  const [actionDate, setActionDate] = useState(dayjs());
  const [showTaskPicker, setShowTaskPicker] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [files, setFiles] = useState([]);
  const [loadingActivities, setLoadingActivities] = useState(false);
  const [showUpdatePalDrawer, setShowUpdatePalDrawer] = useState(false);
  const [newPalItem, setNewPalItem] = useState(null);

  console.log("Issue", issue);

  const updateIssue = async (statusId) => {
    try {
      const res = await Api.post("issue/updateFromKanban", {
        issueId: issue?._id,
        status: statusId,
      });
      if (res?.success) {
        // dispatch({
        //   type: "AddApiAlert",
        //   payload: {
        //     success: true,
        //     message: "Status updated successfully",
        //   },
        // });
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: res?.message || "Something went wrong",
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong",
        },
      });
    }
  };

  const TIME_MODES = [
    "1 day",
    "1 week",
    "15 days",
    "1 months",
    "3 months",
    "6 months",
    "9 months",
    "1 year",
    "Custom",
  ];
  const REQUIREMENT_TYPE_OPTIONS = [
    "Rent",
    "Sell",
    "Roommate",
    "Job",
    "Investment",
    "Tender",
  ];
  const [actionDateMode, setActionDateMode] = useState("1 day");
  const [requirementTitle, setRequirementTitle] = useState("");
  const [requirementType, setRequirementType] = useState("Rent");

  const { user, userProfile } = useSelector((state) => state.auth);

  let actionTabOptions = [
    {
      title: "Notes",
      icon: NoteOutlined,
    },
    {
      title: "Actions",
      icon: AlarmOnOutlined,
    },
    {
      title: "Documents",
      icon: FilePresentOutlined,
    },
    {
      title: "SMS",
      icon: MessageOutlined,
    },
    {
      title: "Call",
      icon: PhoneOutlined,
    },
  ];

  const saveNote = async () => {
    try {
      const { data } = await Api.post("/issue/activity/create", {
        issueId: issue?._id,
        type: "Note",
        note: note,
        userProfileId: user?.profile,
      });
      if (data) {
        setNote("");
        setActivities([...activities, data]);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Note added successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const updateNote = async () => {
    try {
      const { data } = await Api.post("/issue/activity/update", {
        issueActivityId: itemToPreview?._id,
        type: "Note",
        note: note,
      });
      if (data) {
        setNote("");
        setActivities(
          activities.map((item) => (item?._id === data?._id ? data : item)) ||
            []
        );
        setItemToPreview();
        setShowPreviewDrawer(false);

        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Note updated successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const saveAction = async () => {
    try {
      const { data } = await Api.post("/issue/activity/create", {
        issueId: issue?._id,
        type: "Action",
        actionTitle: actionTitle,
        actionDescription: actionDescription,
        actionDate: actionDate.toDate(),
        actionDateMode: actionDateMode,
        userProfileId: user?.profile,
      });
      if (data) {
        setActionTitle("");
        setActionDescription("");
        setActionDate(dayjs());
        setActivities([...activities, data]);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Action added successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const updateAction = async () => {
    try {
      const { data } = await Api.post("/issue/activity/update", {
        issueActivityId: itemToPreview?._id,
        type: "Action",
        actionTitle: actionTitle,
        actionDescription: actionDescription,
        actionDate: actionDate.toDate(),
        actionDateMode: actionDateMode,
      });
      if (data) {
        setActionTitle("");
        setActionDescription("");
        setActionDate(dayjs());
        setActivities(
          activities.map((item) => (item?._id === data?._id ? data : item)) ||
            []
        );
        setItemToPreview();
        setShowPreviewDrawer(false);

        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Action updated successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const saveDocs = async () => {
    try {
      if (createdFileIds.length > 0) {
        const { data } = await Api.post("/issue/activity/create", {
          issueId: issue?._id,
          type: "Doc",
          fileIds: createdFileIds,
          userProfileId: user?.profile,
        });
        setActivities([...activities, data]);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const deleteDocByFile = async (fileId) => {
    try {
      const { data } = await Api.post("/issue/activity/update", {
        issueActivityId: itemToPreview?._id,
        fileId: fileId,
        type: "Doc",
      });
      if (data) {
        setActivities(
          activities.map((item) => (item?._id === data?._id ? data : item)) ||
            []
        );
        setItemToPreview();
        setShowPreviewDrawer(false);

        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Docs updated successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    saveDocs();
  }, [createdFileIds]);

  const getActivities = async () => {
    try {
      setLoadingActivities(true);
      const { data } = await Api.post("/issue/activity/get", {
        issueId: issue?._id,
      });
      if (data) {
        setActivities(data);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setLoadingActivities(false);
    }
  };

  const connectLeadWithIssue = async (lead) => {
    try {
      const { data } = await Api.post("/issue/activity/connect-lead", {
        issueId: issue?._id,
        finrelId: lead?.finrel?._id || lead?.finrel,
      });
      if (data) {
        setLead(lead);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    getActivities();
  }, []);

  useEffect(() => {
    setLead(issue?.finrel);
    setRequirementTitle(issue?.finrel?.requirement?.title);
    setRequirementType(issue?.finrel?.requirement?.type || "Rent");
    setTasks(issue?.tasks || []);
  }, [issue]);

  useEffect(() => {
    switch (actionDateMode) {
      case "1 day":
        setActionDate(dayjs().add(1, "day").hour(9).minute(0));
        break;
      case "1 week":
        setActionDate(dayjs().add(1, "week").hour(9).minute(0));
        break;
      case "15 days":
        setActionDate(dayjs().add(15, "day").hour(9).minute(0));
        break;
      case "1 months":
        setActionDate(dayjs().add(1, "month").hour(9).minute(0));
        break;
      case "3 months":
        setActionDate(dayjs().add(3, "month").hour(9).minute(0));
        break;
      case "6 months":
        setActionDate(dayjs().add(6, "month").hour(9).minute(0));
        break;
      case "9 months":
        setActionDate(dayjs().add(9, "month").hour(9).minute(0));
        break;
      case "1 year":
        setActionDate(dayjs().add(1, "year").hour(9).minute(0));
        break;

      default:
        break;
    }
  }, [actionDateMode]);

  const editRequirement = async () => {
    try {
      const { data } = await Api.post("/listing/requirements/update", {
        requirementId: lead?.requirement?._id,
        updateData: {
          title: requirementTitle,
          requirementType: requirementType,
        },
      });
      if (data) {
        setIssue({
          ...issue,
          finrel: {
            ...issue?.finrel,
            requirement: {
              ...issue?.finrel?.requirement,
              title: requirementTitle,
              requirementType: requirementType,
            },
          },
        });
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Requirement updated successfully",
          },
        });
        setShowRequirementDrawer(false);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const handlePreview = (item) => {
    setItemToPreview(item);
    setShowPreviewDrawer(true);
    switch (item?.type) {
      case "Note":
        setNote(item?.note);
        break;

      case "Action":
        setActionTitle(item?.calendarEvent?.title);
        setActionDescription(item?.calendarEvent?.description);
        setActionDate(dayjs(item?.calendarEvent?.startDate));
        setActionDateMode(item?.actionDateMode || "1 day");
        break;

      case "Doc":
        let docs = item?.docs;
        let _files = [];
        for (let i = 0; i < docs.length; i++) {
          let doc = docs[i];
          _files = [..._files, ...doc.files];
        }
        setFiles(_files);
        break;

      default:
        break;
    }
  };

  const addTasksToIssue = async (taskIds) => {
    try {
      const { data } = await Api.post("/issue/add-tasks", {
        issueId: issue?._id,
        taskIds: taskIds,
      });
      if (data) {
        setIssue({
          ...issue,
          tasks: data.tasks,
        });

        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Tasks added successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const removeTaskFromIssue = async (taskId) => {
    try {
      const { data } = await Api.post("/issue/remove-task", {
        issueId: issue?._id,
        taskId: taskId,
      });
      if (data) {
        setIssue({
          ...issue,
          tasks: data.tasks,
        });

        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Task removed successfully",
          },
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  const [shared, setShared] = useState([]);
  const [initialProfileRole, setInitialProfileRole] = useState({});

  const assignBtn = (
    <Button variant="outlined" startIcon={<Add />}>
      Click here to assign
    </Button>
  );
  const projectProfileId =
    issue?.project?.profile?._id || issue?.project?.profile;
  const orgProfileId =
    issue?.organization?.profile?._id || issue?.organization?.profile;
  const userProfileId = user?.profile;
  //Initalize
  useEffect(() => {
    let locInitialProfileRole = {};
    if (orgProfileId) {
      locInitialProfileRole[orgProfileId] = "Owner";
    }
    if (userProfileId) {
      locInitialProfileRole[userProfileId] = "Owner";
    }
    if (projectProfileId) {
      locInitialProfileRole[projectProfileId] = "Owner";
    }
    setInitialProfileRole(locInitialProfileRole);
  }, [orgProfileId, userProfileId, projectProfileId]);

  const {
    sharedProfilesRole,
    ShareButton,
    ShareDrawer,
    SharedView,
    assignedProfiles,
    AssignButton,
    AssigneDrawer,
    AssignView,
  } = useShareAndAssign({
    initShared: initialProfileRole,
    initAssigned: null,
    resourceId: null,
    resourceName: null,
    customShareBtn: null,
    customAssignBtn: assignBtn,
  });

  const [statusOptions, setStatusOptions] = useState([]);
  const [statusOption, setStatusOption] = useState("");

  useEffect(() => {
    let template = issue?.template;
    if (template) {
      setStatusOptions(template.pipeline);
    }
  }, [issue]);

  useEffect(() => {
    setStatusOption(issue?.status);
  }, [statusOptions]);

  const [activityFilter, setActivityFilter] = useState("Note");

  useEffect(() => {
    if (!issue || !statusOption) return;
    if (issue?.status !== statusOption) {
      updateIssue(statusOption);
    }
  }, [statusOption]);

  const [mainProfileWithParent, setMainProfileWithParent] = useState(null);
  const [profileIdArr, setProfileIdArr] = useState([]);

  useEffect(() => {
    if (issue) {
      setMainProfileWithParent(issue?.profile);
      setProfileIdArr([issue?.profile?._id]);
    }
  }, [issue]);

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle={`${issue?.template?.title}`}
      appBarActions={
        <HorizBox>
          {/* <Button variant="contained" color="primary">
            Update
          </Button> */}
        </HorizBox>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <StandardAppContainerRounded>
            {lead?._id ? (
              <Box>
                <Box
                  sx={{
                    mb: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <SingleUserWithCaptionTableCell
                    label={lead?.parent?.displayName}
                    caption={
                      (String(lead?.parent?.email).trim() !== ""
                        ? lead?.parent?.email
                        : "Email N/A") +
                      " | " +
                      (String(lead?.parent?.phone).trim() !== ""
                        ? lead?.parent?.phone
                        : "Phone N/A")
                    }
                    url={lead?.parent?.displayPicture?.url}
                    labelStyleProps={{
                      fontSize: "1.1rem",
                    }}
                  />
                  <IconButton onClick={() => connectLeadWithIssue({})}>
                    <Delete />
                  </IconButton>
                </Box>

                <FormBox label="Listing">
                  <FormHeaderWithActions
                    label={lead?.listing?.title || "Untitled Listing"}
                    labelTypographyVariant="h6"
                    actions={
                      <HorizBox>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setShowUpdatePalDrawer(true);
                          }}
                        >
                          <EditOutlined />
                        </IconButton>
                      </HorizBox>
                    }
                  ></FormHeaderWithActions>
                </FormBox>

                <FormBox label="Requirement">
                  <Typography variant="h6">
                    {lead?.requirement?.requirementType || "N/A"}
                  </Typography>
                </FormBox>

                <FormBox label="Owner" labelTypographyVariant="h6">
                  <SingleUserWithCaptionTableCell
                    label={
                      lead?.requirement?.owner?.parent?.displayName || "N/A"
                    }
                    url={lead?.requirement?.owner?.parent?.displayPicture?.url}
                    labelStyleProps={{
                      fontSize: "0.9rem",
                    }}
                    avatarSx={{
                      width: "27px",
                      height: "27px",
                    }}
                  />
                </FormBox>

                <FormBox label="Price Range" labelTypographyVariant="h6">
                  <Typography variant="body1">
                    ₹ {lead?.requirement?.minPrice} - ₹{" "}
                    {lead?.requirement?.maxPrice}
                  </Typography>
                </FormBox>

                <FormBox label="Area Range" labelTypographyVariant="h6">
                  <Typography variant="body1">
                    {lead?.requirement?.minArea} - {lead?.requirement?.maxArea}{" "}
                    sqft
                  </Typography>
                </FormBox>
              </Box>
            ) : (
              <Typography variant="body2">
                This ticket is not linked to any lead,{" "}
                <span
                  style={{ color: "#1976D2", cursor: "pointer" }}
                  onClick={() => setShowLeadsDrawer(true)}
                >
                  click here
                </span>{" "}
                to add one
              </Typography>
            )}
          </StandardAppContainerRounded>

          {lead?._id && (
            <>
              <StandardAppContainerRounded>
                <FormBox label="Assign">
                  {AssignButton}
                  {AssigneDrawer}
                  {AssignView}
                </FormBox>
              </StandardAppContainerRounded>
              <StandardAppContainerRounded>
                <FormBox label="Set Status">
                  <Select
                    value={statusOption}
                    onChange={(e) => setStatusOption(e.target.value)}
                    size="small"
                    fullWidth={true}
                  >
                    {statusOptions.map((option) => {
                      return (
                        <MenuItem value={option?._id}>{option?.text}</MenuItem>
                      );
                    })}
                  </Select>
                </FormBox>
              </StandardAppContainerRounded>
            </>
          )}
        </Grid>
        <Grid item xs={9}>
          <StandardAppContainerRounded>
            <Box>
              <HorizBox>
                {actionTabOptions.map((item) => (
                  <Button
                    variant={actionTab === item.title ? "contained" : "text"}
                    color={actionTab === item.title ? "primary" : "inherit"}
                    startIcon={<item.icon />}
                    size="large"
                    onClick={(e) => setActionTab(item.title)}
                  >
                    {item.title}
                  </Button>
                ))}
              </HorizBox>
              <Box sx={{ mt: 3 }}>
                {actionTab === "Notes" && (
                  <Box>
                    <FormBox label="Leave a Note">
                      <TextField
                        placeholder="Enter"
                        fullWidth={true}
                        multiline={true}
                        rows={4}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                      <DuoButtonGroup
                        primaryButtonText="Save"
                        hideSecondary={true}
                        primaryButtonListener={() => saveNote()}
                      />
                    </FormBox>
                  </Box>
                )}
                {actionTab === "Actions" && (
                  <Box>
                    <FormBox label="Action Name">
                      <TextField
                        placeholder="Enter"
                        fullWidth={true}
                        value={actionTitle}
                        onChange={(e) => setActionTitle(e.target.value)}
                      />
                    </FormBox>
                    <FormBox label="Action Description">
                      <TextField
                        placeholder="Enter"
                        fullWidth={true}
                        multiline={true}
                        rows={4}
                        value={actionDescription}
                        onChange={(e) => setActionDescription(e.target.value)}
                      />
                    </FormBox>
                    <FormBox label="Action Date">
                      <Select
                        value={actionDateMode}
                        onChange={(e) => setActionDateMode(e.target.value)}
                        fullWidth={true}
                      >
                        {TIME_MODES.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormBox>
                    {actionDateMode === "Custom" && (
                      <Box>
                        <FormBox label="Action Date">
                          <DatePicker
                            value={actionDate}
                            onChange={(val) => setActionDate(val)}
                          />
                        </FormBox>
                        <FormBox label="Action Time">
                          <TimePicker
                            value={actionDate}
                            onChange={(val) => {
                              setActionDate(val);
                            }}
                            fullWidth={true}
                          />
                        </FormBox>
                      </Box>
                    )}

                    <DuoButtonGroup
                      primaryButtonText="Save"
                      primaryButtonListener={() => saveAction()}
                      hideSecondary={true}
                    />
                  </Box>
                )}
                {actionTab === "Documents" && (
                  <FormBox label="Upload File">
                    <CustomFileUploadButton
                      showComponent={
                        <Box
                          sx={{
                            display: "flex",
                            height: "79px",
                            padding: "16px 20px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "12px",
                            flexShrink: 0,
                            borderRadius: "10px",
                            border: "1px dashed #ABBED1",
                            background: "#F5F7FA",
                          }}
                        >
                          <img
                            src="/icons/app/document-upload.svg"
                            style={{ width: "28px", height: "28px" }}
                            alt="upload icon"
                          />
                        </Box>
                      }
                      parentType={"Doc"}
                      parentId={null}
                      fileNum={25}
                      givenMaxSize={26214400}
                      closeFunCall={() => {
                        dispatch({ type: "FileUploadReset" });
                        dispatch({
                          type: "FileReset",
                        });
                      }}
                    />
                  </FormBox>
                )}
              </Box>
            </Box>
          </StandardAppContainerRounded>
          <StandardAppContainerRounded>
            <FormBox label="Tasks" labelTypographyVariant="h5">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setShowTaskPicker(true)}
                >
                  Click here to link tasks
                </Button>
              </Box>
              <Box>
                <TaskPicker
                  open={showTaskPicker}
                  setOpen={setShowTaskPicker}
                  selectedTasks={selectedTasks}
                  setSelectedTasks={setSelectedTasks}
                  size="medium"
                  onTasksAdded={(taskIds) => {
                    addTasksToIssue(taskIds);
                  }}
                  existingTaskIds={tasks.map((item) => item?._id)}
                />
                <Box>
                  <TableContainer
                    columns={[
                      "Task Name",
                      "Creator",
                      "Progress",
                      "Start Date",
                      "End Date",
                      "Actions",
                    ]}
                    data={tasks}
                    containerHeight="auto"
                  >
                    {tasks?.map((item) => (
                      <tr
                        key={item?._id}
                        onClick={() => {
                          const isDev = config.mode == "dev";
                          var path = isDev
                            ? `/planning/task/${item?._id}`
                            : "https://planning.reallist.ai/planning/task/${item?._id}";
                          if (isDev) {
                            history.push(path);
                          } else {
                            const token = localStorage.getItem("token");
                            setAuthCookieData({ user, token });
                            window.open(path, "_blank");
                          }
                        }}
                      >
                        <td>{item?.taskName || "Untitled"}</td>
                        <td>{item?.creator?.parent?.displayName}</td>
                        <td>{item?.progress} %</td>
                        <td>{dayjs(item?.startDate).format("DD/MM/YYYY")}</td>
                        <td>{dayjs(item?.endDate).format("DD/MM/YYYY")}</td>
                        <td
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <PopupMenuList
                            appearContent={<MoreButton />}
                            actions={[
                              {
                                title: "Remove",
                                action: () => {
                                  removeTaskFromIssue(item?._id);
                                },
                              },
                            ]}
                          />
                        </td>
                      </tr>
                    ))}
                  </TableContainer>
                </Box>
              </Box>
            </FormBox>
          </StandardAppContainerRounded>
          <StandardAppContainerRounded>
            <FormHeaderWithActions
              label="Activity Board"
              labelTypographyVariant="h5"
              actions={
                <HorizBox>
                  <Select
                    value={activityFilter}
                    onChange={(e) => setActivityFilter(e.target.value)}
                    size="small"
                  >
                    <MenuItem value="Note">Notes</MenuItem>
                    <MenuItem value="Action">Actions</MenuItem>
                    <MenuItem value="Doc">Documents</MenuItem>
                    <MenuItem value="SMS">SMS</MenuItem>
                    <MenuItem value="Call">Call</MenuItem>
                  </Select>
                </HorizBox>
              }
            >
              <TableContainer
                columns={["Title", "Type", "Creator", "Date", "Actions"]}
                data={activities}
                dataloading={loadingActivities}
                containerHeight="auto"
              >
                {activities
                  ?.filter((item) => item.type === activityFilter)
                  .map((item) => (
                    <tr key={item?._id} onClick={() => handlePreview(item)}>
                      <td>{item?.title || "Untitled"}</td>
                      <td
                        style={{
                          color: "#1976D2",
                        }}
                      >
                        <HorizBox>
                          {item?.type === "Doc" ? (
                            <FilePresentOutlined />
                          ) : item?.type === "Note" ? (
                            <NoteOutlined />
                          ) : item?.type === "Action" ? (
                            <AlarmOnOutlined />
                          ) : item?.type === "SMS" ? (
                            <MessageOutlined />
                          ) : (
                            <PhoneOutlined />
                          )}
                          <span>{item?.type}</span>
                        </HorizBox>
                      </td>
                      <td>{item?.userProfile?.parent?.displayName}</td>
                      <td>{dayjs(item?.createdAt).format("DD/MM/YYYY")}</td>
                      <td onClick={(e) => e.stopPropagation()}>
                        <PopupMenuList
                          appearContent={<MoreButton />}
                          actions={[
                            {
                              title: "Delete",
                              action: () => {
                                console.log("Delete");
                              },
                            },
                          ]}
                        />
                      </td>
                    </tr>
                  ))}
              </TableContainer>

              {/* <BoxSpaceBetween>
                <Select
                  value={activityFilter}
                  onChange={(e) => setActivityFilter(e.target.value)}
                  size="small"
                >
                  <MenuItem value="Notes">Notes</MenuItem>
                  <MenuItem value="Actions">Actions</MenuItem>
                  <MenuItem value="Documents">Documents</MenuItem>
                  <MenuItem value="SMS">SMS</MenuItem>
                  <MenuItem value="Call">Call</MenuItem>
                </Select>
                <TextField
                  placeholder="Search"
                  value={activitySearchStr}
                  onChange={(e) => setActivitySearchStr(e.target.value)}
                  size="small"
                />
              </BoxSpaceBetween>
              <Box>
                {activities?.map((item) => (
                  <Grid
                    container
                    alignItems="center"
                    spacing={2}
                    key={item?._id}
                    sx={{
                      cursor: "pointer",
                      p: 1,
                      mt: 1,
                      "&: hover": {
                        backgroundColor: "#F1F1F1",
                      },
                    }}
                  >
                    <Grid item xs={3}>
                      <ImgTextChip
                        src={item?.userProfile?.parent?.displayPicture?.url}
                        label={
                          item?.userProfile?.parent?.displayName || "Untitled"
                        }
                        hideDeleteButton={true}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1">
                        {item?.title || "Untitled"}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          handlePreview(item);
                        }}
                      >
                        View {item?.type}
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Box>
                        <Typography variant="body2">
                          {dayjs(item?.createdAt).format("DD/MM/YYYY")}
                        </Typography>
                        <Typography variant="body2">
                          {dayjs(item?.createdAt).format("hh:mm a")}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </Box> */}
            </FormHeaderWithActions>
          </StandardAppContainerRounded>
        </Grid>
      </Grid>

      <DrawerContainer
        open={showPreviewDrawer}
        setOpen={setShowPreviewDrawer}
        title={itemToPreview?.type}
        onClose={() => {
          setItemToPreview(null);
          setNote("");
          setActionTitle("");
          setActionDescription("");
          setActionDate(dayjs());
        }}
      >
        <Box>
          {itemToPreview?.type === "Note" && (
            <Box>
              <TextField
                placeholder="Enter"
                fullWidth={true}
                multiline={true}
                rows={4}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <DuoButtonGroup
                primaryButtonText="Update"
                primaryButtonListener={() => updateNote()}
                secondaryButtonText="Cancel"
                secondaryButtonListener={() => {
                  setShowPreviewDrawer(false);
                }}
              />
            </Box>
          )}
          {itemToPreview?.type === "Action" && (
            <Box>
              <FormBox label="Action Name">
                <TextField
                  placeholder="Enter"
                  fullWidth={true}
                  value={actionTitle}
                  onChange={(e) => setActionTitle(e.target.value)}
                />
              </FormBox>
              <FormBox label="Action Description">
                <TextField
                  placeholder="Enter"
                  fullWidth={true}
                  multiline={true}
                  rows={4}
                  value={actionDescription}
                  onChange={(e) => setActionDescription(e.target.value)}
                />
              </FormBox>
              <FormBox label="Action Date">
                <Select
                  value={actionDateMode}
                  onChange={(e) => setActionDateMode(e.target.value)}
                  fullWidth={true}
                >
                  {TIME_MODES.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormBox>
              {actionDateMode === "Custom" && (
                <Box>
                  <FormBox label="Action Date">
                    <DatePicker
                      value={actionDate}
                      onChange={(val) => setActionDate(val)}
                    />
                  </FormBox>
                  <FormBox label="Action Time">
                    <TimePicker
                      value={actionDate}
                      onChange={(val) => {
                        setActionDate(val);
                      }}
                      fullWidth={true}
                    />
                  </FormBox>
                </Box>
              )}

              <DuoButtonGroup
                primaryButtonText="Update"
                primaryButtonListener={() => updateAction()}
                secondaryButtonText="Cancel"
                secondaryButtonListener={() => {
                  setShowPreviewDrawer(false);
                }}
              />
            </Box>
          )}
          {itemToPreview?.type === "Doc" && (
            <Box>
              <FilesViewer
                files={files}
                isDeletable={true}
                deleteFunction={async (fileId) => {
                  deleteDocByFile(fileId);
                }}
                disablePreview={false}
                disableShowMetaData={true}
                disableEditMetaData={true}
              />
            </Box>
          )}
        </Box>
      </DrawerContainer>

      <SearchEntityDialog
        entity={"User"}
        multiple={false}
        orgtype={"User"}
        curEntityId={user?.profile}
        givenFilterOption={[
          {
            option: "Contact",
            types: ["Prospect", "Other"],
          },
        ]}
        onSave={(entity) => {
          connectLeadWithIssue(entity);
          setShowLeadsDrawer(false);
        }}
        givenProfileIdArr={[issue.profile._id]}
        open={showLeadsDrawer}
        setOpen={setShowLeadsDrawer}
        title="Select Lead"
        financialRelationType="Prospect"
      />

      <CreatePalDrawer
        text=""
        openPal={showUpdatePalDrawer}
        setOpenPal={setShowUpdatePalDrawer}
        financialRelationType="Prospect"
        pal={lead}
        mainProfileWitParent={mainProfileWithParent}
        profileIdArr={profileIdArr} // TODO: Correct this
        selectEntityFunction={(newLead) => {
          console.log("New Lead: ", newLead);
          setIssue({
            ...issue,
            finrel: { ...(issue?.finrel || {}), parent: newLead },
          });
        }}
        setNewPalItem={setNewPalItem}
      />
    </StandardContainer>
  );
};

export default ProfileIssueCRM;
