import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useDebounce } from "react-use";

export default function BooleanResponse(props) {
  const { viewMode, questionId, formRes, updateApi } = props;
  const defaultValue = formRes[0]?.boolean || false;
  const strValue = defaultValue.toString();
  const [value, setValue] = useState(strValue);

  const handleChange = (event) => {
    if (!viewMode) {
      event.preventDefault();
      setValue(event.target.value);
    }
  };

  useDebounce(
    () => {
      if (questionId) {
        let formResId = formRes[0]?._id;
        let curValue = value == "true";
        const obj = {
          formResId,
          updateObj: {
            boolean: curValue,
          },
        };
        if (!viewMode) updateApi(obj);
      }
    },
    100,
    [value]
  );

  return (
    <RadioGroup
      aria-label="gender"
      name="gender1"
      value={value}
      onChange={handleChange}
      color="primary"
      style={{
        width: "50%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <FormControlLabel
        value="true"
        control={<Radio color="primary" />}
        label="True"
        disabled={viewMode}
      />
      <FormControlLabel
        value="false"
        control={<Radio color="primary" />}
        label="False"
        disabled={viewMode}
      />
    </RadioGroup>
  );
}
