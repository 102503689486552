import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import arrayToReducer from "../../helpers/arrayToReducer";
import { useDebounce } from "react-use";

export default function MultipleChoiceResponse(props) {
  const { viewMode, question, questionId, formRes, updateApi } = props;
  const [value, setValue] = useState(formRes[0]?.option || "");

  const [optionIds, setOptionIds] = useState([]);
  const [optionDict, setOptionDict] = useState({});

  useDebounce(
    () => {
      if (questionId) {
        let formResId = formRes[0]?._id;
        const obj = {
          formResId,
          updateObj: {
            option: value,
          },
        };
        if (!viewMode) updateApi(obj);
      }
    },
    100,
    [value]
  );

  const handleChange = (event) => {
    if (!viewMode) {
      event.preventDefault();
      setValue(event.target.value);
    }
  };

  useEffect(() => {
    const { idArr, newDict } = arrayToReducer(question?.options);
    setOptionIds(idArr);
    setOptionDict(newDict);
  }, []);

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={value}
        onChange={handleChange}
      >
        {optionIds.map((optionId, index) => {
          const option = optionDict[optionId];
          return (
            <FormControlLabel
              value={option?._id}
              key={index}
              control={<Radio color="primary" />}
              label={option?.optionText}
              disabled={viewMode}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
