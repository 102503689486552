import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import randomColor from "randomcolor";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
}));

function PieAnalytics(props) {
  const classes = useStyles();
  const { root } = classes;
  const { data } = props;

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className={root}>
      <PieChart width={300} height={300}>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={data?.chartData}
          cx="50%"
          cy="50%"
          outerRadius={110}
          fill="#8884d8"
          label={renderCustomizedLabel}
          labelLine={false}
        >
          {data.chartData.map((entry, index) => {
            let col = randomColor({ luminosity: "bright" });
            return <Cell key={`cell-${index}`} fill={col} />;
          })}
        </Pie>
        <Tooltip />
      </PieChart>
    </div>
  );
}

export default PieAnalytics;

//pie chart needs the formatted data containing 'name' and 'value' pairs grouped
