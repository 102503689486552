import { TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDebounce } from "react-use";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from "@material-ui/pickers";

export default function ShortAnswerResponse(props) {
  const { viewMode, questionId, formRes, updateApi } = props;
  const [selectedDate, setSelectedDate] = useState(formRes[0]?.time || "");

  const handleDateChange = (date) => {
    if (!viewMode) setSelectedDate(date);
  };

  useDebounce(
    () => {
      if (questionId) {
        let formResId = formRes[0]?._id;
        const obj = {
          formResId,
          updateObj: {
            time: selectedDate,
          },
        };
        if (!viewMode) updateApi(obj);
      }
    },
    100,
    [selectedDate]
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        variant="inline"
        margin="normal"
        id="time-picker"
        label="Select time"
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
        disabled={viewMode}
      />
    </MuiPickersUtilsProvider>
  );
}
