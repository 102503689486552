import React, { useEffect, useState } from "react";
import DrawerContainer from "./DrawerContainer";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helpers/Api";
import HorizBox from "./HorizBox";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import Select from "./Select";
import DuoButtonGroup from "./DuoButtonGroup";

const TaskPicker = ({
  open,
  setOpen,
  size = "small",
  selectedTasks,
  setSelectedTasks,
  onTasksAdded,
  existingTaskIds,
}) => {
  const { user } = useSelector((state) => state.auth);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const dispatch = useDispatch();
  const MODES = ["User", "Project", "Organization"];
  const [mode, setMode] = useState("User");
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [targets, setTargets] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState("");
  const [tasks, setTasks] = useState([]);
  const [loadingTargets, setLoadingTargets] = useState(false);
  const [loadingTasks, setLoadingTasks] = useState(false);

  const setUpOrgs = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model === "Organization") {
        setOrgs((prev) => [...prev, team?.parent]);
      }
    }
  };

  const setUpProjects = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model === "Project") {
        setProjects((prev) => [...prev, team?.parent]);
      }
    }
  };

  useEffect(() => {
    setUpOrgs();
    setUpProjects();
  }, []);

  const getTargetsByMode = async () => {
    try {
      setLoadingTargets(true);
      let _id =
        mode === "Organization"
          ? selectedOrg
          : mode === "Project"
          ? selectedProject
          : user._id;

      if (!_id) return console.log("No _id found");

      const { data } = await Api.post("/target/get-by-mode", {
        mode: mode,
        modeId: _id,
      });
      if (data) {
        setTargets(data);
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to get targets",
        },
      });
    } finally {
      setLoadingTargets(false);
    }
  };

  const getTasksByTarget = async () => {
    try {
      setLoadingTasks(true);
      if (!selectedTarget) return console.log("No selected target");

      const { data } = await Api.post("/wbs/", {
        targetId: selectedTarget,
      });
      if (data) {
        let filteredData = data?.data.filter(
          (item) => !existingTaskIds.includes(item._id)
        );
        setTasks(filteredData || []);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while fetching tasks",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching tasks",
        },
      });
    } finally {
      setLoadingTasks(false);
    }
  };

  useEffect(() => {
    getTargetsByMode();
  }, [mode, selectedOrg, selectedProject]);

  useEffect(() => {
    setSelectedTasks([]);
  }, [mode]);

  useEffect(() => {
    getTasksByTarget();
  }, [selectedTarget]);

  return (
    <DrawerContainer open={open} setOpen={setOpen} title="Select Tasks">
      <HorizBox>
        <FormControl>
          <InputLabel id="mode">Mode</InputLabel>
          <Select
            value={mode}
            onChange={(e) => setMode(e.target.value)}
            label="Mode"
            sx={{ minWidth: "120px" }}
            size={size}
          >
            {MODES.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {mode === "Project" && (
          <FormControl>
            <InputLabel id="Project">Project</InputLabel>
            <Select
              label="Project"
              value={selectedProject}
              onChange={(e) => setSelectedProject(e.target.value)}
              sx={{ minWidth: "120px" }}
              size={size}
            >
              {projects.map((item) => (
                <MenuItem key={item?._id} value={item?._id}>
                  {item?.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {mode === "Organization" && (
          <FormControl>
            <InputLabel id="Organization">Organization</InputLabel>
            <Select
              label="Organization"
              value={selectedOrg}
              onChange={(e) => setSelectedOrg(e.target.value)}
              sx={{ minWidth: "120px" }}
            >
              {orgs.map((item) => (
                <MenuItem key={item?._id} value={item?._id}>
                  {item?.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {loadingTargets ? (
          <Box>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Box>
            {targets?.length > 0 && (
              <FormControl>
                <InputLabel id="Target">Target</InputLabel>
                <Select
                  label="Target"
                  value={selectedTarget}
                  onChange={(e) => setSelectedTarget(e.target.value)}
                  sx={{ minWidth: "120px" }}
                >
                  {targets.map((item) => (
                    <MenuItem key={item?._id} value={item?._id}>
                      {item?.title || "Untitled"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        )}

        {loadingTasks ? (
          <Box>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Box>
            {tasks?.length > 0 && (
              <FormControl>
                <InputLabel id="Task">Task</InputLabel>
                <Select
                  label="Task"
                  value={selectedTasks}
                  onChange={(e) => setSelectedTasks(e.target.value)}
                  sx={{ minWidth: "120px" }}
                  multiple={true}
                >
                  {tasks.map((item) => (
                    <MenuItem key={item?._id} value={item?._id}>
                      {item?.taskName || "Untitled"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        )}
      </HorizBox>
      <DuoButtonGroup
        primaryButtonText="Add"
        primaryButtonListener={() => {
          onTasksAdded(selectedTasks);
          setOpen(false);
        }}
        secondaryButtonText="Cancel"
        secondaryButtonListener={() => setOpen(false)}
      />
    </DrawerContainer>
  );
};

export default TaskPicker;
