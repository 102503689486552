import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import ShortAnswerResponse from "./short.answer.response";
import LongAnswerResponse from "./long.answer.response";
import MultipleChoiceResponse from "./multiple.choice.response.with.value";
import BooleanResponse from "./boolean.response";
import DropdownResponse from "./dropdown.response";
import DateResponse from "./date.response";
import TimeResponse from "./time.response";
import LinearScaleResponseWithValue from "./linear.scale.response.with.value";
import NumberResponse from "./number.response";
import MultipleChoiceGridResponse from "./multiple.choice.grid.response.with.value";
import CheckboxGrid from "./checkbox.grid.response";
//form table left
import FormTableResponse from "./form.table.response";
import LinkResponse from "./link.response";
import Choose from "../select/choose";
import PlatformResponse from "./platform.response";
import ContactResponse from "./contact.response";
import FormTable from "./formtable/FormTable.js";

//file upload left

export default function QuestionTypeRendererWithValue(props) {
  const {
    viewMode,
    issue,
    question,
    type,
    updateApi,
    questionId,
    formResDict,
    qMap,
    tMap,
    setFormTMap,
    table,
    setformResDict,
    formRes,
    tableRes,
    setTableRes,
  } = props;

  const renderType = (type) => {
    if (
      type == "Organization" ||
      type == "Project" ||
      type == "User" ||
      type == "Issue" ||
      type == "Template"
    ) {
      return (
        <PlatformResponse
          type={type}
          viewMode={viewMode}
          questionId={questionId}
          formRes={formRes}
          updateApi={updateApi}
        />
      );
    }

    if (type == "Contact") {
      return (
        <ContactResponse
          type={type}
          viewMode={viewMode}
          questionId={questionId}
          formRes={formRes}
          updateApi={updateApi}
        />
      );
    }

    switch (type) {
      case "Short Answer":
        return (
          <ShortAnswerResponse
            viewMode={viewMode}
            questionId={questionId}
            formRes={formRes}
            updateApi={updateApi}
          />
        );
      case "Long Answer":
        return (
          <LongAnswerResponse
            viewMode={viewMode}
            questionId={questionId}
            formRes={formRes}
            updateApi={updateApi}
          />
        );
      case "Multiple Choice":
        return (
          <MultipleChoiceResponse
            viewMode={viewMode}
            question={question}
            questionId={questionId}
            formRes={formRes}
            updateApi={updateApi}
          />
        );
      case "Boolean":
        return (
          <BooleanResponse
            viewMode={viewMode}
            questionId={questionId}
            formRes={formRes}
            updateApi={updateApi}
          />
        );
      case "Dropdown":
        return <DropdownResponse viewMode={viewMode} question={question} />;
      case "Date":
        return (
          <DateResponse
            viewMode={viewMode}
            questionId={questionId}
            formRes={formRes}
            updateApi={updateApi}
          />
        );
      case "Time":
        return (
          <TimeResponse
            viewMode={viewMode}
            questionId={questionId}
            formRes={formRes}
            updateApi={updateApi}
          />
        );
      case "Linear Scale":
        return (
          <LinearScaleResponseWithValue
            viewMode={viewMode}
            updateApi={updateApi}
            question={question}
            questionId={questionId}
            formRes={formRes}
          />
        );
      case "Link":
        return (
          <LinkResponse
            viewMode={viewMode}
            updateApi={updateApi}
            question={question}
            questionId={questionId}
            formRes={formRes}
          />
        );
      case "Number":
        return (
          <NumberResponse
            viewMode={viewMode}
            questionId={questionId}
            formRes={formRes}
            updateApi={updateApi}
          />
        );
      case "Multiple Choice Grid":
        return (
          <MultipleChoiceGridResponse
            viewMode={viewMode}
            updateApi={updateApi}
            question={question}
            questionId={questionId}
            formRes={formRes}
          />
        );

      case "Checkbox Grid":
        return (
          <CheckboxGrid
            viewMode={viewMode}
            updateApi={updateApi}
            question={question}
            questionId={questionId}
            formRes={formRes}
          />
        );
      case "Form Table":
        return (
          <FormTable
            viewMode={viewMode}
            issue={issue}
            updateApi={updateApi}
            question={question}
            questionId={questionId}
            formRes={formRes}
            setformResDict={setformResDict}
            formResDict={formResDict}
            qMap={qMap}
            tMap={tMap}
            setFormTMap={setFormTMap}
            table={table}
            tableRes={tableRes}
            setTableRes={setTableRes}
          />
          // <FormTableResponse
          //   viewMode={viewMode}
          //   issue={issue}
          //   updateApi={updateApi}
          //   question={question}
          //   questionId={questionId}
          //   formRes={formRes}
          //   setformResDict={setformResDict}
          //   formResDict={formResDict}
          //   qMap={qMap}
          //   tMap={tMap}
          //   setFormTMap={setFormTMap}
          //   table={table}
          //   tableRes={tableRes}
          //   setTableRes={setTableRes}
          // />
        );
    }
  };
  return <>{renderType(type)}</>;
}
