const { default: Api } = require('../../helpers/Api');

export const getPublicTemplatesApiCall = async (obj) => {
    try {
        const resResult = await Api.post("issue/template/getPublicTemplates", obj);
        const data = resResult?.data || [];
        return data;
    }
    catch (err) {
        console.log(err)
    }
};