import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { green, red } from "@material-ui/core/colors";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({}));

export default function FilterSelectComponent({
  currentVal,
  items,
  title,
  condition,
  index,
  updateCondition,
  ObjectKey,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [val, setVal] = useState(currentVal);

  const handleChange = (event) => {
    setVal(event.target.value);
    let obj = condition;
    obj[ObjectKey] = event.target.value;
    updateCondition(index, obj);
  };

  useEffect(() => {
    setVal(currentVal);
  }, [currentVal]);

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      size="small"
      style={{ width: "25%" }}
    >
      <InputLabel id="demo-simple-select-outlined-label">{title}</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={val}
        onChange={handleChange}
        label="Header Name"
        // displayEmpty
        // autoWidth
      >
        {items.map((header, index) => {
          return (
            <MenuItem key={index} value={header}>
              {header}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
