import React, { useEffect, useState } from "react";
import DrawerContainer from "../styled/generic/DrawerContainer";
import FormBox from "../styled/generic/FormBox";
import TextField from "../styled/generic/TextField";
import DescriptionInput from "../styled/description.input";
import SpaceBetween from "../styled/generic/SpaceBetween";
import dayjs from "dayjs";
import DatePicker from "../styled/generic/DatePicker";
import Select from "../styled/generic/Select";
import { MenuItem } from "@mui/material";
import ProjectPicker from "../styled/generic/ProjectPicker";
import OrgPicker from "../styled/generic/OrgPicker";
import FileUploadButton from "../file/Uploader/FileUploadButton";
import { useDispatch, useSelector } from "react-redux";
import FilesViewer from "../file/Viewer/FilesViewer";
import useShareAndAssign from "../ResourceAccessControl/shareAndAssign";
import Button from "../styled/generic/Button";
import DuoButtonGroup from "../styled/generic/DuoButtonGroup";
import { updateDeleteFlagForManyFiles } from "../propertyManagement/apiCall";
import Api from "../../helpers/Api";
import useDatePickerMap from "../styled/datepicker";

const IssueEditDrawer = ({ open, setOpen, issue }) => {
  const file = useSelector((state) => state.file);
  const { createdFileIds } = file;
  const dispatch = useDispatch();

  const shareBtn = (
    <Button variant="outlined" color="primary">
      Manage Access
    </Button>
  );
  const {
    sharedProfilesRole,
    ShareButton,
    ShareDrawer,
    SharedView,
    assignedProfiles,
    AssignButton,
    AssigneDrawer,
    AssignView,
  } = useShareAndAssign({
    initShared: null,
    initAssigned: null,
    resourceId: issue?._id,
    resourceName: "Issue",
    customShareBtn: shareBtn,
    customAssignBtn: null,
  });

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(new dayjs());
  const [endDate, setEndDate] = useState(new dayjs().add(2, "day"));
  const [priority, setPriority] = useState(3);
  const [project, setProject] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [deletedImgIds, setDeletedImgIds] = useState([]);
  const [parent, setParent] = useState(null);
  const [parentModel, setParentModel] = useState("Organization");
  const [parentObject, setParentObject] = useState({});
  const [template, setTemplate] = useState("");
  const [chosenProject, setChosenProject] = useState(null);
  const [chosenOrganization, setChosenOrganization] = useState("");
  const [status, setStatus] = useState("");
  const [taskIds, setTaskIds] = useState([]);
  const [dueDate, setDueDate] = useState("");
  const [isPrivate, setPrivate] = useState(issue?.isPrivate);
  const [materialList, setMaterialList] = useState([]);
  const profileId = issue?.profile?._id;
  const { dateTimePickView, dateArr } = useDatePickerMap({
    isOrder: true,
    dateArr: [
      {
        label: "Start Time",
        value: issue?.startDate,
      },
      {
        label: "End Time",
        value: issue?.finishDate,
      },
    ],
  });

  let dep = [];
  const [dependency, setDependency] = useState(dep || []);
  const [dependencyList, setDependencyList] = useState([]);
  const PRIORITY_OPTIONS = [
    {
      label: "Very Low",
      value: 1,
    },
    {
      label: "Low",
      value: 2,
    },
    {
      label: "Medium",
      value: 3,
    },
    {
      label: "High",
      value: 4,
    },
    {
      label: "Urgent",
      value: 5,
    },
  ];

  useEffect(() => {
    if (issue?._id) {
      setTitle(issue?.title || "");
      setDescription(issue?.description || "");
      setStartDate(dayjs(issue?.start_date));
      setEndDate(dayjs(issue?.end_date));
    }
  }, [issue]);

  const removeSingleImgFromReducerAndDelete = async (selectedId) => {
    const filteredFileIds = createdFileIds.filter((id) => id != selectedId);
    let deletedImgs = deletedImgIds;
    deletedImgs.push(selectedId);
    setDeletedImgIds(deletedImgs);

    dispatch({
      type: "AddFile",
      payload: {
        createdFileIds: [...filteredFileIds],
      },
    });
  };

  const getParentId = () => {
    if (parent) {
      if (["Organization", "Project"]?.includes(parentModel)) {
        return parentObject?._id;
      } else {
        return parent[0] || null;
      }
    } else {
      return null;
    }
  };

  const updateIssueApi = async () => {
    let parentId = getParentId();
    const newStartDate = new Date(startDate).toUTCString();
    const newDueDate = new Date(dueDate).toUTCString();

    const issueObject = {
      _id: issue?._id,
      title,
      description,
      checklist: taskIds,
      template: template?._id,
      closed: template?.finalStates?.includes(status?._id),
      status: status?._id,
      parentModelName: parent ? parentModel : "",
      parent: parentId,
      project: chosenProject,
      organization: chosenOrganization,
      // shared: sharedProps?.shared,
      // assigned: sharedProps?.assigned,
      due_date: newDueDate,
      startDate: newStartDate,
      // startDate: dateArr[0],
      finishDate: dateArr[1],
      files: createdFileIds,
      priority: parseInt(priority),
      ancestors: dependency,
      isPrivate,
      materials: materialList.map(({ id, quantity }) => ({
        budgetWorkItem: id,
        quantity: quantity,
        createdBy: profileId,
        issue: issue?._id,
      })),
    };

    const requiredFields = template?.required || [];
    if (requiredFields?.includes("Project")) {
      let ip = issueObject.project;
      if (!ip) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Please choose a project",
          },
        });
        return;
      }
    }

    if (requiredFields?.includes("Organization")) {
      let io = issueObject.organization;
      if (!io) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Please choose an organization",
          },
        });
        return;
      }
    }

    const res = await Api.post("issue/update", issueObject);
    if (!res) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
      return;
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Ticket updated successfully",
        },
      });
    }

    if (deletedImgIds?.length > 0) {
      await updateDeleteFlagForManyFiles({ fileIds: deletedImgIds })
        .then((data) => {
          setDeletedImgIds([]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    dispatch({ type: "FileUploadReset" });
    //history.goBack();
  };

  useEffect(() => {
    setTitle(issue?.title);
    setDescription(issue?.description);
    setStartDate(dayjs(issue?.startDate));
    setDueDate(dayjs(issue?.due_date));
    let statusObj = issue?.template?.pipeline.filter(
      (obj) => obj?._id == issue?.status
    )[0];
    setTemplate(issue?.template);
    setStatus(statusObj);
    setPriority((issue?.priority || 1).toString());
    if (issue?.parent) {
      if (["Organization", "Project"].includes(issue?.parentModelName)) {
        setParent([issue?.parent?.profile]);
      } else {
        setParent([issue?.parent?._id]);
      }
      setParentObject(issue?.parent);
      setParentModel(issue?.parentModelName);
    }

    setChosenProject(issue?.project);
    setChosenOrganization(issue?.organization);

    let imgs = issue?.files;
    let imgIds = [];

    imgs?.length > 0 &&
      imgs.map((img) => {
        imgIds.push(img?._id);
      });

    dispatch({
      type: "AddFile",
      payload: {
        createdFileIds: imgIds,
      },
    });
  }, [issue]);

  return (
    <DrawerContainer open={open} setOpen={setOpen} title="Edit Issue">
      <FormBox label="Title">
        <TextField
          value={title}
          placeholder={"Ticket Title"}
          onChange={(event) => setTitle(event.target.value)}
          variant="outlined"
          fullWidth
        />
      </FormBox>
      <FormBox label="Description">
        <DescriptionInput
          NoTitle
          description={description}
          placeholder={"Ticket Description"}
          setDescription={setDescription}
        />
      </FormBox>
      <SpaceBetween
        left={
          <FormBox label="Start Date">
            <DatePicker value={startDate} onChange={setStartDate} />
          </FormBox>
        }
        right={
          <FormBox label="End Date">
            <DatePicker value={endDate} onChange={setEndDate} />
          </FormBox>
        }
      />

      <SpaceBetween
        left={
          <FormBox label="Project">
            <ProjectPicker
              selectedProject={project}
              setSelectedProject={setProject}
              fullWidth={true}
            />
          </FormBox>
        }
        right={
          <FormBox label="Organization">
            <OrgPicker
              selectedOrg={organization}
              setSelectedOrg={setOrganization}
              fullWidth={true}
            />
          </FormBox>
        }
      />
      <FormBox label="Priority">
        <Select
          value={priority}
          onChange={(event) => setPriority(event.target.value)}
          fullWidth
        >
          {PRIORITY_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormBox>
      <FormBox label="Files">
        <FileUploadButton
          parentType="Doc"
          used={false}
          parentId={null}
          IconColor="white"
          iconBig={true}
          aditionalText={"Add file"}
        />
        <div style={{ marginTop: "20px" }}>
          <FilesViewer
            fileIds={createdFileIds}
            deletable={true}
            handler={removeSingleImgFromReducerAndDelete}
          />
        </div>
      </FormBox>
      <FormBox label="Assign">
        {AssignButton}
        {AssigneDrawer}
        {AssignView}
      </FormBox>
      <DuoButtonGroup
        primaryButtonText="Save"
        primaryButtonListener={() => {
          updateIssueApi();
        }}
        secondaryButtonText="Cancel"
        secondaryButtonListener={() => {
          setOpen(false);
        }}
      />
    </DrawerContainer>
  );
};

export default IssueEditDrawer;
