import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, styled } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import Api from "../../helpers/Api";
import _ from "lodash";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchIcon from "@material-ui/icons/Search";
import IssueTemplateCard from "./issue.template.card";
import CreateButton from "../styled/actionBtns/create.btn";
import IssuesTemplateTable from "./issue.template.table";
import CloseIcon from "@material-ui/icons/Close";
import IssueTemplateViewManager from "./issue.template.view.manager";
import useGetTemplates from "./useGetTemplates";
import TemplateCardList from "./template.cardList";
import configObject from "../../config";
import TableChartIcon from "@material-ui/icons/TableChart";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import useProfileIssueData from "./useProfileIssueData";
import IssuesTable from "./issue.table";
import useGetProfile from "../profile/useGetProfile";
import SelectTemplate from "./select.template.dialog";
import StandardContainer from "../styled/generic/StandardContainer";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  //   root: {
  //     flex: "1",
  //     display: "flex",
  //     padding: "1rem",
  //     paddingTop: "0",
  //     minHeight: "8rem",
  //     textAlign: "right",
  //     marginLeft: "1rem",
  //     flexDirection: "column",
  //   },
  root: {
    padding: "1rem 3rem",
  },
  header_container: {
    display: "flex",
    flexDirection: "column",
    "& h3": {
      fontWeight: "500",
      fontSize: "1.2rem",
      marginBottom: "1rem",
    },
    marginBottom: "1rem",
  },
  subHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchTextBox: {
    padding: "12px 0px 12px 14px",
    background: "#F5F4F6",
    borderRadius: "8px",
    caretColor: "#2F80ED",
    fontWidth: "400",
    marginRight: "1rem",
  },
}));
const ToggleButtonPrimary = styled(ToggleButton)({
  "&.Mui-selected": {
    color: "white",
    backgroundColor: "rgb(77, 171, 245)",
  },
  "&.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#2196f3 !important",
  },
});

function IssueTemplateList(props) {
  const classes = useStyles();
  const { header_container, subHeader, searchTextBox } = classes;
  const {
    profileId,
    templateIds,
    templateDictionary,
    setTemplateIds,
    setTemplateDictionary,
    issueIds,
    setIssueIds,
    issueDictionary,
    setIssueDictionary,
    loading,
    totalIssues,
    refetchIssues,
    currentPage,
    totalPages,
  } = props;

  const [openChoose, setOpenChoose] = useState(false);

  const { auth } = useSelector((state) => state);
  const user = auth?.user;
  const dispatch = useDispatch();
  // const { profileId } = useParams();
  const history = useHistory();
  const [currentTemplateId, setCurrentTemplateId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [open, setOpen] = React.useState(false);
  const [toggleButtonValue, setToggleButtonValue] = useState("list"); // table or list
  const [grouped, setGrouped] = useState({});
  const [filterIssueIds, setFilterIssueIds] = useState([...issueIds]);
  const profile = useGetProfile(profileId);

  useEffect(() => {
    setFilterIssueIds([...issueIds]);
    const issues = issueIds.map((issueId) => issueDictionary[issueId]);

    let gr = _.groupBy(issues, function (i) {
      return i?.template?._id;
    });
    setGrouped(gr);
  }, [issueIds, issueDictionary]);

  const handleClickOpen = (id) => {
    setCurrentTemplateId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentTemplateId(null);
    history.go(0);
  };

  const createTemplate = async () => {
    const res = await Api.post("issue/template/create", {
      profile: profileId,
      shared: [profileId],
      managers: [user?.profile],
    });

    const data = res?.data;
    if (data?._id) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Team created successfully",
        },
      });
      // handleClickOpen(data?._id);
      var path = "/issue/template/" + data?._id + "/edit";
      history.push(path);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Team create failed",
        },
      });
    }
  };

  const onDelete = async (templateId) => {
    const res = await Api.post("issue/template/delete", {
      templateId: templateId,
    });

    if (res?.data) {
      if (res?.data?.delete) {
        const newTempIds = _.pull(templateIds, templateId);
        setTemplateIds(newTempIds);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Template deleted successfully",
          },
        });
      } else {
        // if not deleted then
        console.error(res);
      }
    }
  };

  return (
    <Box>
      <StandardAppContainerRounded>
        <TemplateCardList
          templateIds={templateIds}
          templateDictionary={templateDictionary}
          setTemplateIds={setTemplateIds}
          setTemplateDictionary={setTemplateDictionary}
          onDelete={onDelete}
          issueIds={filterIssueIds}
          issueDictionary={issueDictionary}
          grouped={grouped}
          profileId={profile}
        />
      </StandardAppContainerRounded>
      <StandardAppContainerRounded>
        <div className={header_container}>
          <h3>Tickets</h3>
          <div className={subHeader}>
            <TextField
              placeholder="Search by title"
              type="text"
              style={{ width: "80%" }}
              className={searchTextBox}
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchQuery && (
                  <IconButton onClick={() => setSearchQuery("")}>
                    <CancelIcon />
                  </IconButton>
                ),
                disableUnderline: true,
              }}
            />

            <SelectTemplate
              templateIds={templateIds}
              templateDictionary={templateDictionary}
              profile={profile}
            />
          </div>
        </div>

        <IssuesTable
          totalIssues={totalIssues}
          issueIds={filterIssueIds}
          issueDictionary={issueDictionary}
          profileId={profileId}
          profile={profile}
          templateIds={templateIds}
          templateDictionary={templateDictionary}
          searchQuery={searchQuery}
          refetchIssues={refetchIssues}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </StandardAppContainerRounded>
    </Box>
  );
}

export default IssueTemplateList;

{
  /* <CreateButton
            style={{ flexBasis: "20%" }}
            startIcon={<AddIcon />}
            onClick={() => {
              createTemplate();
            }}
          >
            Template
          </CreateButton> */
}

{
  /* <div>
					<ToggleButtonGroup
						value={toggleButtonValue}
						exclusive
						onChange={(event, newValue) => {
							if (newValue != null) {
								setToggleButtonValue(newValue);
							}
						}}
					>
						<ToggleButtonPrimary value="table" color="primary">
							<TableChartIcon />
						</ToggleButtonPrimary>
						<ToggleButtonPrimary value="list">
							<DashboardIcon />
						</ToggleButtonPrimary>
					</ToggleButtonGroup>
				</div> */
}

{
  /* <Dialog
					open={open}
					onClose={handleClose}
					maxWidth={"md"}

				>
					<DialogTitle style={{ margin: "2%", padding: "0px 9px" }}>
						<Grid
							container
							justifyContent="space-between"
							alignItems="center"
						>
							<Typography variant="h6">Edit Template</Typography>
							<CloseIcon
								onClick={handleClose}
								style={{ cursor: "pointer" }}
							/>
						</Grid>
					</DialogTitle>
					<DialogContent>
						<IssueTemplateViewManager
							handleClose={handleClose}
							templateId={currentTemplateId}
						/>
					</DialogContent>
				</Dialog> */
}
