import { Box, Checkbox } from "@material-ui/core";
import React, { useEffect } from "react";

export default function CheckboxCell(props) {
  const { value, setValue } = props;

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Checkbox
        color="primary"
        defaultChecked={value}
        onChange={(_, val) => setValue(val)}
      />
    </Box>
  );
}
