import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { 
  IconButton, Paper, Button, Dialog, DialogActions,
  DialogTitle, DialogContent, DialogContentText, TextField,
} from "@material-ui/core";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { useDebounce } from "react-use";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MyPopOver from "../../styled/CommonComponents/MyPopOver";
import Api from "../../../helpers/Api";
import {
  Autocomplete, Box, Chip, LinearProgress, ListItemButton, ListItemText, Typography,
} from "@mui/material";
import TaxPicker from "./TaxPicker";
import ConfirmationDialog from "../../global/ConfirmationDialog";
import CreateOffering from "../../finance2o/productsAndServices/create.offering.only";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  itemType: {
    width: "50px"
  },
  itemTitleSty: {
    width: "140px",
  },
  itemTypeSty: {
    width: "130px",
  },
  qtySty: {
    width: "30px",
  },
  unitSty: {
    width: "40px",
  },
  rateUnitSty: {
    width: "40px",
  },
  taxChipSty: {
    width: "80px",
  },

  rateUnitGSTSty: {
    width: "40px",
  },
  GSTSty: {
    width: "120px",
  },
  totalCostIncludingGSTSty: {
    width: "160px",
  },
  optionsCont: {
    padding: "5px",
  },
  singleOption: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    cursor: "pointer",
    "& img": {
      width: "20px",
      height: "auto",
      marginRight: "10px",
    },
    "& p": {
      fontSize: "15px",
      fontWeight: "500",
    },
    "&:hover": {
      backgroundColor: "#e2e2e2",
    },
  },
  showChildIcon: {
    transform: "rotate(0deg)",
    transition: "all .3s ease-in-out",
  },
  hideChildIcon: {
    transform: "rotate(-90deg)",
    transition: "all .3s ease-in-out",
  },
  titleBtnCont: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "15px",
    "& p": {
      fontSize: "13px",
      fontWeight: "500",
    },
    "& .MuiButton-root:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  btnCont: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    padding: "3px",
    borderRadius: "8px",
    marginLeft: "10px",
  },
}));

const updateBillItem = async (obj) => {
  try {
    const resData = await Api.post(
      "/procurement/ops/billlist/billitems/update-by-id",
      obj
    );
    const resultData = resData?.data;
    return resultData;
  } catch (error) {
    console.log(error);
  }
};

const BillListTableItem = ({
  showTableColumn,
  billitemData,
  createGroupItem,
  paddingLeft,
  isCollapseChild,
  setCollapseChild,
  billitems,
  setBillItems,
  updateBillItemFromDialog,
  createBillItems,
  organization,
  calculateTotalTaxes,
  getBillItems,
  loading,
  setLoading,
}) => {
  const classes = useStyles();
  const [isCollapseSubChild, setCollapseSubChild] = useState(true);
  const [title, setTitle] = useState("");
  const [curQty, setCurQty] = useState(0);
  const [curAmount, setCurAmount] = useState(0);
  const [curUnit, setCurUnit] = useState("");
  const [curRate, setCurRate] = useState(0);
  const [openItemEditDialog, setOpenItemEditDialog] = useState(false);
  const [openItemAddDialog, setOpenItemAddDialog] = useState(true);
  const [loadingMaterials, setLoadingMaterials] = useState(false);
  const [showAddBillItemDialog, setShowAddBillItemDialog] = useState(false);
  const [openTaxPicker, setOpenTaxPicker] = useState(false);
  const [selectedTaxes, setSelectedTaxes] = useState([]);
  const [taxInclusive, setTaxInclusive] = useState(0);
  const [taxExclusive, setTaxExclusive] = useState(0);
  const [offeringCreateOpen, setOfferingCreateOpen] = useState(false);
  const [offeringCreateLoading, setOfferingCreateLoading] = useState(false);
  const [tx, setTx] = useState({ type: "Bill" });
  const [resourceType, setResourceType] = useState("Material");

  const dispatch = useDispatch();

  const handleTaxes = () => {
    let amountVal = 0;
    let qtyVal = curQty || 0;
    let rateVal = curRate || 0;
    let taxIncl = 0;
    let taxExcl = 0;

    amountVal = qtyVal * rateVal;
    taxIncl = amountVal;
    taxExcl = amountVal;

    if (selectedTaxes) {
      selectedTaxes.forEach((tax) => {
        let perc = tax?.percent || 0;
        taxIncl = taxIncl + (perc / 100) * taxIncl;
      });
    }

    setCurAmount(amountVal);
    setTaxInclusive(taxIncl);
    setTaxExclusive(taxExcl);
  };

  useEffect(() => {
    handleTaxes();
  }, [billitems]);

  const updateAll = async (params) => {
    setLoading(true);
    await updateBillItem({
      name: params?.name,
      category: params?.category,
      subCategory: params?.subCategory,
      unit: params?.unit,
      qTy: params?.qTy,
      rate: params?.rate,
      _id: billitemData?._id,
    })
      .then((data) => {
        let arr = [];
        billitems.map((billitem) => {
          if (billitemData?._id == billitem?._id) {
            arr.push({
              ...billitem,
              name: data?.name,
              category: data?.category,
              subCategory: data?.subCategory,
              unit: data?.unit,
              qTy: params?.qTy,
              rate: params?.rate,
            });
          } else {
            arr.push(billitem);
          }
        });
        setBillItems(arr);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onTaxSubmit = async (newTaxes) => {
    setLoading(true);
    await updateBillItem({
      taxes: newTaxes.map((item) => item?._id),
      _id: billitemData?._id,
    })
      .then((data) => {
        let arr = [];
        billitems.map((billitem) => {
          if (billitemData?._id == billitem?._id) {
            arr.push({
              ...billitem,
              taxes: newTaxes,
            });
          } else {
            arr.push(billitem);
          }
        });
        setBillItems(arr);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setTitle(billitemData?.name || "");
    setCurQty(billitemData?.qTy || 0);
    setCurUnit(billitemData?.unit || "");
    setCurRate(billitemData?.rate || 0);
    setSelectedTaxes(billitemData?.taxes || []);
  }, [billitemData]);

  useEffect(() => {
    setCollapseSubChild(isCollapseChild);
  }, [isCollapseChild]);

  const [billItemCount, setBillItemCount] = useState(1);

  const insertBillItems = () => {
    let count = billItemCount;
    if (!isNaN(parseInt(count))) {
      if (count > 0) {
        createBillItems(
          billitemData?._id,
          billitemData?.numberOfChildren,
          billitemData,
          count
        );
        setShowAddBillItemDialog(false);
      }
    }
  };

  const [materials, setMaterials] = useState([]);

  const [selectedMaterial, setSelectedMaterial] = useState(null);

  const searchFromDefaultLibrary = async () => {
    try {
      setLoadingMaterials(true);
      const { data } = await Api.post("/wallet/offering/search-item", {
        searchStr: title,
        organizationId: organization?._id,
      });
      if (data) {
        setMaterials(data);
      }
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoadingMaterials(false);
    }
  };

  const [shouldSearch, setShouldSearch] = useState(false);

  const handleMaterialSearch = async () => {
    if (title && title.length >= 3 && shouldSearch) {
      await searchFromDefaultLibrary();
    }
  };

  useDebounce(
    () => {
      handleMaterialSearch();
    },
    500,
    [title]
  );

  const [showDeleteBillItemDialog, setShowDeleteBillItemDialog] =
    useState(false);

  const [billItemToDelete, setBillItemToDelete] = useState();

  const deleteBillItem = async () => {
    if (!billItemToDelete || !billItemToDelete?._id) {
      return;
    }

    try {
      let isBillGroup = billItemToDelete.isBillGroup;

      const { data } = await Api.post(
        "/procurement/ops/billlist/billitems/delete",
        {
          billItemId: billItemToDelete?._id,
        }
      );

      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: isBillGroup
              ? "Bill group deleted successfully"
              : "Bill item deleted successfully",
          },
        });
        getBillItems();
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to delete bill item",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to delete bill item",
        },
      });
    }
  };

  useDebounce(
    () => {
      updateAll({ ...billitemData, name: title });
    },
    300,
    [title]
  );

  useDebounce(
    () => {
      updateAll({ ...billitemData, qTy: curQty });
    },
    500,
    [curQty]
  );

  useDebounce(
    () => {
      updateAll({ ...billitemData, unit: curUnit });
    },
    500,
    [curUnit]
  );

  useDebounce(
    () => {
      updateAll({ ...billitemData, rate: curRate });
    },
    500,
    [curRate]
  );


  
  return (
    <>
      <tr
        className="workItemRow"
        style={
          isCollapseChild
            ? { display: "none", fontWeight: "700", opacity: "1" }
            : { display: "table-row", fontWeight: "700", opacity: "1" }
        }
      >
        {showTableColumn?.includes("Item Type") &&
          <td className={classes.itemType}>

            <div style={{ display: 'flex', alignItems: "center", paddingLeft: "9px" }}>
              <p>{resourceType}</p>
              <MyPopOver
                closeOnClick={true}
                appearContent={
                  <ArrowDropDownIcon style={{ cursor: 'pointer' }} />
                }
                showContent={
                  <div className={classes.optionsCont}>
                    <div
                      className={classes.singleOption}
                      onClick={() => {
                        setResourceType("Material");
                      }}
                    >
                      <p>Material</p>
                    </div>
                    <div
                      className={classes.singleOption}
                      onClick={() => {
                        setResourceType("Material + Labour");
                      }}
                    >
                      <p>Material + Labour</p>
                    </div>
                    <div
                      className={classes.singleOption}
                      onClick={() => {
                        setResourceType("Labour");
                      }}
                    >
                      <p>Labour</p>
                    </div>
                    <div
                      className={classes.singleOption}
                      onClick={() => {
                        setResourceType("Services");
                      }}
                    >
                      <p>Services</p>
                    </div>
                    <div
                      className={classes.singleOption}
                      onClick={() => {
                        setResourceType("Subcontract");
                      }}
                    >
                      <p>Subcontract</p>
                    </div>
                  </div>
                }
              />
            </div>
          </td>}
        <td className={classes.itemTitleSty}>
          <div
            className="singleItemTitleBtnCont"
            style={
              billitemData.isRateEditable
                ? billitemData.children
                  ? { paddingLeft: `${paddingLeft - 15}px` }
                  : { paddingLeft: `${paddingLeft}px` }
                : billitemData.children
                  ? { paddingLeft: `${paddingLeft - 15}px`, paddingRight: "70px" }
                  : { paddingLeft: `${paddingLeft}px`, paddingRight: "70px" }
            }
          >
            {billitemData?.isBillGroup ? (
              <>
                {billitemData.children ? (
                  <IconButton
                    style={{ padding: "4px" }}
                    onClick={() => {
                      setCollapseSubChild(!isCollapseSubChild);
                    }}
                  >
                    <ExpandMoreIcon
                      className={
                        isCollapseSubChild
                          ? classes.hideChildIcon
                          : classes.showChildIcon
                      }
                    />
                  </IconButton>
                ) : null}
                <img src="https://mybuildcost.io/app/assets/icons/dropdown-icon/item-group-icon.svg" />

                <input
                  type="text"
                  placeholder="Enter group title"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  style={
                    title && title.length > 0
                      ? billitemData?.isRateEditable
                        ? { fontWeight: "500" }
                        : { fontWeight: "400" }
                      : billitemData?.isRateEditable
                        ? { fontWeight: "400", border: "1px solid red" }
                        : { fontWeight: "400", border: "1px solid red" }
                  }
                />

                {/* {billitemData?.isRateEditable ? (
                  <>
                    <IconButton
                      className="editIconSty"
                      onClick={() => {
                        setOpenItemEditDialog(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </>
                ) : null} */}

                <MyPopOver
                  closeOnClick={true}
                  appearContent={
                    <Button className="singleItemBtnCont">
                      <AddIcon
                        style={{
                          marginRight: "5px",
                          fontSize: "15px",
                          color: "white",
                        }}
                      />
                      <ExpandMoreIcon
                        style={{ fontSize: "15px", color: "white" }}
                      />
                    </Button>
                  }
                  showContent={
                    <div className={classes.optionsCont}>
                      {billitemData?.isBillGroup && (
                        <>
                          <div
                            className={classes.singleOption}
                            onClick={() => {
                              createGroupItem(
                                billitemData?._id,
                                billitemData?.numberOfChildren,
                                billitemData
                              );
                            }}
                          >
                            <img src="https://mybuildcost.io/app/assets/icons/dropdown-icon/item-group-icon.svg" />
                            <p>Item Group</p>
                          </div>
                          <div
                            className={classes.singleOption}
                            onClick={() => {
                              setShowAddBillItemDialog(true);
                            }}
                          >
                            <img src="https://mybuildcost.io/app/assets/icons/dropdown-icon/work-item-icon.svg" />
                            <p>Bill Item</p>
                          </div>
                        </>
                      )}
                      <div
                        className={classes.singleOption}
                        onClick={() => {
                          setBillItemToDelete(billitemData);
                          setShowDeleteBillItemDialog(true);
                        }}
                      >
                        <img src="https://mybuildcost.io/app/assets/icons/dropdown-icon/work-item-icon.svg" />
                        <p>Delete</p>
                      </div>
                    </div>
                  }
                />
              </>
            ) : (
              <>
                <img
                  src="https://mybuildcost.io/app/assets/icons/dropdown-icon/work-item-icon.svg"
                  style={{ marginLeft: "20px" }}
                />

                <Autocomplete
                  value={selectedMaterial}
                  onChange={(event, newValue) => {
                    setShouldSearch(false);
                    setSelectedMaterial(newValue);
                    updateAll(newValue);
                  }}
                  options={materials}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                        {...params.inputProps}
                        type="text"
                        placeholder="Enter item title"
                        value={title}
                        onChange={(e) => {
                          setShouldSearch(true);
                          setTitle(e.target.value);
                        }}
                        style={
                          title && title.length > 0
                            ? billitemData?.isRateEditable
                              ? { fontWeight: "500" }
                              : { fontWeight: "400" }
                            : billitemData?.isRateEditable
                              ? { fontWeight: "400", border: "1px solid red" }
                              : { fontWeight: "400", border: "1px solid red" }
                        }
                      />
                    </div>
                  )}
                  loading={loadingMaterials}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    <Box
                      {...props}
                      sx={{
                        color: "#3f3f46",
                        backgroundColor: "#ffffff",
                        "&:hover": {
                          backgroundColor: "#f4f4f5",
                        },
                        cursor: "pointer",
                        padding: "2px 5px",
                      }}
                    >
                      {option?.name}
                    </Box>
                  )}
                  componentsProps={{
                    paper: {
                      sx: {
                        border: "1px solid lightgray",
                        marginLeft: 1,
                        width: 484,
                      },
                    },
                  }}
                  noOptionsText={
                    <Button
                      onClick={() => {
                        setOfferingCreateOpen(true);
                      }}
                      size="small"
                      variant="text"
                      autoCapitalize="false"
                    >
                      Create New?
                    </Button>
                  }
                />

                {/* {billitemData?.isRateEditable ? (
                  <>
                    <IconButton
                      className="editIconSty"
                      onClick={() => {
                        setOpenItemEditDialog(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </>
                ) : null} */}
                <MyPopOver
                  closeOnClick={true}
                  appearContent={
                    <Button className="singleItemBtnCont">
                      <AddIcon
                        style={{
                          marginRight: "5px",
                          fontSize: "15px",
                          color: "white",
                        }}
                      />
                      <ExpandMoreIcon
                        style={{ fontSize: "15px", color: "white" }}
                      />
                    </Button>
                  }
                  showContent={
                    <div className={classes.optionsCont}>
                      {billitemData?.isBillGroup && (
                        <>
                          <div
                            className={classes.singleOption}
                            onClick={() => {
                              createGroupItem(
                                billitemData?._id,
                                billitemData?.numberOfChildren,
                                billitemData
                              );
                            }}
                          >
                            <img src="https://mybuildcost.io/app/assets/icons/dropdown-icon/item-group-icon.svg" />
                            <p>Item Group</p>
                          </div>
                          <div
                            className={classes.singleOption}
                            onClick={() => {
                              setShowAddBillItemDialog(true);
                            }}
                          >
                            <img src="https://mybuildcost.io/app/assets/icons/dropdown-icon/work-item-icon.svg" />
                            <p>Bill Item</p>
                          </div>
                        </>
                      )}
                      <div
                        className={classes.singleOption}
                        onClick={() => {
                          setBillItemToDelete(billitemData);
                          setShowDeleteBillItemDialog(true);
                        }}
                      >
                        <img src="https://mybuildcost.io/app/assets/icons/dropdown-icon/work-item-icon.svg" />
                        <p>Delete</p>
                      </div>
                    </div>
                  }
                />
              </>
            )}
          </div>
        </td>

        <td className={classes.qtySty} style={{ padding: "0px 5px" }}>
          <input
            value={billitemData?.isBillGroup ? "" : curQty}
            onChange={(e) => {
              setCurQty(e.target.value);
            }}
            style={
              billitemData?.isRateEditable
                ? {
                  fontWeight: "400",
                  width: "100%",
                  textAlign: "right",
                  fontSize: "12px",
                }
                : {
                  fontWeight: "400",
                  width: "100%",
                  textAlign: "right",
                  fontSize: "12px",
                }
            }
            disabled={billitemData?.isBillGroup ? true : false}
            type="number"
          />
        </td>

        <td
          className={classes.rateUnitSty}
          style={
            billitemData?.isRateEditable
              ? { fontWeight: "600" }
              : { fontWeight: "400" }
          }
        >
          <input
            value={billitemData?.isBillGroup ? "" : curRate}
            onChange={(e) => {
              setCurRate(e.target.value);
            }}
            disabled={billitemData?.isBillGroup ? true : false}
            type="number"
          />
        </td>

        <td
          className={classes.unitSty}
          style={
            billitemData?.isRateEditable
              ? { fontWeight: "600" }
              : { fontWeight: "400" }
          }
        >
          <input
            value={billitemData?.isBillGroup ? "" : curUnit}
            onChange={(e) => {
              setCurUnit(e.target.value);
            }}
            disabled={billitemData?.isBillGroup ? true : false}
            type="text"
          />
        </td>
        <td className={classes.qtySty}>
          <input
            value={billitemData?.isBillGroup ? "" : curAmount}
            onChange={(e) => {
              setCurAmount(e.target.value);
            }}
            disabled={billitemData?.isBillGroup ? true : false}
            type="number"
            readOnly
          />
        </td>

        {showTableColumn?.includes("Taxes") && <td className={classes.taxChipSty}>
          {billitemData?.isBillGroup ? (
            <div></div>
          ) : (
            <div>
              {selectedTaxes && selectedTaxes.length > 0 ? (
                <div>
                  {selectedTaxes.map((item, i) => (
                    <Chip
                      id={item?._id}
                      label={item?.name}
                      variant="outlined"
                      size="small"
                      key={i}
                      onClick={() => setOpenTaxPicker(true)}
                      onDelete={() => {
                        const newTaxes = selectedTaxes.filter(
                          (tax) => tax?._id !== item?._id
                        );
                        setSelectedTaxes(newTaxes);
                        onTaxSubmit(newTaxes);
                      }}
                    />
                  ))}
                </div>
              ) : (
                <div>
                  <Chip
                    label="Add Tax"
                    variant="outlined"
                    size="small"
                    onClick={() => setOpenTaxPicker(true)}
                  />
                </div>
              )}
            </div>
          )}
        </td>}

        {showTableColumn?.includes("Tax incl.") && <td className={classes.rateUnitSty}>
          {billitemData?.isBillGroup === false &&
            `₹ ${parseFloat(taxInclusive).toFixed(2)}`}
        </td>}

        {showTableColumn?.includes("Tax excl.") &&<td className={classes.rateUnitSty}>
          {billitemData?.isBillGroup === false && `₹ ${taxExclusive}`}
        </td>}

      </tr>
      {billitemData.children &&
        billitemData.children.map((child) => (
          <BillListTableItem
            key={child._id}
            showTableColumn={showTableColumn}
            billitemData={child}
            createGroupItem={createGroupItem}
            paddingLeft={paddingLeft + 25}
            isCollapseChild={isCollapseSubChild}
            setCollapseChild={setCollapseSubChild}
            billitems={billitems}
            setBillItems={setBillItems}
            updateBillItemFromDialog={updateBillItemFromDialog}
            createBillItems={createBillItems}
            organization={organization}
            calculateTotalTaxes={calculateTotalTaxes}
            getBillItems={getBillItems}
            loading={loading}
            setLoading={setLoading}
          />
        ))}
      <TaxPicker
        open={openTaxPicker}
        setOpen={setOpenTaxPicker}
        callback={(data) => {
          setSelectedTaxes(data);
          onTaxSubmit(data);
        }}
      />
      <ConfirmationDialog
        title={
          billItemToDelete?.isBillGroup
            ? "Delete Bill Group"
            : "Delete Bill Item"
        }
        message={
          billItemToDelete?.isBillGroup
            ? "Are you sure you want to delete this bill group and all bill items associated with it?"
            : "Are you sure you want to delete this bill item?"
        }
        successButtonText="Delete"
        cancelButtonText="Cancel"
        successListener={() => {
          deleteBillItem();
        }}
        cancelListener={() => { }}
        open={showDeleteBillItemDialog}
        setOpen={setShowDeleteBillItemDialog}
      />
      <Dialog
        open={showAddBillItemDialog}
        onClose={() => setShowAddBillItemDialog(false)}
      >
        <DialogTitle>Insert Bill Items</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the number of bill items you want to insert
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Bill Item Count"
            type="number"
            fullWidth
            variant="outlined"
            value={billItemCount}
            onChange={(e) => setBillItemCount(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddBillItemDialog(false)}>
            Cancel
          </Button>
          <Button onClick={() => insertBillItems()}>Insert</Button>
        </DialogActions>
      </Dialog>

      <NormalDialog
        openDialog={offeringCreateOpen}
        handleCloseShare={() => {
          setOfferingCreateOpen(false);
        }}
        pageTitle={"Add New"}
        content={
          <div>
            <CreateOffering
              text={title || ""}
              setOpen={setOfferingCreateOpen}
              setLoading={setOfferingCreateLoading}
              onSelect={(data) => {
                setShouldSearch(false);
                setSelectedMaterial(data);
                updateAll(data);
              }}
              tx={tx}
              orgId={organization?._id}
            />
            <div style={{ width: "100%", height: "4px" }}>
              {offeringCreateLoading && <LinearProgress />}
            </div>
          </div>
        }
      />
    </>
  );
};

export default BillListTableItem;
