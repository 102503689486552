import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    "& .recharts-wrapper": {
      margin: "0 auto",
    },
  },
}));

function BarAnalytics(props) {
  const classes = useStyles();
  const { root } = classes;
  const { data } = props;

  return (
    <div className={root}>
      <BarChart data={data?.chartData} width={300} height={300}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={data?.dataKey} />
        <YAxis dataKey="count" />
        <Tooltip />
        <Bar dataKey="count" fill="#8884d8" />
      </BarChart>
    </div>
  );
}

export default BarAnalytics;

//bar like line chart needs datakey
//default:chartData,graphType,templateField
