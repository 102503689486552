import {
  LocalizationProvider,
  TimePicker as MuiTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";

const TimePicker = ({ textfieldProps, format, fullWidth = true, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiTimePicker
        format={format}
        slotProps={{
          textField: {
            sx: {
              borderRadius: "8px",
            },
            fullWidth: fullWidth,
            ...textfieldProps,
          },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default TimePicker;
