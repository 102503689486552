import DateFnsUtils from "@date-io/date-fns";
import { Menu, MenuItem, Popover, Select } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const DatePickerPopup = ({ open, setOpen, anchorEl, setValue }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (val) => {
    setValue(val);
    setOpen(false);
  };

  return (
    <Popover
      id="simple-popover"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["StaticDatePicker"]}>
          <DemoItem>
            <StaticDatePicker
              defaultValue={dayjs(new Date())}
              onAccept={(val) => handleSave(val)}
              onCancel={handleClose}
            />
          </DemoItem>
        </DemoContainer>
      </LocalizationProvider>
    </Popover>
  );
};

export default DatePickerPopup;
