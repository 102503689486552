import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: "200px",
    // height: "200px",
    margin: "auto",
  },
}));

function ScatterAnalytics(props) {
  // Assuming each issue has a 'createdAt' field with a Date value
  const classes = useStyles();
  const { root } = classes;
  const { data } = props;

  const sortedData = data?.chartData.sort(
    (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
  );

  const dateFormatter = (tick) => {
    const formattedDate = moment(tick).format("MMM DD, YYYY");
    return formattedDate;
  };

  return (
    <div className={root}>
      <LineChart
        data={sortedData}
        width={300}
        height={300}
        margin={{ top: 5, right: 5, left: 0, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={data?.dataKey} tickFormatter={dateFormatter} />
        <YAxis dataKey="value" />
        <Tooltip />
        <Line type="monotone" dataKey="value" stroke="#8884d8" />
      </LineChart>
    </div>
  );
}

export default ScatterAnalytics;

//line chart needs datakey
//default:chartData,graphType,templateField
