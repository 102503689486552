import React, { useEffect } from "react";
// import { CustomNode } from "react-flow-renderer";

const RectangularNode = ({ data }) => {
  const nodeStyles = {
    width: "200px", // Customize the width
    height: "100px", // Customize the height
    border: "2px solid #ddd", // Customize the border
    borderRadius: "0", // Set borderRadius to '0' for a rectangular shape
    background: "#fff",
  };


  return (
    <div style={nodeStyles}>
      {/* Render your node content */}
      <div>{data?.label}</div>
      <div>{data?.startDate}</div>
    </div>
  );
};

export default RectangularNode;
