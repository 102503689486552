import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PropTypes from "prop-types";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import emptyData from "../../Assets/emptyData.svg";
import * as h2p from "html2plaintext";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  InputBase,
  makeStyles,
  withStyles,
  Popper,
  TableSortLabel,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DoneIcon from "@material-ui/icons/Done";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import EditIcon from "@material-ui/icons/Edit";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import IssueDialog from "./issue.table.dialog";
import IssueDeleteDialog from "./issue.table.deleteDialog";
import IssueSelectDialog from "./issue.select.dialog";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import LessText from "../styled/CommonComponents/LessText";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { Pagination } from "@mui/material";
import IssueFilterModal from "./issue.filter.modal";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    marginLeft: "1rem",
    display: "flex",
    padding: ".5rem",
    minHeight: "8rem",
    flexWrap: "wrap",
  },
  avatarBox: {
    width: "2rem",
    padding: "0.7rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0.3rem",
      marginRight: "0.4rem",
    },
  },
  button: {
    marginRight: "1rem",
    fontSize: 13,
    width: "100%",
    textAlign: "left",
    color: "#586069",
    fontWeight: 600,
    "&:hover,&:focus": {
      color: "#0366d6",
    },
    "& span": {
      width: "100%",
    },
    "& svg": {
      width: 16,
      height: 16,
    },
  },
  tag: {
    marginTop: 3,
    height: 20,
    padding: ".15em 4px",
    fontWeight: 600,
    lineHeight: "15px",
    borderRadius: 2,
  },
  popper: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 3,
    width: 300,
    zIndex: 1,
    fontSize: 13,
    color: "#586069",
    backgroundColor: "#f6f8fa",
  },
  header: {
    borderBottom: "1px solid #e1e4e8",
    padding: "8px 10px",
    fontWeight: 600,
  },
  inputBase: {
    padding: 10,
    width: "100%",
    borderBottom: "1px solid #dfe2e5",
    "& input": {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: "1px solid #ced4da",
      fontSize: 14,
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13,
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent",
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  popperDisablePortal: {
    position: "relative",
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2,
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2,
  },
  text: {
    flexGrow: 1,
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18,
  },
  avatar: {
    width: "22px",
    height: "22px",
  },
  redButton: {
    backgroundColor: "#db2121",
    "&:hover": {
      backgroundColor: "rgb(173 21 21)",
    },
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableRowRoot: {
    "&$tableRowSelected, &$tableRowSelected:hover": {
      backgroundColor: "#2196f336",
    },
  },
  tableRowSelected: {
    backgroundColor: theme.palette.primary.main,
  },
  table_container: {
    marginTop: "1rem",
  },
  table: {
    minWidth: "1000px",
  },
  emptyBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0px 20px",
    "& img": {
      width: "320px",
      height: "auto",
      marginBottom: "15px",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "510",
      opacity: "0.9",
    },
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

export default function IssuesTable(props) {
  const history = useHistory();
  const {
    totalIssues,
    issueIds,
    onSelect,
    profileId,
    issueDictionary,
    templateIds,
    templateDictionary,
    searchQuery,
    refetchIssues,
    currentPage,
    totalPages,
  } = props;

  const classes = useStyles();
  const [issues, setIssues] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredRows, setFilteredRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [drawerIssue, setDrawerIssue] = useState({});
  const matches = useMediaQuery("(max-width:500px)");
  //open closed issues
  const [openIssuesTab, setopenIssuesTab] = React.useState(1);
  const [openIssues, setOpenIssues] = useState([]);
  const [closedIssues, setClosedIssues] = useState([]);
  const [selectedIssues, setSelectedIssues] = useState([]);

  // popper template
  const [filteredTempRows, setFilteredTempRows] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [templateFilter, setTemplateFilter] = useState([]);
  const [value, setValue] = useState([]);
  const [pendingValue, setPendingValue] = React.useState(null);
  // popper assignees
  const [assignedFilter, setAssignedFilter] = useState([]);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [value2, setValue2] = useState([]);
  const [pendingValue2, setPendingValue2] = React.useState([]);

  //table checklist
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("index");
  const [selected, setSelected] = React.useState([]);
  const [openDialogOC, setOpenDialogOC] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  // project popper
  const [filteredTempRowsProject, setFilteredTempRowsProject] = useState([]);
  const [anchorElProject, setAnchorElProject] = React.useState(null);
  const [templateFilterProject, setTemplateFilterProject] = useState([]);
  const [valueProject, setValueProject] = useState([]);
  const [pendingValueProject, setPendingValueProject] = React.useState(null);

  const defaultColumns = [
    { id: "IssueId", label: "ID", width: 40, _id: "IssueId" },
    { id: "Title", label: "TITLE", width: 80, _id: "Title" },
    {
      id: "Assigned",
      label: "ASSIGNEES",
      width: 60,
      _id: "Assigned",
    },
    { id: "DueDate", label: "DUE DATE", width: 60, _id: "Due Date" },
    { id: "Priority", label: "PRIORITY", width: 60, _id: "Priority" },
    {
      id: "Template",
      label: "STATUS",
      width: 70,
      _id: "Status",
    },
    {
      id: "CreatedBy",
      label: "AUTHOR",
      width: 60,
      _id: "Author",
    },
  ];

  const [columns, setColumns] = useState(defaultColumns);

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const {
      classes,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell
            padding="checkbox"
            align="center"
            style={{
              width: "25px",
            }}
          >
            <Checkbox
              color="primary"
              indeterminateIcon={<IndeterminateCheckBoxIcon color="primary" />}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
          {columns.map((headCell) => (
            <TableCell
              align={"center"}
              style={{ width: headCell.width }}
              key={headCell._id}
              sortDirection={orderBy === headCell._id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell._id}
                direction={orderBy === headCell._id ? order : "asc"}
                onClick={createSortHandler(headCell._id)}
              >
                {headCell.label}
                {orderBy === headCell._id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    if (date) {
      return [
        padTo2Digits(date.getDate()),
        padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join("-");
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = filteredRows.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleCheckClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setPendingValue2(value2);
    setAnchorEl2(event.currentTarget);
  };

  const handleProjectPopperClick = (event) => {
    setAnchorElProject(event.currentTarget);
  };

  const handleClose = (event, reason) => {
    if (reason === "toggleInput") {
      return;
    }
    setValue(pendingValue);
    setValue2(pendingValue2);
    if (anchorEl) {
      anchorEl.focus();
    }
    if (anchorEl2) {
      anchorEl2.focus();
    }
    setAnchorEl(null);
    setAnchorEl2(null);
    setAnchorElProject(null);
  };

  const openPopper = Boolean(anchorEl);
  const openPopper2 = Boolean(anchorEl2);
  const openProjectPopper = Boolean(anchorElProject);

  const id = openPopper ? "template-label" : undefined;
  const projectPopperId = openProjectPopper ? "template-project" : undefined;

  const handleopenIssuesTab = (event, newstate) => {
    if (newstate !== null) {
      setopenIssuesTab(newstate);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    let temp = [];
    let tempOpenIssues = [];
    let tempClosedIssues = [];

    temp = issueIds.map((id) => {
      return {
        ...issueDictionary[id],
        pipeline: {
          ...templateDictionary[issueDictionary[id]?.template?._id]?.pipeline,
        },
      };
    });

    let tempFilter = templateIds.map((key) => {
      return {
        _id: key,
        title: templateDictionary[key]?.title,
        issueHeaders: templateDictionary[key]?.issueHeaders,
        questions: templateDictionary[key]?.form?.questions,
      };
    });

    if (templateIds.length == 1) {
      setPendingValue(tempFilter[0]);
    }

    temp.map((obj) => {
      if (obj?.closed) {
        tempClosedIssues.push(obj);
      } else {
        tempOpenIssues.push(obj);
      }
    });

    setTemplateFilter([...tempFilter]);
    setIssues([...temp]);
    setOpenIssues([...tempOpenIssues]);
    setClosedIssues([...tempClosedIssues]);

    // Setting up projects
    let issuesWithProjects;
    issuesWithProjects = issueIds
      .map((id) => {
        return {
          ...issueDictionary[id],
        };
      })
      .filter((item) => item.project !== null);
    setTemplateFilterProject([...issuesWithProjects]);
  }, [issueIds, issueDictionary, templateDictionary]);

  useEffect(() => {
    if (openIssuesTab == 1) {
      setSelectedIssues([...openIssues]);
    } else {
      setSelectedIssues([...closedIssues]);
    }
    setPage(0);
  }, [openIssuesTab, openIssues, closedIssues]);

  useEffect(() => {
    let tempRows = [];
    let assignedFiltertemp = [
      { _id: "none", displayName: "Assigned to nobody" },
    ];

    tempRows = selectedIssues.map((issue, idx) => {
      let _id = issue?._id;
      let template_id = issue?.template?._id;
      let assigned_id = [];
      let index = idx + 1;
      let IssueId = issue?.issueId;
      let Title = issue?.title || "No Title";
      let Template = issue?.template?.title;
      let tempobj = {};
      let Assigned = [];
      let assignedObj = [];
      try {
        Assigned = issue?.assigned.map((ele) => {
          assignedFiltertemp.push({
            _id: ele?.parent?._id,
            displayName: ele?.parent?.displayName,
            displayPicture: ele?.parent?.displayPicture,
          });
          assignedObj.push({
            _id: ele?.parent?._id,
            displayName: ele?.parent?.displayName,
            displayPicture: ele?.parent?.displayPicture,
          });
          assigned_id.push(ele?.parent?._id);
          return `${ele?.parent?.displayName}`;
        });
      } catch (error) {
        Assigned = [];
      }
      for (const keys in issue?.pipeline) {
        // getting text and color for issue status
        if (issue?.pipeline[keys]?._id == issue?.status) {
          tempobj = { ...issue?.pipeline[keys] };
          break;
        }
      }

      let CurrentStatus = tempobj || "null";
      let CreatedBy = issue?.user?.displayName || "Not Available";
      let CreatedAt = formatDate(new Date(issue?.createdAt));
      let DueDate = formatDate(new Date(issue?.due_date));
      let Priority = issue?.priority || 1;
      let createdByUrl = issue?.user?.displayPicture?.url;
      let formResponses = issue?.formResponses || [];
      return {
        IssueId,
        Title,
        Assigned,
        DueDate,
        Priority,
        Template,
        CreatedBy,
        createdByUrl,
        CurrentStatus,
        CreatedAt,
        template_id,
        _id,
        assigned_id,
        formResponses,
        assignedObj,
      };
    });
    setAssignedFilter(
      [...new Set(assignedFiltertemp.map(JSON.stringify))].map(JSON.parse)
    );
    setRows(tempRows);
    setFilteredTempRows(tempRows);
    setFilteredRows(tempRows);
  }, [selectedIssues, openIssuesTab, issueDictionary, issues, issueIds]);

  const staticHeaders = [
    "IssueId",
    "Title",
    "Assigned",
    "Due Date",
    "Priority",
    "Status",
    "Author",
  ];

  useEffect(() => {
    if (pendingValue) {
      let filterId = pendingValue?._id;
      let headers = pendingValue?.issueHeaders;
      let questions = pendingValue?.questions;
      let newCols = [];

      headers.map((header) => {
        let qId = null;
        let qIndex = -1; //for getting the response
        let qText = ""; //for getting the question header;
        let qType = "",
          field = "";

        if (!staticHeaders.includes(header)) {
          qId = header;
          questions.map((q, idx) => {
            if (q?._id == header) {
              qIndex = idx;
              qText = q?.questionText || "Untitled";
              qType = q?.type;
            }
          });
        }
        // switch (qType) {
        //   case "Short Answer":
        //     field = "text";
        //     break;
        //   case "Long Answer":
        //     field = "text";
        //     break;
        //   case "Boolean":
        //     field = "boolean";
        //     break;
        //   case "Date":
        //     field = "date";
        //     break;
        //   case "Time":
        //     field = "time";
        //     break;
        //   case "Linear Scale":
        //     field = "value";
        //     break;
        //   case "Number":
        //     field = "numeric";
        //     break;
        //   case "Organization":
        //     field = "parent";
        //     break;
        //   case "Project":
        //     field = "parent";
        //     break;
        //   case "User":
        //       field = "parent";
        //       break;
        //   case "Contact":
        //       field = "contact";
        //       break;
        // }
        switch (header) {
          case "IssueId":
            newCols.push({
              id: "IssueId",
              label: "ID",
              width: 40,
              _id: header,
            });
            break;
          case "Title":
            newCols.push({
              id: "Title",
              label: "TITLE",
              width: 80,
              _id: header,
            });
            break;
          case "Assigned":
            newCols.push({
              id: "Assigned",
              label: "ASSIGNEES",
              width: 60,
              _id: header,
            });
            break;
          case "Due Date":
            newCols.push({
              id: "DueDate",
              label: "DUE DATE",
              width: 60,
              _id: header,
            });
            break;
          case "Priority":
            newCols.push({
              id: "Priority",
              label: "PRIORITY",
              width: 60,
              _id: header,
            });
            break;
          case "Status":
            newCols.push({
              id: "Template",
              label: "STATUS",
              width: 70,
              _id: header,
            });
            break;
          case "Author":
            newCols.push({
              id: "CreatedBy",
              label: "AUTHOR",
              width: 60,
              _id: header,
            });
            break;
          default:
            //some form question as a header
            newCols.push({
              qId,
              label: qText,
              width: 60,
              _id: header,
              qType,
            });
        }
      });

      setColumns(newCols);
      let temp = rows.filter((obj) => {
        return filterId == obj?.template_id;
      });
      setFilteredTempRows([...temp]);
      setFilteredRows([...temp]);
      setPage(0);
    } else {
      setFilteredTempRows([...rows]);
      setFilteredRows([...rows]);
      setPage(0);
    }
  }, [pendingValue]);

  useEffect(() => {
    if (searchQuery == "") {
      setFilteredRows(filteredTempRows);
      setPage(0);
    } else {
      setPage(0);
      {
        let arr = [];
        let title = "";
        arr = filteredTempRows.filter((rowObj) => {
          title = (rowObj?.Title).toLocaleLowerCase();
          return title.includes(searchQuery.toLocaleLowerCase());
        });
        setFilteredRows([...arr]);
      }
    }
  }, [searchQuery, rows]);

  const setDrawer = (row) => {
    let arr = [];
    arr = issues.filter((issue) => {
      return issue?._id == row?._id;
    });
    arr[0].CurrentStatus = row?.CurrentStatus;

    let issue = arr[0];
    console.log("issue", issue);
    if (issue?.template?.isCRM) {
      history.push("/issue/edit/" + issue?._id);
      return;
    }

    setDrawerIssue({ ...arr }[0]);
    setOpen(true);
  };
  const DrawerClose = () => {
    setDrawerIssue({});
    setOpen(false);
  };

  const clearAllFilter = () => {
    setColumns(defaultColumns);
    setFilteredTempRows([...rows]);
    setFilteredRows([...rows]);
    setPage(0);
    setPendingValue();
    setValue([]);
    setPendingValue2([]);
    setValue2([]);
  };

  const [showFilterDialog, setShowFilterDialog] = useState(false);

  return (
    <>
      {totalIssues == 0 ? (
        <div className={classes.emptyBox}>
          <img src={emptyData} />
          <p>No Tickets Found. To Create Click On New Issue</p>
        </div>
      ) : (
        <>
          <div>
            <div
              style={{
                display: "flex",
                border: "1px solid #ececec",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div>
                {selected.length == 0 ? (
                  <ToggleButtonGroup
                    value={openIssuesTab}
                    exclusive
                    onChange={handleopenIssuesTab}
                  >
                    <ToggleButton
                      value={1}
                      style={
                        openIssuesTab == 1
                          ? {
                              color: "white",
                              backgroundColor: "rgb(77,171,245)",
                            }
                          : {}
                      }
                    >
                      <RadioButtonCheckedIcon
                        style={{
                          marginRight: "0.4rem",
                        }}
                      />
                      {openIssues.length} Open
                    </ToggleButton>
                    <ToggleButton
                      value={0}
                      style={
                        openIssuesTab == 0
                          ? {
                              color: "white",
                              backgroundColor: "rgb(77,171,245)",
                            }
                          : {}
                      }
                    >
                      <DoneIcon
                        style={{
                          marginRight: "0.4rem",
                        }}
                      />
                      {closedIssues.length} Closed
                    </ToggleButton>
                  </ToggleButtonGroup>
                ) : (
                  <Typography
                    variant="h6"
                    component="div"
                    style={{
                      padding: "0.9rem",
                      fontSize: "1.1rem",
                    }}
                  >
                    {selected.length} Tickets Selected
                  </Typography>
                )}
              </div>

              <div style={{ display: "flex" }}>
                <ButtonBase
                  disableRipple
                  style={
                    selected.length == 0
                      ? {
                          display: "none",
                        }
                      : {}
                  }
                  className={classes.button}
                  onClick={() => {
                    setOpenDeleteDialog(true);
                  }}
                >
                  <span>
                    {openIssuesTab == 0 ? "Mark as Open" : "Mark As Closed"}
                  </span>
                </ButtonBase>
              </div>
              <div style={{ display: "flex" }}>
                <ButtonBase
                  disableRipple
                  style={
                    selected.length == 0
                      ? {
                          display: "none",
                        }
                      : {}
                  }
                  className={classes.button}
                  onClick={() => {
                    setOpenDialogOC(true);
                  }}
                >
                  <span>
                    {openIssuesTab == 0 ? "Mark as Open" : "Mark As Closed"}
                  </span>
                </ButtonBase>

                {/* <ButtonBase
                  disableRipple
                  className={classes.button}
                  onClick={handleProjectPopperClick}
                >
                  <span>Project</span>
                  <ArrowDropDownIcon />
                </ButtonBase>

                <ButtonBase
                  disableRipple
                  className={classes.button}
                  onClick={handleClick}
                >
                  <span>Template</span>
                  <ArrowDropDownIcon />
                </ButtonBase> */}

                <ButtonBase
                  disableRipple
                  className={classes.button}
                  onClick={() => setShowFilterDialog(true)}
                >
                  <span>Apply Filters </span>
                  <ArrowDropDownIcon />
                </ButtonBase>

                <IssueFilterModal
                  open={showFilterDialog}
                  setOpen={setShowFilterDialog}
                  profileId={profileId}
                  refetchIssues={refetchIssues}
                />

                {/* <Popper
                  id={id}
                  open={openPopper}
                  anchorEl={anchorEl}
                  placement="bottom-end"
                  className={classes.popper}
                >
                  <div className={classes.header}>Filter By Template</div>
                  <Autocomplete
                    open={openPopper}
                    onClose={handleClose}
                    multiple={false}
                    classes={{
                      paper: classes.paper,
                      option: classes.option,
                      popperDisablePortal: classes.popperDisablePortal,
                    }}
                    value={pendingValue}
                    onChange={(event, newValue) => {
                      setPendingValue(newValue);
                    }}
                    disableCloseOnSelect
                    disablePortal
                    renderTags={() => null}
                    noOptionsText="No Templates"
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <DoneIcon
                          className={classes.iconSelected}
                          style={{
                            visibility: selected ? "visible" : "hidden",
                          }}
                        />

                        <div className={classes.text}>{option.title}</div>
                        <CloseIcon
                          className={classes.close}
                          style={{
                            visibility: selected ? "visible" : "hidden",
                          }}
                        />
                      </React.Fragment>
                    )}
                    options={templateFilter}
                    getOptionLabel={(option) => option?.title}
                    renderInput={(params) => (
                      <InputBase
                        ref={params.InputProps.ref}
                        inputProps={params.inputProps}
                        autoFocus
                        className={classes.inputBase}
                      />
                    )}
                  />
                </Popper>

                <Popper
                  id={projectPopperId}
                  open={openProjectPopper}
                  anchorEl={anchorElProject}
                  placement="bottom-end"
                  className={classes.popper}
                >
                  <div className={classes.header}>Filter By Project</div>
                  <Autocomplete
                    open={openProjectPopper}
                    onClose={handleClose}
                    multiple={false}
                    classes={{
                      paper: classes.paper,
                      option: classes.option,
                      popperDisablePortal: classes.popperDisablePortal,
                    }}
                    value={pendingValueProject}
                    onChange={(event, newValue) => {
                      setPendingValueProject(newValue);
                    }}
                    disableCloseOnSelect
                    disablePortal
                    renderTags={() => null}
                    noOptionsText="No Projects"
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <DoneIcon
                          className={classes.iconSelected}
                          style={{
                            visibility: selected ? "visible" : "hidden",
                          }}
                        />

                        <div className={classes.text}>{option.title}</div>
                        <CloseIcon
                          className={classes.close}
                          style={{
                            visibility: selected ? "visible" : "hidden",
                          }}
                        />
                      </React.Fragment>
                    )}
                    options={templateFilterProject}
                    getOptionLabel={(option) => option?.title}
                    renderInput={(params) => (
                      <InputBase
                        ref={params.InputProps.ref}
                        inputProps={params.inputProps}
                        autoFocus
                        className={classes.inputBase}
                      />
                    )}
                  />
                </Popper> */}

                {/* <Popper
                  id={id}
                  open={openPopper2}
                  anchorEl={anchorEl2}
                  placement="bottom-end"
                  className={classes.popper}
                >
                  <div className={classes.header}>Filter By Assignees</div>
                  <Autocomplete
                    open={openPopper2}
                    onClose={handleClose}
                    multiple
                    classes={{
                      paper: classes.paper,
                      option: classes.option,
                      popperDisablePortal: classes.popperDisablePortal,
                    }}
                    value={pendingValue2}
                    onChange={(event, newValue) => {
                      setPendingValue2(newValue);
                    }}
                    disableCloseOnSelect
                    disablePortal
                    renderTags={() => null}
                    noOptionsText="No Assignee"
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <DoneIcon
                          className={classes.iconSelected}
                          style={{
                            visibility: selected ? "visible" : "hidden",
                          }}
                        />
                        <Avatar
                          src={option?.displayPicture?.thumbUrl}
                          style={{
                            width: "2rem",
                            height: "2rem",
                            marginRight: "1rem",
                          }}
                        />
                        <div className={classes.text}>{option.displayName}</div>
                        <CloseIcon
                          className={classes.close}
                          style={{
                            visibility: selected ? "visible" : "hidden",
                          }}
                        />
                      </React.Fragment>
                    )}
                    options={[...assignedFilter].sort((a, b) => {
                      // Display the selected labels first.
                      let ai = value2.indexOf(a);
                      ai =
                        ai === -1
                          ? value2.length + assignedFilter.indexOf(a)
                          : ai;
                      let bi = value2.indexOf(b);
                      bi =
                        bi === -1
                          ? value2.length + assignedFilter.indexOf(b)
                          : bi;
                      return ai - bi;
                    })}
                    getOptionLabel={(option) => option?.displayName}
                    renderInput={(params) => (
                      <InputBase
                        ref={params.InputProps.ref}
                        inputProps={params.inputProps}
                        autoFocus
                        className={classes.inputBase}
                      />
                    )}
                  />
                </Popper> */}
              </div>
            </div>
            <IssueDeleteDialog
              open={openDeleteDialog}
              IdArr={open ? [drawerIssue?._id] : selected}
              issueDictionary={issueDictionary}
              setOpen={setOpenDeleteDialog}
              status={open ? drawerIssue?.closed : openIssuesTab == 0}
            />
            <IssueDialog
              open={openDialogOC}
              IdArr={open ? [drawerIssue?._id] : selected}
              issueDictionary={issueDictionary}
              setOpen={setOpenDialogOC}
              status={open ? drawerIssue?.closed : openIssuesTab == 0}
            />
            <div
              style={{
                display: "flex",
              }}
            >
              {filteredTempRows.length !== rows.length ? (
                <Typography
                  style={{
                    color: "darkgrey",
                    display: "flex",
                    marginTop: "5px",
                    cursor: "pointer",
                  }}
                  onClick={clearAllFilter}
                >
                  <CancelPresentationIcon sx={{ paddingRight: 2 }} />
                  Clear All Filters.
                </Typography>
              ) : null}
            </div>
          </div>
          <TableContainer component={Paper} className={classes.table_container}>
            <Table
              // stickyHeader
              aria-label="sticky table"
              className={classes.table}
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(filteredRows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, idx) => {
                    const isItemSelected = isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${idx}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={idx}
                        color="primary"
                        selected={isItemSelected}
                        style={{ cursor: "pointer" }}
                        onClick={() => setDrawer(row)}
                        classes={{
                          root: classes.tableRowRoot,
                          selected: classes.tableRowSelected,
                        }}
                      >
                        <TableCell
                          align="center"
                          padding="checkbox"
                          style={{
                            width: "25px",
                          }}
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleCheckClick(event, row._id);
                            }}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        {columns.map((column, idx) => {
                          let value = row;
                          const col_id = column._id;
                          let formRes = null;
                          if (!staticHeaders.includes(col_id)) {
                            // this is a form question
                            const formResponses = row?.formResponses;
                            const resIndex = formResponses.findIndex(
                              (e) => e.question === col_id
                            );
                            if (resIndex >= 0) {
                              formRes = formResponses[resIndex];
                            }
                          }
                          // const fieldNames = column.id
                          //   .match(/(\w+)|\[\d+\]/g)
                          //   .map((fieldName) =>
                          //     fieldName.replace(/\[|\]/g, "")
                          //   );

                          // for (const fieldName of fieldNames) {
                          //   const index = parseInt(fieldName);

                          //   if (!isNaN(index)) {
                          //     const array = value;

                          //     if (Array.isArray(array)) {
                          //       value = array[index];
                          //     } else {
                          //       value = undefined; // Property is not an array
                          //       break;
                          //     }
                          //   } else {
                          //     value = value[fieldName];
                          //   }

                          //   if (value === undefined) {
                          //     break;
                          //   }
                          // }

                          let priority_color = "grey";
                          let priority_text = "low";
                          switch (row?.Priority) {
                            case 1:
                              priority_color = "grey";
                              priority_text = "Very Low";
                              break;
                            case 2:
                              priority_color = "green";
                              priority_text = "Low";
                              break;
                            case 3:
                              priority_color = "yellow";
                              priority_text = "Medium";
                              break;
                            case 4:
                              priority_color = "orange";
                              priority_text = "High";
                              break;
                            case 5:
                              priority_color = "red";
                              priority_text = "Urgent";
                              break;
                          }
                          return (
                            <TableCell
                              style={{
                                width: column.width,
                              }}
                              key={column?._id}
                              align="center"
                            >
                              {(() => {
                                switch (column._id) {
                                  case "IssueId":
                                    return value.IssueId;
                                  case "Title":
                                    return value.Title;
                                  case "Assigned":
                                    return value.assignedObj.length > 0 ? (
                                      <div
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <div>
                                          <AvatarGroup max={5}>
                                            {value.assignedObj &&
                                              value.assignedObj.length > 0 &&
                                              value.assignedObj.map(
                                                (data, i) => (
                                                  <Avatar
                                                    key={i}
                                                    src={
                                                      data?.displayPicture
                                                        ?.thumbUrl
                                                        ? data?.displayPicture
                                                            ?.thumbUrl
                                                        : data?.displayPicture
                                                            ?.url
                                                    }
                                                    alt={data?.displayName}
                                                    style={{
                                                      width: "33px",
                                                      height: "33px",
                                                    }}
                                                  />
                                                )
                                              )}
                                          </AvatarGroup>
                                        </div>
                                      </div>
                                    ) : (
                                      "No Assignee"
                                    );
                                  case "Due Date":
                                    return value.DueDate;
                                  case "Priority":
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        <FiberManualRecordIcon
                                          style={{ color: priority_color }}
                                        />
                                        <span>{priority_text}</span>
                                      </div>
                                    );
                                  case "Status":
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "100%",
                                          }}
                                        >
                                          {value?.Template}
                                        </div>
                                        <div
                                          style={{
                                            width: "max-content",
                                            backgroundColor:
                                              row?.CurrentStatus?.color,
                                            borderRadius: "15px",
                                            margin: "0.5rem",
                                            padding:
                                              "0.1rem 0.4rem 0.1rem 0.4rem",
                                          }}
                                        >
                                          {row?.CurrentStatus?.text}
                                        </div>
                                      </div>
                                    );
                                  case "Author":
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Avatar
                                          src={row?.createdByUrl}
                                          className={classes.avatar}
                                        />{" "}
                                        &nbsp; {value?.CreatedBy}
                                      </div>
                                    );
                                  default:
                                    //   for form questions;
                                    switch (column?.qType) {
                                      case "Short Answer":
                                        return <>{formRes?.text}</>;
                                        break;
                                      case "Long Answer":
                                        return <>{formRes?.text}</>;
                                        break;
                                      case "Boolean":
                                        return <>{formRes?.boolean}</>;
                                        break;
                                      case "Date":
                                        return <>{formRes?.date}</>;
                                        break;
                                      case "Time":
                                        return <></>;
                                        break;
                                      case "Linear Scale":
                                        return <>{formRes?.value}</>;
                                        break;
                                      case "Number":
                                        return <>{formRes?.numeric}</>;
                                        break;
                                      case "Organization":
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: "10px",
                                            }}
                                          >
                                            <Avatar
                                              src={
                                                formRes?.parent?.displayPicture
                                                  ?.thumbUrl ||
                                                formRes?.parent?.displayPicture
                                                  ?.url
                                              }
                                              alt={formRes?.parent?.displayName}
                                              style={{
                                                width: "33px",
                                                height: "33px",
                                              }}
                                            />
                                            <div style={{ marginLeft: "5px" }}>
                                              <h3
                                                style={{
                                                  fontSize: "13px",
                                                  fontWeight: "500",
                                                  textAlign: "left",
                                                }}
                                              >
                                                <LessText
                                                  limit={15}
                                                  string={
                                                    formRes?.parent?.displayName
                                                  }
                                                />
                                              </h3>
                                              <p
                                                style={{
                                                  fontSize: "10px",
                                                  fontWeight: "400",
                                                  textAlign: "left",
                                                }}
                                              >{`Added ${moment(
                                                formRes?.createdAt
                                              ).format("DD MMM YYYY")}`}</p>
                                            </div>
                                          </div>
                                        );
                                        break;
                                      case "Project":
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: "10px",
                                            }}
                                          >
                                            <Avatar
                                              src={
                                                formRes?.parent?.displayPicture
                                                  ?.thumbUrl ||
                                                formRes?.parent?.displayPicture
                                                  ?.url
                                              }
                                              alt={formRes?.parent?.displayName}
                                              style={{
                                                width: "33px",
                                                height: "33px",
                                              }}
                                            />
                                            <div style={{ marginLeft: "5px" }}>
                                              <h3
                                                style={{
                                                  fontSize: "13px",
                                                  fontWeight: "500",
                                                  textAlign: "left",
                                                }}
                                              >
                                                <LessText
                                                  limit={15}
                                                  string={
                                                    formRes?.parent?.displayName
                                                  }
                                                />
                                              </h3>
                                              <p
                                                style={{
                                                  fontSize: "10px",
                                                  fontWeight: "400",
                                                  textAlign: "left",
                                                }}
                                              >{`Added ${moment(
                                                formRes?.createdAt
                                              ).format("DD MMM YYYY")}`}</p>
                                            </div>
                                          </div>
                                        );
                                        break;
                                      case "User":
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: "10px",
                                            }}
                                          >
                                            <Avatar
                                              src={
                                                formRes?.parent?.displayPicture
                                                  ?.thumbUrl ||
                                                formRes?.parent?.displayPicture
                                                  ?.url
                                              }
                                              alt={formRes?.parent?.displayName}
                                              style={{
                                                width: "33px",
                                                height: "33px",
                                              }}
                                            />
                                            <div style={{ marginLeft: "5px" }}>
                                              <h3
                                                style={{
                                                  fontSize: "13px",
                                                  fontWeight: "500",
                                                  textAlign: "left",
                                                }}
                                              >
                                                <LessText
                                                  limit={15}
                                                  string={
                                                    formRes?.parent?.displayName
                                                  }
                                                />
                                              </h3>
                                              <p
                                                style={{
                                                  fontSize: "10px",
                                                  fontWeight: "400",
                                                  textAlign: "left",
                                                }}
                                              >{`Added ${moment(
                                                formRes?.createdAt
                                              ).format("DD MMM YYYY")}`}</p>
                                            </div>
                                          </div>
                                        );
                                        break;
                                      case "Contact":
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: "10px",
                                            }}
                                          >
                                            <Avatar
                                              src={
                                                formRes?.contact?.profile
                                                  ?.parent?.displayPicture
                                                  ?.thumbUrl ||
                                                formRes?.contact?.profile
                                                  ?.parent?.displayPicture?.url
                                              }
                                              alt={
                                                formRes?.contact?.profile
                                                  ?.parent?.displayName
                                              }
                                              style={{
                                                width: "33px",
                                                height: "33px",
                                              }}
                                            />
                                            <div style={{ marginLeft: "5px" }}>
                                              <h3
                                                style={{
                                                  fontSize: "13px",
                                                  fontWeight: "500",
                                                  textAlign: "left",
                                                }}
                                              >
                                                <LessText
                                                  limit={15}
                                                  string={
                                                    formRes?.contact?.profile
                                                      ?.parent?.displayName
                                                  }
                                                />
                                              </h3>
                                              <p
                                                style={{
                                                  fontSize: "10px",
                                                  fontWeight: "400",
                                                  textAlign: "left",
                                                }}
                                              >{`Added ${moment(
                                                formRes?.createdAt
                                              ).format("DD MMM YYYY")}`}</p>
                                            </div>
                                          </div>
                                        );
                                        break;
                                    }
                                }
                              })()}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            ActionsComponent={TablePaginationActions}
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            margin={2}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={(evt, newPage) => {
                refetchIssues(newPage);
              }}
              variant="outlined"
              shape="rounded"
            />
          </Box>

          {/* create the dialog here */}
          <IssueSelectDialog
            open={open}
            setOpen={setOpen}
            setOpenDialogOC={setOpenDialogOC}
            issue={drawerIssue}
          />
        </>
      )}
    </>
  );
}
