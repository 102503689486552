import Api from '../../../helpers/Api';

export const getBankAccounts = async (obj) => {
    try {
        const res = await Api.post('wallet/get/bank-accounts', obj);
        const result = res?.data;
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const updateBankAccount = async (obj) => {
    try {
        const res = await Api.post('wallet/update/bank-accounts/by-id', obj);
        const result = res?.data;
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getPaymentConfigs = async (obj) => {
    try {
        const res = await Api.post('wallet/payment/config/get', obj);
        const result = res?.data;
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getDwollaData = async (obj) => {
    try {
        const res = await Api.post('dwolla/get/customer', obj);
        const result = res?.data;
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getPopulatedWalletData = async (obj) => {
    try {
        const res = await Api.post("wallet/get/populated-data", obj);
        const result = res?.data;
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getWalletDataByProfileId = async (obj) => {
    try {
        const res = await Api.post("get/wallet/by-profileId", obj);
        const result = res?.data;
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const linkDwollaWithPlaid = async (obj) => {
    try {
        const res = await Api.post("dwolla/addFundingSrc", obj);
        const result = res?.data;
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};