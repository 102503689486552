import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import CommentIcon from "@material-ui/icons/Comment";
import arrayToReducer from "../../helpers/arrayToReducer";
import "./status.css";
import "./card.css";
import {
	Chip,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	InputBase,
	Radio,
	RadioGroup,
	Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import IssueGraph from "./issue.graph";
import { MarkerType } from "react-flow-renderer";
import Api from "../../helpers/Api";
import { useSelector } from "react-redux";
import { useDebounce } from "react-use";
import ColorSelect from "../styled/color/color.select";
import { ColorBlob } from "../styled/color/color.blob";
import Kanban from "../styled/Kanban/Kanban";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

var getContrast = function (hexcolor) {
	// If a leading # is provided, remove it
	if (!hexcolor) return "white";
	if (hexcolor.slice(0, 1) === "#") {
		hexcolor = hexcolor.slice(1);
	}

	// If a three-character hexcode, make six-character
	if (hexcolor.length === 3) {
		hexcolor = hexcolor
			.split("")
			.map(function (hex) {
				return hex + hex;
			})
			.join("");
	}

	// Convert to RGB value
	var r = parseInt(hexcolor.substr(0, 2), 16);
	var g = parseInt(hexcolor.substr(2, 2), 16);
	var b = parseInt(hexcolor.substr(4, 2), 16);

	// Get YIQ ratio
	var yiq = (r * 299 + g * 587 + b * 114) / 1000;

	// Check contrast
	return yiq >= 128 ? "black" : "white";
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		// maxWidth: "34rem",
		backgroundColor: theme.palette.background.paper,
	},
	text: {
		fontSize: 20,
		fontWeight: "bold",
		margin: "1rem 1rem",
	},

	title: {
		fontWeight: "600",
		margin: "1rem 1rem",
	},

	textInput: {
		// maxWidth: "24rem",
		width: "100%",
		borderWidth: "1px",
		backgroundColor: "#f1f1f1",
		color: "#424242",
		borderRadius: "11px",
		padding: "0.3rem",
		marginRight: "0.5rem",
		fontSize: 16,
		zIndex: 0,
	},
}));

const reactFlowStyle = {
	background: "white",
	width: "100%",
	height: 300,
};
const position = { x: 0, y: 0 };
const edgeType = "smoothstep";
export default function StatusList(props) {
	const classes = useStyles();
	const auth = useSelector((state) => state.auth);
	const user = auth?.user;
	const {
		template,
		setTemplate,
		statusIds,
		setStatusIds,
		statusDict,
		setStatusDict,
		startState,
		setStartState,
		finalStates,
		setFinalStates,
		updateTemplate,
	} = props;
	const [showComment, setShowComment] = useState(false);
	const [activeStatus, setActiveStatus] = useState(null);
	const [text, setText] = useState("");
	const [disableState, setDisableState] = useState(0);
	const [color, setColor] = useState("");
	const [columns, setColumns] = useState([]);
	const [sideColumns, setSideColumns] = useState([]);
	// active status?._id
	const [textStatus, setTextStatus] = useState(null);
	const [statusColor, setStatusColor] = useState(null);
	const [isFinal, setIsFinal] = useState(false);
	const [finalId, setFinalId] = useState("");
	const [checked, setChecked] = useState(false);
	const [initialNodes, setInitialNodes] = useState([
		{
			id: "start",
			type: "input",
			data: {
				label: "Start",
				// issue: startArr[0],
			},
			position: {
				x: 0,
				y: 0,
			},
			style: {
				backgroundColor: "#53d769",
			},
			targetPosition: "top",
			sourcePosition: "bottom",
		},
		{
			id: "finish",
			type: "output",
			data: {
				label: "Finish",
				// issue: endArr[0],
			},
			position: {
				x: 0,
				y: 172,
			},
			style: {
				backgroundColor: "rgb(239 85 5)",
			},
			targetPosition: "top",
			sourcePosition: "bottom",
		},
	]);
	const [initialEdges, setInitialEdges] = useState([
		{
			id: "default",
			source: "start",
			target: "finish",
			type: "smoothstep",
			animated: false,
			style: {
				color: "black",
			},
			markerEnd: {
				type: "arrowclosed",
			},
		},
	]);
	useDebounce(
		() => {
			if (textStatus) {
				updateApi({
					_id: textStatus,
					text,
				});
			}
		},
		1000,
		[text]
	);

	useEffect(() => {
		if (template?.pipeline.length > 0) {
			const { newDict, idArr } = arrayToReducer(template?.pipeline);
			setStatusIds(idArr);
			setStatusDict(newDict);
		} else {
			createDefault();
		}
	}, [template?.pipeline?.length]);
	useEffect(() => {
		if (template?.pipeline.length != 0) {
			setStartState(template.startState);
			setFinalStates([...template?.finalStates]);
			// setDisableState(template.startState);
		}
	}, [template]);

	const onAdd = async (index) => {
		const res = await Api.post("issue/status/create", {
			index,
			template: template?._id || null,
			text: "",
			color: "#fcb900",
			user: user?._id,
		});

		const data = res?.data;
		setTemplate(data?.issueTemplate);

		// const status = data?.status;

		// const { newDict } = arrayToReducer([status]);

		// setStatusIds([
		// 	...statusIds.slice(0, index),
		// 	status?._id,
		// 	...statusIds.slice(index),
		// ]);
		// setStatusDict({
		// 	...statusDict,
		// 	...newDict,
		// });
	};
	const updateTemplateInBackend = async () => {
		let updateObj = {
			_id: template?._id,
			pipeline: statusIds,
			finalStates: finalStates,
			startState: startState,
		};

		await Api.post("issue/template/update", updateObj)
			.then((res) => {
				if (res?.data) {
					const t = res?.data;
					// setTemplate(res?.data);
				}
			})
			.catch((err) => {
				console.log("error as :", err);
			});
	};
	const createDefault = async () => {
		if (template?._id) {
			const res = await Api.post("issue/status/default", {
				template: template?._id,
			});

			const data = res?.data;

			const items = data?.items;

			const { newDict, idArr } = arrayToReducer(items);

			setStatusDict({
				...statusDict,
				...newDict,
			});

			setStatusIds(idArr);
			setStartState(idArr[0]);
			setFinalStates([idArr[2], idArr[3]]);
			setDisableState(idArr[0]);
		}
	};

	const onDelete = async (statusId, index) => {
		setStatusIds([
			...statusIds.slice(0, index),
			...statusIds.slice(index + 1),
		]);
		setStatusDict((prev) => {
			const { statusId, ...rest } = prev;
			return rest;
		});

		const res = await Api.post("issue/status/delete", {
			statusId,
		});
	};

	const updateApi = async (status) => {
		const res = await Api.post("issue/status/update", status);
	};
	const handleStartChange = (statusId) => {
		// setStartState(statusId);
		// setDisableState(event.target.value);
		if (finalStates.includes(statusId)) {
			let temp = finalStates.filter((ele) => ele != statusId);
			setFinalStates([...temp]);
		}
		setTemplate((prev) => {
			return {
				...prev,
				startState,
				finalStates,
			};
		});
		updateTemplateInBackend();
	};
	const handleCheck = (statusId) => {
		return finalStates.includes(statusId);
	};
	const handleFinalChange = (statusId) => {
		if (finalStates.includes(statusId)) {
			let temp = finalStates.filter((ele) => ele != statusId);
			setFinalStates([...temp]);
		} else {
			setFinalStates([...finalStates, statusId]);
		}
	};
	const onDragEnd = (result, columns, setColumns) => {
		if (!result.destination) return;
		const { source, destination } = result;

		const column = columns[source.droppableId];
		const copiedItems = [...column.items];
		const [removed] = copiedItems.splice(source.index, 1);
		copiedItems.splice(destination.index, 0, removed);
		let temp = copiedItems.map((obj) => obj?._id);
		setStatusIds([...temp]);
		// handleStartChange(copiedItems[0]._id);
		setStartState(copiedItems[0]._id);
		setColumns({
			...columns,
			[source.droppableId]: {
				...column,
				items: copiedItems,
			},
		});
	};
	const onDragEndSide = (result, columns, setColumns) => {
		if (!result.destination) return;
		const { source, destination } = result;
		if (source.droppableId !== destination.droppableId) {
			const sourceColumn = columns[source.droppableId];
			const destColumn = columns[destination.droppableId];

			if (
				sourceColumn.minItems &&
				sourceColumn.minItems == sourceColumn.items.length
			) {
				return;
			}

			const sourceItems = [...sourceColumn.items];
			const destItems = [...destColumn.items];
			const [removed] = sourceItems.splice(source.index, 1);
			destItems.splice(destination.index, 0, removed);
			const sourceItemIds = sourceItems.map((item) => item._id);
			const destItemIds = destItems.map((item) => item._id);

			if (source.droppableId == "1") {
				setFinalStates(destItemIds);
			} else {
				setFinalStates(sourceItemIds);
			}
			setColumns({
				...columns,
				[source.droppableId]: {
					...sourceColumn,
					items: sourceItems,
				},
				[destination.droppableId]: {
					...destColumn,
					items: destItems,
				},
			});
		} else {
			const column = columns[source.droppableId];
			const copiedItems = [...column.items];
			const [removed] = copiedItems.splice(source.index, 1);
			copiedItems.splice(destination.index, 0, removed);
			setColumns({
				...columns,
				[source.droppableId]: {
					...column,
					items: copiedItems,
				},
			});
		}
	};
	const updateGraph = () => {
		let leftIds = statusIds.filter(
			(id) => startState !== id && !finalStates.includes(id)
		);
		let tempNodes = [
			{
				id: startState,
				type: "input",
				data: { label: statusDict[startState]?.text },
				position,
				style: {
					backgroundColor: statusDict[startState]?.color,
					color: getContrast(statusDict[startState]?.color),
					width: "auto",
					height: "auto",
				},
			},
			...leftIds.map((id) => {
				let obj = statusDict[id];
				return {
					id: id,
					data: {
						label: obj?.text,
					},
					position,
					style: {
						backgroundColor: obj?.color,
						color: getContrast(obj?.color),
						width: "auto",
						height: "auto",
					},
				};
			}),
			...finalStates.map((id) => {
				let obj = statusDict[id];
				return {
					id: id,
					data: {
						label: obj?.text,
					},
					type: "output",
					position,
					style: {
						backgroundColor: obj?.color,
						color: getContrast(obj?.color),
						width: "auto",
						height: "auto",
					},
				};
			}),
			{
				id: "finish",
				data: { label: "Final States" },
				position,
				style: {
					backgroundColor: "rgb(239 85 5)",
					color: getContrast("#ef5505"),
					width: "auto",
					height: "auto",
				},
			},
		];
		let tempEdges = [];
		if (leftIds.length) {
			tempEdges.push(
				{
					id: `${startState}${leftIds[0]}`,
					source: startState,
					target: leftIds[0],
					type: edgeType,
					animated: false,
					style: { color: "black" },
					markerEnd: {
						type: MarkerType.ArrowClosed,
					},
				},
				{
					id: `finish${leftIds[leftIds.length - 1]}`,
					source: leftIds[leftIds.length - 1],
					target: "finish",
					type: edgeType,
					animated: false,
					style: { color: "black" },
					markerEnd: {
						type: MarkerType.ArrowClosed,
					},
				}
			);
		} else {
			tempEdges.push({
				id: `${startState}final`,
				source: startState,
				target: "finish",
				type: edgeType,
				animated: false,
				style: { color: "black" },
				markerEnd: {
					type: MarkerType.ArrowClosed,
				},
			});
		}

		for (let i = 0; i < leftIds.length - 1; i++) {
			tempEdges.push({
				id: `${leftIds[i]}${leftIds[i + 1]}`,
				source: leftIds[i],
				target: leftIds[i + 1],
				type: edgeType,
				animated: false,
				style: { color: "black" },
				markerEnd: {
					type: MarkerType.ArrowClosed,
				},
			});
		}
		finalStates.map((id) => {
			tempEdges.push({
				id: `${id}finish`,
				source: "finish",
				target: id,
				type: edgeType,
				animated: false,
				style: { color: "black" },
				markerEnd: {
					type: MarkerType.ArrowClosed,
				},
			});
		});
		setInitialEdges([...tempEdges]);
		setInitialNodes([...tempNodes]);
	};

	useEffect(() => {
		let cols = {
			1: {
				title: "All States",
				items: statusIds.map((statusId, index) => {
					const status = statusDict[statusId];
					let obj = {
						_id: statusId,
						content: (
							<div
								key={index}
								style={{
									display: "flex",
									width: "100%",
									border: "1px solid lightgrey",
									paddingRight: "9px",
									borderRadius: "17px",
									position: "relative",
								}}
							>
								<Chip
									label="Start State"
									size="small"
									style={
										statusId == startState
											? {
												position: "absolute",
												backgroundColor:
													"rgb(19, 135, 19)",
												color: "white",
												top: "-11px",
												left: "3px",
											}
											: { display: "none" }
									}
								/>
								<Chip
									label="Final State"
									size="small"
									style={
										finalStates.includes(statusId)
											? {
												position: "absolute",
												backgroundColor: "#2196f3",
												color: "white",
												top: "-11px",
												left: "3px",
											}
											: { display: "none" }
									}
								/>

								<ListItem
									key={statusId}
									role={undefined}
									dense
									style={{
										paddingRight: 0,
										paddingLeft: 0,
									}}
								>
									<ListItemIcon>
										<DragIndicatorIcon />
										<ColorBlob
											open={statusId == statusColor}
											setOpen={() => {
												if (statusId == statusColor) {
													setStatusColor(null);
												} else {
													setStatusColor(statusId);
												}
											}}
											color={status?.color}
											setColor={(newColor) => {
												var newStatus = {
													...status,
													color: newColor,
												};
												const { newDict } =
													arrayToReducer([newStatus]);
												setStatusDict({
													...statusDict,
													...newDict,
												});

												updateApi(newStatus);
											}}
										/>
									</ListItemIcon>

									<InputBase
										style={{ width: "90%" }}
										value={status?.text}
										className={classes.textInput}
										onChange={(event) => {
											const text = event.target.value;
											var newStatus = {
												...status,
												text,
											};
											const { newDict } = arrayToReducer([
												newStatus,
											]);

											setStatusDict({
												...statusDict,
												...newDict,
											});

											setTextStatus(status?._id);
											setText(text);
										}}
									/>
									<div style={{ display: "inline-flex" }}>
										<IconButton
											edge="end"
											aria-label="Add"
											onClick={() => {
												onAdd(index);
												setShowComment(false);
											}}
										>
											<AddIcon />
										</IconButton>

										<IconButton
											edge="end"
											aria-label="Delete"
											disabled={statusIds.length == 1}
											onClick={() => {
												onDelete(statusId, index);
											}}
										>
											<DeleteIcon />
										</IconButton>
										<div>
											<Checkbox
												id={statusId}
												style={
													statusId == startState
														? { color: "lightgray" }
														: { color: "#2196f3" }
												}
												disabled={
													statusId == startState
												}
												checked={finalStates.includes(
													statusId
												)}
												onClick={(e) => {
													let checkFlag =
														e.target.checked;
													let temp = [...finalStates];
													if (checkFlag) {
														temp = [
															...temp,
															statusId,
														];
													} else {
														temp = temp.filter(
															(id) =>
																id != statusId
														);
													}
													setFinalStates([...temp]);
													setTemplate((prev) => {
														return {
															...prev,
															finalStates: [
																...temp,
															],
														};
													});
													updateTemplateInBackend();
												}}
											// onChange={(e)=>{
											//   let unique = e.target.id;
											//   if(unique==finalStates[0]|| unique==finalStates[1]){
											//       setChecked(!checked);
											//     }
											// }}
											/>
										</div>
									</div>
								</ListItem>
							</div>
						),
					};
					return obj;
				}),
			},
		};
		setColumns(cols);
	}, [statusDict, statusIds, startState, statusColor, finalStates, template]);
	useEffect(() => {
		updateGraph();
	}, [statusIds, startState, finalStates, statusDict]);
	// useEffect(() => {
	// 	if (columns?.[1]?.items) {
	// 		if (columns?.[1]?.items[0]?._id != startState) {
	// 			// handleStartChange(columns?.[1]?.items[0]?._id);
	// 		}
	// 	} else {
	// 		console.log("set here", columns?.[1]?.items[0]?._id, startState);
	// 	}
	// }, [columns]);

	useEffect(() => {
		if (startState && startState != template?.startState) {
			if (handleCheck(startState)) {
				//its both final and start
				let temp = finalStates.filter((ele) => ele != startState);
				setFinalStates([...temp]);

				setTemplate((prev) => {
					return {
						...prev,
						startState,
						finalStates: [...temp],
					};
				});
			} else {
				setTemplate((prev) => {
					return {
						...prev,
						startState,
					};
				});
			}
			updateTemplateInBackend();
		}
	}, [startState, finalStates]);
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Kanban
						columns={columns}
						setColumns={setColumns}
						setFinalId={setFinalId}
						finalStates={finalStates}
						setIsFinal={setIsFinal}
						startState={startState}
						isFinal={isFinal}
						dragDirection="vertical"
						onDragEnd={onDragEnd}
						columnStyles={{
							backgroundColor: "white",
							flex: "1 0 100%",
						}}
						itemStyles={{
							display: "flex",
							flexDirection: "column",
						}}
					/>
				</Grid>
				<Grid item md={6} xs={12}>
					<IssueGraph
						initialNodes={initialNodes}
						initialEdges={initialEdges}
						stopPopper
					/>
				</Grid>
			</Grid>
		</>
	);
}

{
	/* <List
	className={classes.root}
	sx={{
		"& .MuiListItem-secondaryAction": {
			padding: 0,
		},
	}}
>
	<Typography className={classes.text}>Select States</Typography>


</List>; */
}
