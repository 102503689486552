import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {
  createOfferingAndRelation,
  createOfferingRelation,
  getIncomeChartAccounts,
  getOfferingByName,
  createNewOffering
} from "./utils";
import { useDebounce } from "react-use";
import MyAutocomplete from "../../styled/CommonComponents/MyAutoComplete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CreateChartAccountForBill from "./createChartAccountForBill";
import CreateChartAccountForInvoice from "./createChartAccountForInvoice";
import InputAdornment from "@material-ui/core/InputAdornment";
import LessText from "../../styled/CommonComponents/LessText";

const typeArr = ["Product", "Service", "Package"];
const subTypeArr = [
  "Material",
  "Material + Labour",
  "Labour",
  "Services",
  "Subcontract",
];

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  inputCont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  normalInput: {
    width: "47%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  priceRate: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btnCont: {
    paddingTop: "20px",
    paddingBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
  col: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  optionToBar: {
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
    justifyContent: "space-between",
  },
  codeUnitCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  halfText: {
    width: "47%",
    marginTop: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

export default function CreateOffering(props) {
  const { text, setOpen, onSelect, setLoading, tx, orgId } = props;
  const { walletId } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const { user, userProfile } = auth;

  const [chartAccounts, setChartAccounts] = useState([]);
  const [selectedChartAccount, setSelectedChartAccount] = useState({});
  const [chartAccountText, setChartAccountText] = useState("");
  const [openChartAcc, setOpenChartAcc] = useState(false);
  const [taxRate, setTaxRate] = useState(0);
  const [options, setOptions] = useState([]);
  const [name, setName] = useState(text);
  const [price, setPrice] = useState(0);
  const [code, setCode] = useState("");
  const [unit, setUnit] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [type, setType] = useState(typeArr[0]);
  const [typeText, setTypeText] = useState(typeArr[0]);
  const [description, setDescription] = useState("");
  const [offeringObj, setOfferingObj] = useState(null);
  const [subType, setSubType] = useState(subTypeArr[0]);
  const [subTypeText, setSubTypeText] = useState(subTypeArr[0]);

  useEffect(() => {
    switch (subType) {
      case "Material":
        setType(typeArr[0]);
        break;
      case "Material + Labour":
        setType(typeArr[1]);
        break;
      case "Labour":
        setType(typeArr[2]);
        break;
      case "Services":
        setType(typeArr[2]);
        break;
      case "Subcontract":
        setType(typeArr[2]);
        break;
    }
  }, [subType]);

  useEffect(() => {
    let ChartAcctype;
    if (tx?.type == "Invoice") {
      ChartAcctype = { classification: "Income", wallet: walletId };
    } else if (tx?.type == "Bill") {
      ChartAcctype = { topLevel: "Expense", wallet: walletId };
    }

    getIncomeChartAccounts({ type: ChartAcctype })
      .then((accounts) => {
        const addObject = {
          _id: "New",
          name: "+ Add New",
          numDays: "0",
        };
        const newFiltered = [addObject, ...accounts];
        setChartAccounts(newFiltered);

        if (tx?.type == "Invoice") {
          let curChartAcc = null;
          accounts.map((account) => {
            if (account?.name === "Default Income Acc") {
              curChartAcc = account;
            }
          });

          if (curChartAcc) {
            setSelectedChartAccount(curChartAcc);
          } else {
            setSelectedChartAccount(accounts[0]);
          }
        } else if (tx?.type == "Bill") {
          if (type === typeArr[0]) {
            let curChartAcc = null;

            accounts.map((account) => {
              if (account?.name === "Material") {
                curChartAcc = account;
              }
            });

            if (curChartAcc) {
              setSelectedChartAccount(curChartAcc);
            } else {
              setSelectedChartAccount(accounts[0]);
            }
          } else {
            setSelectedChartAccount(accounts[0]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useDebounce(
    () => {
      if (chartAccounts && chartAccounts.length > 0 && tx?.type == "Invoice") {
        let curChartAcc = null;
        chartAccounts.map((account) => {
          if (account?.name === "Default Income Acc") {
            curChartAcc = account;
          }
        });

        if (curChartAcc) {
          setSelectedChartAccount(curChartAcc);
        } else {
          setSelectedChartAccount(chartAccounts[0]);
        }
      } else if (
        chartAccounts &&
        chartAccounts.length > 0 &&
        tx?.type == "Bill"
      ) {
        if (type === typeArr[0]) {
          let curChartAcc = null;

          chartAccounts.map((account) => {
            if (account?.name === "Material") {
              curChartAcc = account;
            }
          });

          if (curChartAcc) {
            setSelectedChartAccount(curChartAcc);
          } else {
            setSelectedChartAccount(chartAccounts[0]);
          }
        } else {
          setSelectedChartAccount(chartAccounts[0]);
        }
      }
    },
    1000,
    [type]
  );

  // need to modify create function
  const saveAndClose = async (e) => {
    e.preventDefault();
    setOpen(false);
    setLoading(true);

    const newOffering = {
      name: name,
      description: description,
      model: type,
      price: price,
      wallet: walletId,
      addedBy: user?.profile,
      user: user?.model == "User" ? user._id : userProfile?._id,
      organization: orgId,
      category: category,
      subCategory: subCategory,
    };

    await createNewOffering(newOffering)
      .then((savedData) => {
        let orgData = savedData;
        // orgData.chartAccount = selectedChartAccount;
        orgData.offering = savedData?.savedOffering;

        setLoading(false);
        if (onSelect) {
          onSelect(orgData, true);
        }
      })
      .catch((err) => {
        console.log(err);
      });

  };

  const chartAccountCreateDialog = async (value) => {
    setOpenChartAcc(value);

    let ChartAcctype;
    if (tx?.type == "Invoice") {
      ChartAcctype = { classification: "Income", wallet: walletId };
    } else if (tx?.type == "Bill") {
      ChartAcctype = { topLevel: "Expense", wallet: walletId };
    }

    await getIncomeChartAccounts({ type: ChartAcctype })
      .then((accounts) => {
        const addObject = {
          _id: "New",
          name: "+ Add New",
          numDays: "0",
        };
        const newFiltered = [addObject, ...accounts];
        const len = accounts?.length;
        setChartAccounts(newFiltered);
        setSelectedChartAccount(accounts[len - 1]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeName = async () => {
    if (name && name.length > 1) {
      await getOfferingByName({
        name: name,
      })
        .then((discountsOrTaxs) => {
          setOptions(discountsOrTaxs);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useDebounce(
    () => {
      onChangeName();
    },
    1000,
    [name]
  );

  const onNameSelected = async (value) => {
    if (value === null) {
      setName("");
      setOptions([]);
      setType("");
      setPrice(0);
      setDescription("");
    } else {
      setName(value?.name);
      setType(value?.model);
      setPrice(value?.price);
      setDescription(value?.description);
    }
    setOfferingObj(value);
  };

  let CreateChartAccountComponent = null;
  switch (tx?.type) {
    case "Bill":
      CreateChartAccountComponent = (
        <CreateChartAccountForBill
          walletId={walletId}
          openDialog={openChartAcc}
          setOpenChart={chartAccountCreateDialog}
        />
      );
      break;
    case "Invoice":
      CreateChartAccountComponent = (
        <CreateChartAccountForInvoice
          walletId={walletId}
          openDialog={openChartAcc}
          setOpenChart={chartAccountCreateDialog}
        />
      );
      break;
    default:
      break;
  }

  return (
    <form className={classes.root} onSubmit={saveAndClose}>
      <div className={classes.inputCont}>
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          value={offeringObj}
          inputValue={name}
          options={options}
          getOptionLabel={(option) => {
            return option?.name || " ";
          }}
          getOptionSelected={(option) => {
            return option?._id == offeringObj?._id;
          }}
          onChange={(event, value) => {
            onNameSelected(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Title"
              margin="normal"
              variant="outlined"
            />
          )}
          onInputChange={(event, newValue) => {
            setName(newValue);
          }}
          renderOption={(option, state) => {
            if (option) {
              return (
                <div style={{ width: "100%" }}>
                  <div className={classes.optionToBar}>
                    <div>
                      {option?.name}
                      <span style={{ marginLeft: "7px", marginRight: "7px" }}>
                        |
                      </span>
                      <span style={{ fontSize: "14px", opacity: "0.6" }}>
                        {option?.model}
                      </span>
                    </div>
                    <div>{option?.price}₹</div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      fontSize: "13px",
                      opacity: "0.6",
                      textAlign: "center",
                    }}
                  >
                    <LessText limit={150} string={option?.description} />
                  </div>
                </div>
              );
            } else {
              return null;
            }
          }}
          style={{ width: "100%" }}
          size="small"
        />
      </div>

      <div className={classes.codeUnitCont}>
        <TextField
          id="outlined-start-adornment"
          label="Category"
          value={category}
          onChange={(e) => {
            setCategory(e.target.value);
          }}
          variant="outlined"
          className={classes.halfText}
          size="small"
        />

        <TextField
          id="outlined-start-adornment"
          label="Sub Category"
          value={subCategory}
          onChange={(e) => {
            setSubCategory(e.target.value);
          }}
          variant="outlined"
          className={classes.halfText}
          size="small"
        />
      </div>

      <div className={classes.codeUnitCont}>
        <TextField
          id="outlined-start-adornment"
          label="Code"
          value={code}
          onChange={(e) => {
            setCode(e.target.value);
          }}
          variant="outlined"
          className={classes.halfText}
          size="small"
        />

        <TextField
          id="outlined-start-adornment"
          label="Unit"
          value={unit}
          onChange={(e) => {
            setUnit(e.target.value);
          }}
          variant="outlined"
          className={classes.halfText}
          size="small"
        />
      </div>

      <div className={classes.inputCont}>
        <div className={classes.normalInput}>
          <TextField
            id="outlined-multiline-static"
            label="Product Description"
            multiline
            rows={4}
            variant="outlined"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            style={{ marginTop: "20px", width: "100%" }}
          />
        </div>

        <div className={classes.normalInput}>
          <div style={{ marginTop: "20px", marginLeft: "-21px" }}>
            <MyAutocomplete
              value={subType}
              isSmall={false}
              text={subTypeText}
              setText={setSubTypeText}
              placeholder={"Resource Type"}
              results={subTypeArr}
              getOptionLabel={(option) => {
                return option || " ";
              }}
              onSelect={(value) => {
                setSubType(value);
              }}
              label={"Resource Type"}
              setWidth={"94%"}
            />

            {/* <MyAutocomplete
              value={type}
              isSmall={false}
              text={typeText}
              setText={setTypeText}
              placeholder={"Type"}
              results={typeArr}
              getOptionLabel={(option) => {
                return option || " ";
              }}
              onSelect={(value) => {
                setType(value);
              }}
              label={"Type"}
              setWidth={"94%"}
            /> */}
          </div>

          <div style={{ marginTop: "20px", marginLeft: "-21px" }}>
            <MyAutocomplete
              value={selectedChartAccount}
              isSmall={false}
              text={chartAccountText}
              setText={setChartAccountText}
              placeholder={"Chart Accounts"}
              results={chartAccounts}
              getOptionLabel={(option) => {
                return option?.name || " ";
              }}
              onSelect={(value) => {
                setSelectedChartAccount(value);
              }}
              onNew={() => {
                setOpenChartAcc(true);
              }}
              label={"Chart Accounts"}
              setWidth={"94%"}
            />
          </div>
        </div>
      </div>

      <div className={classes.priceRate}>
        <TextField
          id="outlined-start-adornment"
          type="number"
          label="Price"
          value={price}
          onChange={(e) => {
            setPrice(e.target.value);
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">₹</InputAdornment>,
          }}
          variant="outlined"
          style={{ width: "48%" }}
          size="small"
        />

        <TextField
          id="outlined-start-adornment"
          type="number"
          label="Tax Rate"
          value={taxRate}
          onChange={(e) => {
            setTaxRate(e.target.value);
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          variant="outlined"
          style={{ width: "48%" }}
          size="small"
        />
      </div>

      <div className={classes.btnCont}>
        <div></div>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={
            code
              ? offeringObj === null
                ? name && price && selectedChartAccount?._id
                  ? false
                  : true
                : false
              : true
          }
        >
          Save and Close
        </Button>
      </div>
      {CreateChartAccountComponent}
    </form>
  );
}
