import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";

import Api from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import arrayToReducer from "../../../helpers/arrayToReducer";
import {
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import { useDebounce, useLocation } from "react-use";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CreateTaxModal({
  open,
  setOpen,
  editableTax,
  setEditableTax,
  name,
  callback,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const [taxName, setTaxName] = useState("");
  const [taxDesc, setTaxDesc] = useState("");
  const [taxLabel, setTaxLabel] = useState("");
  const [taxPerc, setTaxPerc] = useState("");

  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const url = location.pathname;
  let segments = url.split("/");
  let orgId = segments[segments.length - 1];

  const handleCallback = async () => {
    if (editableTax && editableTax._id) {
      await updateTax();
    } else {
      await createTax();
    }
  };

  const createTax = async () => {
    try {
      const { data } = await Api.post("/procurement/ops/taxes/create", {
        name: taxName,
        description: taxDesc,
        invoiceLabel: taxLabel,
        percent: taxPerc,
        user: user._id,
        organization: orgId,
      });
      if (data) {
        callback(data);
      }
      setOpen(false);
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create tax",
        },
      });
    }
  };

  const updateTax = async () => {
    try {
      const { data } = await Api.post("/procurement/ops/taxes/update", {
        taxId: editableTax._id,
        updatedObj: {
          name: taxName,
          description: taxDesc,
          invoiceLabel: taxLabel,
          percent: taxPerc,
        },
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Tax updated successfully",
          },
        });
        callback(data);
        setOpen(false);
        setEditableTax({});
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to create tax",
        },
      });
    }
  };

  useEffect(() => {
    if (editableTax && editableTax._id) {
      setTaxName(editableTax.name || "");
      setTaxDesc(editableTax.description || "");
      setTaxLabel(editableTax.invoiceLabel || "");
      setTaxPerc(editableTax.percent || "");
    } else if (name && name.length > 0) {
      setTaxName(name);
    } else {
      // NO-OP
    }
  }, [editableTax,name]);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Create Tax
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <TextField
            fullWidth
            placeholder="Tax Name"
            variant="outlined"
            value={taxName}
            onChange={(e) => setTaxName(e.target.value)}
          />

          <TextField
            fullWidth
            placeholder="Description"
            variant="outlined"
            style={{ marginTop: "10px" }}
            value={taxDesc}
            onChange={(e) => setTaxDesc(e.target.value)}
          />

          <TextField
            fullWidth
            placeholder="Invoice Label"
            variant="outlined"
            style={{ marginTop: "10px" }}
            value={taxLabel}
            onChange={(e) => setTaxLabel(e.target.value)}
          />

          <TextField
            fullWidth
            placeholder="% Value"
            variant="outlined"
            style={{ marginTop: "10px" }}
            value={taxPerc}
            onChange={(e) => setTaxPerc(e.target.value)}
            type="number"
          />
        </DialogContent>
        <DialogActions>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button
              autoFocus
              color="primary"
              variant="contained"
              disabled={
                taxName.length <= 0 ||
                taxDesc.length <= 0 ||
                taxLabel.length <= 0 ||
                isNaN(parseFloat(taxPerc))
              }
              onClick={() => handleCallback()}
            >
              {editableTax && editableTax._id ? "Update" : "Create"}
            </Button>
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
