import { Menu, MenuItem, Popover, Select } from "@material-ui/core";
import React from "react";

const DropdownSelect = ({ open, setOpen, anchorEl, options, setValue }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (item) => {
    setValue(item);
    setOpen(false);
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {options.map((item, i) => (
        <MenuItem key={i} onClick={() => handleSave(item.optionText)}>{item.optionText}</MenuItem>
      ))}
    </Menu>
  );
};

export default DropdownSelect;
