import React, { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import Choose from "../select/choose";
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import { TextField, Button, Avatar, IconButton } from "@material-ui/core";

export default function ContactResponse(props) {
    const { type, viewMode, questionId, formRes, updateApi } = props;

    const [openChoose, setOpenChoose] = useState(false);
    const [value, setValue] = useState(formRes[0]?.contact);


    useEffect(() => {
        setValue(formRes[0]?.contact)
    }, [formRes, formRes[0]?.contact])


    useDebounce(
        () => {
            if (questionId) {
                let formResId = formRes[0]?._id;
                const obj = {
                    formResId,
                    updateObj: {
                        contact: value?._id,
                    },
                };
                if (!viewMode) updateApi(obj);
            }
        },
        500,
        [value]
    );

    return (
        <div style={{ width: "100%" }}>
            <Choose
                open={openChoose}
                setOpen={setOpenChoose}
                parentModelName={type}
                multiple={false}
                disableModelChange={true}
                placeHolder={`Find ${type}`}
                onSelected={(arr) => {
                    console.log(arr, " is the array");
                    if (arr && arr.length > 0) {
                        setValue(arr[0])
                    }
                }}
            />

            {value ? (<div style={{ display: "flex", alignItems: "center" }} >
                <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }} >
                    <Avatar
                        src={value?.profile?.parent?.displayPicture?.url}
                        alt={value?.profile?.parent?.displayName}
                    />
                    <div style={{ marginLeft: "5px" }} >
                        <h3 style={{ fontSize: "16px", fontWeight: "510" }} >{value?.profile?.parent?.displayName}</h3>
                        <p style={{ fontSize: "12px", fontWeight: "450" }} >{`Added at ${moment(formRes[0]?.updatedAt?.nextDate).format('DD MMM YYYY')}`}</p>
                    </div>
                </div>
                <IconButton
                    onClick={() => { setOpenChoose(true) }}
                >
                    <EditIcon />
                </IconButton>
            </div>) :
                (<Button
                    onClick={() => { setOpenChoose(true) }}
                >
                    {`Find ${type}`}
                </Button>)
            }
        </div>
    );
}
