import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Slide,
	ClickAwayListener,
	Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Api from "../../helpers/Api";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ChooseOrg from "./ChooseOrg";
import ChooseProject from "./ChooseProject";
import ChooseTemplate from "./ChooseTemplate";
import ChooseUser from "./ChooseUser";
import NormalDialog from "../styled/CommonComponents/NormalDialog";


const useStyles = makeStyles((theme) => ({
	container: {
		width: "450px",
		display: "flex",
		flexDirection: "column",
	},
	top_panel: {
		margin: "1rem 0px",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		"& p": {
			color: "#193B56",
			fontSize: "16px",
			fontWeight: "510",
		}
	},
	bottom_panel: {
		"& p": {
			color: "#193B56",
			fontSize: "16px",
			fontWeight: "510",
		},
	},
	btnCont: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-end",
		"& .MuiButton-label": {
			textTransform: "capitalize"
		}
	},
	mainCont: {
		width: "550px",
		minHeight: "300px",
		marginBottom: "25px"
	},
	listCont: {
		width: "100%",
		position: "relative",
	},
	optionCont: {
		width: "100%",
		height: "0px",
		position: "absolute",
		zIndex: "999",
		top: "85px",
		left: "0px",
		boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;",
		overflow: "hidden",
		backgroundColor: "white",
		transition: "all .3s ease-in-out"
	},
	openOptionCont: {
		width: "100%",
		height: "228px",
		position: "absolute",
		zIndex: "999",
		top: "85px",
		left: "0px",
		boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;",
		overflow: "hidden",
		backgroundColor: "white",
		transition: "all .3s ease-in-out"
	},
	optionSty: {
		width: "100%",
		height: "38px",
		display: "flex",
		paddingLeft: "20px",
		fontSize: "15px",
		fontWeight: "500",
		alignItems: "center",
		justifyContent: "flex-start",
		backgroundColor: "white",
		cursor: "pointer",
		transition: "all .3s ease-in-out",
		"&:hover": {
			backgroundColor: "#ececec"
		}
	},
	selectedOptionSty: {
		width: "100%",
		height: "38px",
		display: "flex",
		paddingLeft: "20px",
		fontSize: "15px",
		fontWeight: "500",
		alignItems: "center",
		justifyContent: "flex-start",
		backgroundColor: "#ececec",
		cursor: "pointer",
		transition: "all .3s ease-in-out",
		"&:hover": {
			backgroundColor: "#ececec"
		}
	}
}));

const ListOptions = [
	"Contact",
	"Organization",
	"Project",
	"User",
	"Issue",
	"Template",
];

const ListDict = {
	Organization: "Organization",
	Project: "Project",
	User: "User",
	Template: "Template",
	Issue: "Issue",
	Contact: "Contact"
};

function Choose({
	open,
	setOpen,
	parentModelName,
	onParentModelSelect,
	disableModelChange,
	onSelected,
	multiple,
	placeHolder
}) {
	const classes = useStyles();
	const [parentModel, setParentModel] = useState(parentModelName)
	const [projectTeams, setProjectTeams] = useState([]);

	const [adminProfileIds, setAdminProfileIds] = useState([]);
	const [orgTeams, setOrgTeams] = useState([]);
	const [selectedProject, setSelectedProject] = useState(null);
	const { user, userProfile } = useSelector((state) => state.auth);
	const [anchorEl, setAnchorEl] = React.useState(false);

	const [selectedIds, setSelectedIds] = useState([])
	const [selectedDict, setSelectedDict] = useState({})


	const handleClickListItem = (event) => {
		setAnchorEl(!anchorEl);
	};
	const handleMenuItemClick = (option) => {
		setParentModel(option);
		if (onParentModelSelect) {
			onParentModelSelect(option)
		}

		setAnchorEl(false);
	};
	const getBasicData = async () => {
		const res = await Api.post("shared/getBasicData", {
			userProfileId: user?.profile,
		});

		if (res?.data) {
			const data = res?.data;
			const adminProfileIdsRes = res.data.adminProfileIds;
			const orgTeamRes = data.orgTeams;
			const projectTeamRes = data.projectTeams;

			setAdminProfileIds(adminProfileIdsRes);
			setOrgTeams(orgTeamRes);
			setProjectTeams(projectTeamRes);
		}
	};
	const handleClose = () => {
		if (onSelected) {
			if (selectedIds.length > 0) {
				const selected = selectedIds.map(selectedId => selectedDict[selectedId])
				onSelected(selected);
			}
		}
		setOpen(false);
	};
	const switchmodel = (model) => {
		switch (model) {
			case "Organization":
				return (
					<ChooseOrg
						orgTeams={orgTeams}
						multiple={multiple}
						selectedDict={selectedDict}
						setSelectedDict={setSelectedDict}
						setSelectedIds={setSelectedIds}
						selectedIds={selectedIds}
					/>
				);
			case "Project":
				return (
					<ChooseProject
						projectTeams={projectTeams}
						multiple={multiple}
						selectedDict={selectedDict}
						setSelectedDict={setSelectedDict}
						setSelectedIds={setSelectedIds}
						selectedIds={selectedIds}
					/>
				);

			case "User":
				return (
					<ChooseUser
						multiple={multiple}
						selectedDict={selectedDict}
						setSelectedDict={setSelectedDict}
						setSelectedIds={setSelectedIds}
						selectedIds={selectedIds}
					/>
				);

			case "Template":
				return (
					<ChooseTemplate
						parentModelName={parentModel}
						projectTeams={projectTeams}
						orgTeams={orgTeams}
						multiple={multiple}
						selectedDict={selectedDict}
						setSelectedDict={setSelectedDict}
						setSelectedIds={setSelectedIds}
						selectedIds={selectedIds}
					/>
				);

			case "Issue":
				return (
					<ChooseTemplate
						parentModelName={parentModel}
						projectTeams={projectTeams}
						orgTeams={orgTeams}
						multiple={multiple}
						selectedDict={selectedDict}
						setSelectedDict={setSelectedDict}
						setSelectedIds={setSelectedIds}
						selectedIds={selectedIds}
					/>
				);

			case "Contact":
				return (
					<ChooseTemplate
						parentModelName={parentModel}
						projectTeams={projectTeams}
						orgTeams={orgTeams}
						multiple={multiple}
						selectedDict={selectedDict}
						setSelectedDict={setSelectedDict}
						setSelectedIds={setSelectedIds}
						selectedIds={selectedIds}
					/>
				);
			default:
				return null;
		}
	};
	useEffect(() => {
		getBasicData();
	}, []);

	return (
		<NormalDialog
			openDialog={open}
			handleCloseShare={() => { setOpen(false) }}
			pageTitle={placeHolder ? placeHolder : "Select Subject"}
			content={<>
				<div className={classes.mainCont} >
					{disableModelChange ? null : <>
						<ClickAwayListener onClickAway={() => { setAnchorEl(false) }}>
							<div className={classes.listCont} >
								<List component="nav">
									<ListItem
										button
										aria-haspopup="true"
										onClick={handleClickListItem}
									>
										<ListItemText
											primary="Selected Group"
											secondary={ListDict[parentModel]}
										/>
										<ListItemIcon
											style={{ justifyContent: "center" }}
										>
											<ArrowDropDownIcon />
										</ListItemIcon>
									</ListItem>
								</List>
								<div className={anchorEl ? classes.openOptionCont : classes.optionCont} >
									{ListOptions.map((option, index) => (
										<div
											key={index}
											className={option === parentModel ? classes.selectedOptionSty : classes.optionSty}
											onClick={() => handleMenuItemClick(option)}
										>
											<p>{ListDict[option]}</p>
										</div>
									))}
								</div>
							</div>
						</ClickAwayListener>

						<Divider />
					</>}
					{switchmodel(parentModel)}
				</div>

				<div className={classes.btnCont} >
					<Button
						variant="contained"
						color="primary"
						size="small"
						onClick={handleClose}
					>
						{selectedIds.length > 0 ? "Done" : "Cancel"}
					</Button>
				</div>
			</>}
		/>
	);
}

export default Choose;