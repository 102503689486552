import React, { useEffect, useState } from "react";
import "../../App.css";
// import {
//   KanbanComponent,
//   ColumnsDirective,
//   ColumnDirective,
// } from "@syncfusion/ej2-react-kanban";
import Api from "../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Box,
  Grid,
  MenuItem,
  Pagination,
} from "@mui/material";
import HorizBox from "../styled/generic/HorizBox";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import Select from "../styled/generic/Select";
import LoadingHandler from "../styled/generic/LoadingHandler";
import CRMCard from "./issue.crm.card";
import TextField from "../styled/generic/TextField";
import { useDebounce } from "react-use";
import UserPicker from "../styled/generic/UserPicker";

const KanbanView = ({ template }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [issues, setIssues] = useState([]);
  const [createdAtFilter, setCreatedAtFilter] = useState("ascending");
  const [statusFilters, setStatusFilters] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [searchOption, setSearchOption] = useState("Ticket Title");
  const SEARCH_OPTIONS = ["Ticket Title", "Listing Title", "Requirement Title"];
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [listingFilters, setListingFilters] = useState([]);
  const [listingOptions, setListingOptions] = useState([]);

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (template) {
      setStatusOptions(template.pipeline);
      setStatusFilters(template.pipeline.map((status) => status?._id));
    }
  }, [template]);

  const getListings = async () => {
    try {
      const res = await Api.post("/listing/getListingsBySearchAry", {
        template: template?._id,
      });
      if (res?.data) {
        setListingOptions(res?.data);
        setListingFilters(res?.data.map((listing) => listing?._id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (template?._id) {
      getListings();
    }
  }, [template]);

  const getIssues = async () => {
    try {
      setLoading(true);
      const { data } = await Api.post(`/issue/getPaginatedIssues`, {
        templateId: template?._id,
        pageNumber: pageNumber,
        limit: 9,
        createdAtFilter: createdAtFilter,
        statusFilters: statusFilters?.length === 0 ? null : statusFilters,
        searchStr: searchStr,
        searchOption: searchOption,
        assignedUserProfileIds: selectedUsers.map((user) => user?.profile?._id),
      });
      if (data) {
        setIssues(data.issues);
        setTotalPages(data.totalPages);
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getIssues();
  }, [pageNumber, createdAtFilter, statusFilters, selectedUsers]);

  useDebounce(
    () => {
      getIssues();
    },
    500,
    [searchStr, searchOption]
  );

  const { user } = useSelector((state) => state.auth);

  return (
    <Box>
      <Box>
        <StandardAppContainerRounded>
          <HorizBox>
            <TextField
              value={searchStr}
              onChange={(e) => setSearchStr(e.target.value)}
              size="small"
              fullWidth={true}
              placeholder="Search for a ticket..."
              sx={{
                width: "350px",
              }}
            />
            <Select
              value={statusFilters}
              onChange={(e) => setStatusFilters(e.target.value)}
              size="small"
              multiple={true}
              sx={{ width: "200px" }}
            >
              {statusOptions.map((status) => {
                return <MenuItem value={status?._id}>{status?.text}</MenuItem>;
              })}
            </Select>
            <UserPicker
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              size="small"
              textFieldSx={{ width: "200px" }}
            />
            <Select
              value={listingFilters}
              onChange={(e) => setListingFilters(e.target.value)}
              size="small"
              sx={{ width: "200px" }}
              multiple={true}
            >
              {listingOptions?.map((status) => {
                return <MenuItem value={status?._id}>{status?.title}</MenuItem>;
              })}
            </Select>
          </HorizBox>
        </StandardAppContainerRounded>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StandardAppContainerRounded fullHeight={true}>
            <LoadingHandler loading={loading} fullHeight={true}>
              <Grid container spacing={2}>
                {issues.map((issue) => {
                  return (
                    <CRMCard
                      issue={issue}
                      statusOptions={statusOptions}
                      onIssueUpdate={(newIssue) => {
                        const newIssues = issues.map((oldIssue) => {
                          if (oldIssue._id === newIssue._id) {
                            return newIssue;
                          }
                          return oldIssue;
                        });
                        setIssues(newIssues);
                      }}
                      onIssueDelete={(deletedIssueId) => {
                        const newIssues = issues.filter(
                          (oldIssue) =>
                            String(oldIssue._id) !== String(deletedIssueId)
                        );
                        setIssues(newIssues);
                      }}
                    />
                  );
                })}
              </Grid>
              <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                <Pagination
                  count={totalPages}
                  page={pageNumber}
                  onChange={(e, page) => setPageNumber(page)}
                  color="primary"
                  variant="outlined"
                  shape="rounded"
                />
              </Box>
            </LoadingHandler>
          </StandardAppContainerRounded>
        </Grid>
      </Grid>
      {/* <IssueEditDrawer
      open={showIssueEditDrawer}
      setOpen={setShowIssueEditDrawer}
      issue={selectedIssue}
    /> */}
    </Box>
  );
};

export default KanbanView;
