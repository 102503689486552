import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PieAnalytics from "./PieAnalytics";
import BarAnalytics from "./BarAnalytics";
import ScatterAnalytics from "./ScatterAnalytics";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "2.5px solid #f5f5f5",
    borderRadius: "5px",
    height: "400px",
    width: "400px",
    padding: "10px",
    marginRight: "1rem",
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "&:hover": {
      transform: "scale(1.03)",
      transition: "all 0.3s ease-in-out",
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    "& p": {
      color: "grey",
    },
    "& h3": {
      fontWeight: "400",
    },
  },
}));

function AnalyticsCard(props) {
  const classes = useStyles();
  const { root, header } = classes;
  const { graphData } = props;

  return (
    <div className={root}>
      <div className={header}>
        Bar Graph
        {/* <h3>{graphData?.graphType} Analytics ({graphData?.graphType} Graph)</h3> */}
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={graphData.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="pv" fill="#8884d8" />
          <Bar dataKey="uv" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
      {/* {(() => {
        switch (graphData?.graphType) {
          case "Pie":
            return <PieAnalytics data={graphData} />;
          case "Bar":
            return <BarAnalytics data={graphData} />;
          case "Scatter":
            return <ScatterAnalytics data={graphData} />;
        }
      })()} */}
    </div>
  );
}

export default AnalyticsCard;
