import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import Api from "../../../helpers/Api";
import _ from "lodash";
import arrayToReducer from "../../../helpers/arrayToReducer";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "../../styled/agGrid.css";
import PopupCellRenderer from "../../styled/PopupCellRenderer";
import DropdownSelect from "./components/DropdownSelect";
import DatePickerPopup from "./components/DatePickerPopup";
import moment from "moment";
import TimePickerPopup from "./components/TimePickerPopup";
import Choose from "../../select/choose";
import CheckboxCell from "./cells/CheckboxCell";

const useStyles = makeStyles((theme) => ({
  grid: {
    marginLeft: "auto",
    marginRight: "auto",
    fontFamily: "Roboto, sans-serif",
    backgroundColor: "#f0f0f0",
    fontSize: "0.95rem",
    fontWeight: "normal",
    border: "1px solid #ccc",
  },
}));

const FormTable = ({
  viewMode,
  issue,
  updateApi,
  question,
  questionId,
  formRes,
  setformResDict,
  formResDict,
  qMap,
  tMap,
  setFormTMap,
  table,
  tableRes,
  setTableRes,
}) => {
  const classes = useStyles();

  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [columnMap, setColumnMap] = useState({});
  const [colIdxMap, setColIdxMap] = useState({});
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [qType, setQType] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [openProjectChoose, setOpenProjectChoose] = useState(false);
  const [openOrganizationChoose, setOpenOrganizationChoose] = useState(false);
  const [openUserChoose, setOpenUserChoose] = useState(false);

  const gridApiRef = useRef(null);

  const getQuestions = () => {
    let allQuestions = table?.questions || [];
    let columnArr = [],
      cMap = {},
      cIdxMap = {};
    allQuestions.map((q, index) => {
      let id = q._id;

      let obj = {
        headerName: q?.questionText || "Untitled",
        field: q?._id,
        editable: true,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        sortable: true,
        resizable: false,
        filter: true,
        flex: 1,
        minWidth: 150,
        question: q,
      };

      if (
        q?.type == "Dropdown" ||
        q?.type == "Date" ||
        q?.type == "Time" ||
        q?.type == "Project" ||
        q?.type == "Organization" ||
        q?.type == "User" ||
        q?.type == "Boolean"
      ) {
        obj.editable = false;
      }

      if (q?.type === "Boolean") {
        obj.cellRenderer = CheckboxCell;
        obj.cellRendererParams = {
          setValue: setValue,
        };
      }

      cMap[q._id] = q;
      cIdxMap[q._id] = index;

      columnArr.push(obj);
    });
    setColumnDefs(columnArr);
    setColumnMap(cMap);
    setColIdxMap(cIdxMap);
  };

  const getVal = (currResponse, thisType) => {

    switch (thisType) {
      case "Short Answer":
      case "Long Answer":
        return currResponse?.text;
      case "Date":
        if (moment(currResponse?.date).isValid()) {
          return moment(currResponse?.date).format("DD/MM/YY");
        } else {
          return "null";
        }

      case "Time":
        if (moment(currResponse?.date).isValid()) {
          return moment(currResponse?.time).format("hh:mm A");
        } else {
          return "null";
        }
      case "Number":
        return currResponse?.numeric;
      case "Dropdown":
        return currResponse?.text;
      case "Project":
      case "Organization":
      case "User":
        // return {
        //   avatar: currResponse?.parent?.displayPicture?.url,
        //   displayName: currResponse?.parent?.displayName,
        // };
        return currResponse?.parent?.displayName;
      case "Issue":
      case "Template":
        return currResponse?.parent?.title;
      case "Boolean":
        return currResponse?.boolean;
    }
  };

  //rows
  const getResponseRows = () => {
    let allQuestions = table?.questions || [];
    let responseRows = tableRes?.rows || [];
    let rowArr = [];
    responseRows.map((currRow) => {
      //this row has responses
      let rowObj = {};
      currRow.responses.map((currResponse, index) => {
        let thisType = allQuestions[index]?.type || "Short Answer";
        let thisVal = getVal(currResponse, thisType);
        rowObj[currResponse.question] = thisVal;
      });
      rowArr.push(rowObj);
    });
    setRowData(rowArr);
  };

  useEffect(() => {
    getQuestions();
    getResponseRows();
  }, [issue?.tableResponses, tableRes?.rows]);

  const onAddClick = async (api, node) => {
    setLoading(true);
    let rowIndex = node ? node.rowIndex : tableRes ? tableRes?.rows?.length : 0;
    let addIndex = rowIndex + 1;
    let obj = {
      issue: issue._id,
      index: addIndex,
      formId: question?.form,
      questionsId: question?._id,
      tableId: table?._id,
      tableResponsesId: tableRes?._id,
      questions: table?.questions,
      tags: [
        issue && { data: issue._id, dataModel: "Issue" },
        issue &&
          issue.project && { data: issue?.project, dataModel: "Project" },
      ],
    };
    const res = await Api.post("formtable/responses/addRow", obj);
    //get the rowId added
    if (res?.data) {
      setTableRes(res.data);
    }
    setLoading(false);
  };

  const onDeleteClick = async (api, node) => {
    //delete this row
    setLoading(true);
    let rowIndex = node.rowIndex;
    let row = tableRes?.rows[rowIndex];
    let obj = {
      row: row,
      tableResponsesId: tableRes?._id,
    };
    const res = await Api.post("formtable/responses/deleteRow", obj);
    if (res?.data) {
      setTableRes(res.data);
    }
    setLoading(false);
  };

  const handleRowCreation = async () => {
    await onAddClick();
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);

  const [showDate, setShowDate] = useState(false);
  const [dateAnchorEl, setDateAnchorEl] = useState(null);

  const [showTime, setShowTime] = useState(false);
  const [timeAnchorEl, setTimeAnchorEl] = useState(null);

  const handleDropdown = (anchor) => {
    setDropdownAnchorEl(anchor);
    setShowDropdown(true);
  };

  const handleDate = (anchor) => {
    setDateAnchorEl(anchor);
    setShowDate(true);
  };

  const handleTime = (anchor) => {
    setTimeAnchorEl(anchor);
    setShowTime(true);
  };

  const updateResponse = async (obj) => {
    setLoading(true);
    let oldRes = tableRes;
    const res = await Api.post("formtable/responses/updateResponse", obj);
    if (res?.data) {

      setTableRes(res.data);
    }
    setLoading(false);
  };

  // Value of cell
  const [value, setValue] = useState();

  const update = () => {
    let obj = {};
    if (
      qType == "Project" ||
      qType == "Organization" ||
      qType == "User" ||
      qType == "Issue" ||
      qType == "Template"
    ) {
      obj = {
        tableResponsesId: tableRes?._id,
        responseId: response?._id,
        updateObj: {
          parent: value,
          parentModelName: qType,
        },
      };
    } else {
      let field = "text";
      if (qType == "Date") {
        field = "date";
      } else if (qType == "Time") {
        field = "time";
      } else if (qType == "Number") {
        field = "numeric";
      } else if (qType == "Boolean") {
        field = "boolean";
      }
      obj = {
        tableResponsesId: tableRes?._id,
        responseId: response?._id,
        updateObj: {
          [field]: value,
        },
      };
    }
    if (obj) updateResponse(obj);
  };

  useEffect(() => {
    update();
  }, [value]);

  function handleCellClicked(params) {
    let rowIndex = params.rowIndex;
    let colId = params.column.colId;
    let question = columnMap[colId];
    let row = tableRes?.rows[rowIndex];
    let colIdx = colIdxMap[colId];
    let resp = row?.responses[colIdx];
    let type = question?.type;
    setQType(type);
    setResponse(resp);

    if (type === "Dropdown") {
      handleDropdown(params.event.target);
    }

    if (type === "Date") {
      handleDate(params.event.target);
    }

    if (type === "Time") {
      handleTime(params.event.target);
    }

    if (type == "Project") {
      setOpenProjectChoose(true);
    }

    if (type == "Organization") {
      setOpenOrganizationChoose(true);
    }

    if (type == "User") {
      setOpenUserChoose(true);
    }
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ margin: "10px" }}
      >
        <Box display="flex" alignItems="center">
          {loading ? (
            <>
              <CircularProgress size="1rem" style={{ color: "#000000" }} />
              <span style={{ fontSize: "0.9rem", marginLeft: "10px" }}>
                Saving your changes
              </span>
            </>
          ) : (
            <span style={{ fontSize: "0.9rem", marginLeft: "10px" }}>
              Changes saved
            </span>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleRowCreation()}
        >
          Insert New Row +
        </Button>
      </Box>
      <div
        className={`ag-theme-alpine ${classes.grid}`}
        style={{ height: "400px", borderRadius: "5px" }}
      >
        <AgGridReact
          columnDefs={
            viewMode
              ? columnDefs
              : [
                  {
                    headerName: "Action",
                    cellRenderer: PopupCellRenderer,
                    cellRendererParams: {
                      onAddClick: onAddClick,
                      onDeleteClick: onDeleteClick,
                    },
                    field: "menu",
                    pinned: "left",
                    editable: false,
                    maxWidth: 100,
                    // cellStyle: { paddingBottom: "15px" },
                  },
                  ...columnDefs,
                ]
          }
          rowData={rowData}
          singleClickEdit={true}
          // frameworkComponents={frameworkComponents}
          // getRowStyle={getRowStyle}
          // onRowMouseEnter={onRowMouseEnter}
          // onRowMouseLeave={onRowMouseLeave}
          onCellClicked={handleCellClicked}
          // onGridReady={onGridReady}
          // pagination={true}
          // pivotPanelShow={"always"}
          // paginationAutoPageSize={true}
          // suppressClickEdit={true}
          onCellEditingStarted={(params) => {
            console.log("Cell editing started", params);
          }}
          onCellEditingStopped={(params) => {
            setValue(params.newValue);
          }}
          stopEditingWhenCellsLoseFocus={true}
        />
      </div>
      <DropdownSelect
        open={showDropdown}
        setOpen={setShowDropdown}
        anchorEl={dropdownAnchorEl}
        options={table?.questions[0]?.options || []}
        value={value}
        setValue={setValue}
      />
      <DatePickerPopup
        open={showDate}
        setOpen={setShowDate}
        anchorEl={dateAnchorEl}
        value={value}
        setValue={setValue}
      />
      <TimePickerPopup
        open={showTime}
        setOpen={setShowTime}
        anchorEl={timeAnchorEl}
        value={value}
        setValue={setValue}
      />
      <Choose
        open={openProjectChoose}
        setOpen={setOpenProjectChoose}
        parentModelName="Project"
        multiple={false}
        disableModelChange={true}
        placeHolder={`Choose Project`}
        onSelected={(arr) => {
          setValue(arr[0]?._id);
        }}
      />
      <Choose
        open={openOrganizationChoose}
        setOpen={setOpenOrganizationChoose}
        parentModelName="Organization"
        multiple={false}
        disableModelChange={true}
        placeHolder={`Choose Organization`}
        onSelected={(arr) => {
          setValue(arr[0]?._id);
        }}
      />

      <Choose
        open={openUserChoose}
        setOpen={setOpenUserChoose}
        parentModelName="User"
        multiple={false}
        disableModelChange={true}
        placeHolder={`Choose User`}
        onSelected={(arr) => {
          setValue(arr[0]?._id);
        }}
      />
    </>
  );
};

export default FormTable;
