import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  makeStyles,
} from "@material-ui/core";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ListItemButton } from "@mui/material";
import Api from "../../helpers/Api";
import { useDispatch } from "react-redux";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    textAlign: "left",
  },
  tab: {
    textTransform: "lowercase", // Convert text to lowercase
    justifyContent: "flex-start", // Align text to the left
    textAlign: "left",
    padding: "2px", // Adjust tab padding=
  },
  customListItem: {
    margin: "0px", // Set margin as needed
    padding: "0px",
    width: "200px", // Set width as needed
  },
}));

const DataList = ({ ary, setAry }) => {
  const handleChange = (isChecked, index) => {
    let newAry = [...ary];
    newAry[index].isSelected = isChecked;
    setAry(newAry);
  };

  return (
    <Box style={{ width: "100%", paddingLeft: "15px" }}>
      {ary && ary.length > 1 ? (
        <List>
          {ary.map((item, index) => (
            <ListItem
              key={item}
              style={{
                padding: "0px",
                width: "100%",
              }}
            >
              <Box
                width="100%"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  "&:hover": { backgroundColor: "#f8f8f8" },
                }}
              >
                <Typography>
                  {item?.data?.displayName
                    ? item?.data?.displayName
                    : item?.data?.title}
                </Typography>
                <Checkbox
                  color="primary"
                  checked={item?.isSelected}
                  onChange={(evt, val) => handleChange(val, index)}
                />
              </Box>
            </ListItem>
          ))}
        </List>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center">
          No entries found
        </Box>
      )}
    </Box>
  );
};

const IssueFilterModal = ({ open, setOpen, profileId, refetchIssues }) => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [value, setValue] = React.useState(0);
  const [scroll, setScroll] = React.useState("paper");
  const [templateFilters, setTemplateFilters] = useState([]);
  const [projectFilters, setProjectFilters] = useState([]);
  const [organizationFilters, setOrganizationFilters] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getFilters = async () => {
    try {
      const data = await Api.post("/issue/filters", {
        profileId: profileId,
      });

      const tempFilters = data.templateFilters;
      const projFilters = data.projectFilters;
      const orgFilters = data.organizationFilters;

      setTemplateFilters(
        tempFilters.map((item) => {
          return {
            data: item,
            isSelected: false,
          };
        })
      );

      setProjectFilters(
        projFilters.map((item) => {
          return {
            data: item,
            isSelected: false,
          };
        })
      );

      setOrganizationFilters(
        orgFilters.map((item) => {
          return {
            data: item,
            isSelected: false,
          };
        })
      );
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Unable to apply filter",
        },
      });
    }
  };

  useEffect(() => {
    getFilters();
  }, []);

  const resetFilters = () => {
    setTemplateFilters(
      templateFilters.map((item) => {
        return {
          ...item,
          isSelected: false,
        };
      })
    );

    setProjectFilters(
      projectFilters.map((item) => {
        return {
          ...item,
          isSelected: false,
        };
      })
    );

    setOrganizationFilters(
      organizationFilters.map((item) => {
        return {
          ...item,
          isSelected: false,
        };
      })
    );
  };

  const apply = () => {
    const filterCondition = {
      templates: templateFilters
        .filter((item) => item.isSelected)
        .map((item) => item?.data._id),
      projects: projectFilters
        .filter((item) => item.isSelected)
        .map((item) => item?.data._id),
      organizations: organizationFilters
        .filter((item) => item.isSelected)
        .map((item) => item?.data._id),
    };
    refetchIssues(1, filterCondition);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">Filter</DialogTitle>
      <DialogContent dividers={scroll === "paper"} style={{ width: "100%" }}>
        <Box
          style={{
            bgcolor: "background.paper",
            display: "flex",
            width: "100%",
            height: "100%",
          }}
        >
          <Tabs
            orientation="vertical"
            // variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab label="Templates" {...a11yProps(0)} />
            <Tab label="Projects" {...a11yProps(1)} />
            <Tab label="Orgs" {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <DataList ary={templateFilters} setAry={setTemplateFilters} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DataList ary={projectFilters} setAry={setProjectFilters} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <DataList
              ary={organizationFilters}
              setAry={setOrganizationFilters}
            />
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => resetFilters()}>Reset</Button>
        <Button onClick={() => apply()}>Apply</Button>
      </DialogActions>
    </Dialog>
  );
};

export default IssueFilterModal;
