import React, { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import Choose from "../select/choose";
import BugReportIcon from '@material-ui/icons/BugReport';
import { TextField, Button, Avatar, IconButton } from "@material-ui/core";
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';

export default function PlatformResponse(props) {
    const { type, viewMode, questionId, formRes, updateApi } = props;

    const [openChoose, setOpenChoose] = useState(false);
    const [value, setValue] = useState(formRes[0]?.parent || null);
    const [name, setName] = useState("");
    const [dpUrl, setDPUrl] = useState("");
    const [addedAt, setAddedAt] = useState("");

    useEffect(() => {
        if (type === "Organization") {
            setValue(formRes[0]?.parent)
            setName(formRes[0]?.parent?.displayName)
            const dp = formRes[0]?.parent?.displayPicture
            const useDp = dp?.thumbUrl || dp?.url
            setDPUrl(useDp)
            setAddedAt(formRes[0]?.updatedAt)
        } else if (type === "Project") {
            setValue(formRes[0]?.parent)
            setName(formRes[0]?.parent?.displayName)
            const dp = formRes[0]?.parent?.displayPicture
            const useDp = dp?.thumbUrl || dp?.url
            setDPUrl(useDp)
            setAddedAt(formRes[0]?.updatedAt)
        } else if (type === "User") {
            setValue(formRes[0]?.parent)
            setName(formRes[0]?.parent?.displayName)
            const dp = formRes[0]?.parent?.displayPicture
            const useDp = dp?.thumbUrl || dp?.url
            setDPUrl(useDp)
            setAddedAt(formRes[0]?.updatedAt)
        } else if (type === "Issue") {
            setValue(formRes[0]?.parent)
            setName(formRes[0]?.parent?.title)
            setDPUrl("issue")
            setAddedAt(formRes[0]?.updatedAt)
        } else if (type === "Template") {

        }
    }, [type, formRes])


    useDebounce(
        () => {
            if (questionId) {
                let formResId = formRes[0]?._id;
                const obj = {
                    formResId,
                    updateObj: {
                        parent: value?._id,
                        parentModelName: type
                    },
                };

                setName(value?.displayName)
                const dp = value?.displayPicture
                const useDp = dp?.thumbUrl || dp?.url
                setDPUrl(useDp)

                if (!viewMode) updateApi(obj);
            }
        },
        500,
        [value]
    );

    return (
        <div style={{ width: "100%" }}>
            <Choose
                open={openChoose}
                setOpen={setOpenChoose}
                parentModelName={type}
                multiple={false}
                disableModelChange={true}
                placeHolder={`Find ${type}`}
                onSelected={(arr) => {
                    if (arr && arr.length > 0) {
                        setValue(arr[0])
                    }
                }}
            />

            {value ? (<div style={{ display: "flex", alignItems: "center" }} >
                <div style={{ display: "flex", alignItems: "center", marginRight: "10px" }} >
                    {dpUrl === "issue" ? (
                        <Avatar>
                            <BugReportIcon />
                        </Avatar>
                    ) : (
                        <Avatar
                            src={dpUrl}
                            alt={name}
                        />
                    )}
                    <div style={{ marginLeft: "5px" }} >
                        <h3 style={{ fontSize: "16px", fontWeight: "510" }} >{name}</h3>
                        <p style={{ fontSize: "12px", fontWeight: "450" }} >{`Added at ${moment(addedAt).format('DD MMM YYYY')}`}</p>
                    </div>
                </div>
                <IconButton
                    onClick={() => { setOpenChoose(true) }}
                >
                    <EditIcon />
                </IconButton>
            </div>) :
                (<Button
                    onClick={() => { setOpenChoose(true) }}
                >
                    {`Find ${type}`}
                </Button>)
            }
        </div>
    );
}
