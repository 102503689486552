import React from "react";
import HorizBox from "./HorizBox";
import { Avatar } from "@mui/material";

const ImgTextChip = ({
  src,
  label,
  onClick,
  size,
  spacing = 1,
  hideDeleteButton = false,
}) => {
  return (
    <HorizBox
      sx={{
        borderRadius: "8px",
        border: "1px solid #D8DEE4",
        backgroundColor: "#F5F7FA",
        display: "inline-flex",
        px: size === "small" ? "6px" : "10px",
        py: size === "small" ? "6px" : "10px",
        margin: spacing,
      }}
    >
      <Avatar
        src={src}
        sx={{
          width: size === "small" ? "26px" : "30px",
          height: size === "small" ? "26px" : "30px",
        }}
      />
      <span
        style={{
          fontSize: size === "small" ? "0.9rem" : "1rem",
          fontWeight: "600",
          color: "#54555A",
        }}
      >
        {label}
      </span>
      <span
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {!hideDeleteButton && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M13.5 4.5L4.5 13.5"
              stroke="#717171"
              stroke-width="1.125"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.5 4.5L13.5 13.5"
              stroke="#717171"
              stroke-width="1.125"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
      </span>
    </HorizBox>
  );
};

export default ImgTextChip;
